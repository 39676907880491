import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CriterionDto } from '../../../openapi';
import { State } from './types';
import { addCriteria, deleteCriteria, fetchCriteria, updateCriteria } from './thunks';

const initialState: State = {
  criteria: [],
  isLoading: false,
  isDataLoaded: false,
  error: undefined,
  criterionToDelete: null,
  criterionToEdit: null,
  isFormSubmitted: false,
};

const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    dismissError: (state) => {
      state.error = undefined;
    },
    selectCriterionToDelete: (state, action: PayloadAction<CriterionDto>) => {
      state.criterionToDelete = action.payload;
    },
    selectCriterionToEdit: (state, action: PayloadAction<CriterionDto>) => {
      state.criterionToEdit = action.payload;
    },
    resetCriterionToDelete: (state) => {
      state.criterionToDelete = null;
    },
    resetFormSubmit: (state) => {
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCriteria.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isDataLoaded = true;
      state.criteria = action.payload;
    });
    builder.addCase(fetchCriteria.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCriteria.fulfilled, (state) => {
      state.isLoading = false;
      state.isDataLoaded = false;
      state.isFormSubmitted = true;
    });
    builder.addCase(addCriteria.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCriteria.fulfilled, (state) => {
      state.isLoading = false;
      state.isDataLoaded = false;
      state.criterionToEdit = null;
      state.isFormSubmitted = true;
    });
    builder.addCase(updateCriteria.rejected, (state, action) => {
      state.isLoading = false;
      state.criterionToEdit = null;
      state.error = action.payload;
    });

    builder.addCase(deleteCriteria.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCriteria.fulfilled, (state) => {
      state.isLoading = false;
      state.isDataLoaded = false;
      state.criterionToDelete = null;
    });
  },
});

export const {
  dismissError,
  selectCriterionToDelete,
  selectCriterionToEdit,
  resetCriterionToDelete,
  resetFormSubmit,
} = criteriaSlice.actions;

export default criteriaSlice.reducer;
