import { Box, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudyClassDto } from '../../openapi';
import {
  getLastItemFromArray,
  normalizeArray,
  useGetCurrentStudyClassQuery,
  useGetStudyClassesQuery,
} from '../../shared';
import { StudyClassSelect } from './study-class-select';

type Options = {
  onChange?: (studyClass: StudyClassDto) => void;
  currentStudyClassAsDefault?: boolean;
  sx?: SxProps;
};

type ReturnValue = {
  studyClass: StudyClassDto | null;
  studyClassSelectComponent: JSX.Element;
};

export const useStudyClassSelectWidget = ({
  onChange,
  currentStudyClassAsDefault = false,
  sx = {},
}: Options = {}): ReturnValue => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: studyClasses = [] } = useGetStudyClassesQuery();
  const { data: currentStudyClass } = useGetCurrentStudyClassQuery();

  const searchParams = new URLSearchParams(location.search);
  const defaultStudyClassFromSearch = searchParams.has('studyClass')
    ? Number(new URLSearchParams(location.search).get('studyClass'))
    : null;

  const defaultStudyClassState = () => {
    if (defaultStudyClassFromSearch) {
      return defaultStudyClassFromSearch;
    }

    if (currentStudyClassAsDefault) {
      return (currentStudyClass ?? getLastItemFromArray(studyClasses)).classNumber;
    }

    return 0;
  };

  const [studyClassNumber, setStudyClassNumber] = useState(defaultStudyClassState());

  useEffect(() => {
    // Sync url on initial render
    syncUrl(studyClassNumber);
  }, []);

  useEffect(() => {
    // Update study class on url change
    const studyClassFromUrl = Number(new URLSearchParams(location.search).get('studyClass'));

    if (!location.search || studyClassFromUrl === studyClassNumber) {
      return;
    }

    setStudyClassNumber(studyClassFromUrl);
  }, [location.pathname]);

  const normalizedStudyClasses = normalizeArray<StudyClassDto>(studyClasses, 'classNumber');

  const onChangeProxyHandler = (number: number) => {
    syncUrl(number);
    setStudyClassNumber(number);

    if (onChange) {
      onChange(normalizedStudyClasses[number]);
    }
  };

  const syncUrl = (number: number) => {
    const params = new URLSearchParams({ studyClass: String(number) }).toString();
    navigate(`${location.pathname}?${params}`, {
      replace: true,
    });
  };

  const styles = { ...defaultStyles, ...sx } as SxProps;

  return {
    studyClass: normalizedStudyClasses[studyClassNumber] ?? null,
    studyClassSelectComponent: (
      <Box sx={styles}>
        <StudyClassSelect
          studyClass={studyClassNumber}
          currentStudyClassAsDefault={
            currentStudyClassAsDefault && studyClassNumber === currentStudyClass?.classNumber
          }
          onStudyClassChange={onChangeProxyHandler}
        />
      </Box>
    ),
  };
};

const defaultStyles: SxProps = { marginY: 4, maxWidth: 500, width: '100%' };
