import { SocialMediaDto, UserDto } from '../../openapi';
import { NavigationRoutes } from '../../shared';

export const isStudentSelected = (url: string) => {
  return (
    url.includes(NavigationRoutes.classSpreadsheet.route) &&
    !url.endsWith(NavigationRoutes.classSpreadsheet.route)
  );
};

export const getStudentTelegram = (student?: UserDto | null) => {
  const telegramUsername = student?.socialMedia?.find(
    (item) => item.mediaTitle === SocialMediaDto.mediaTitle.TELEGRAM,
  )?.username;

  if (!telegramUsername) {
    return null;
  }

  return telegramUsername.startsWith('@') || telegramUsername.startsWith('http')
    ? telegramUsername
    : '@' + telegramUsername;
};
