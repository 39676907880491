import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { ColorSwatch } from '../../../../../../components/color-swatch/color-swatch';
import { LessonsReview } from '../../../../../../openapi';
import { mapScoreValueToLabel, taskScores } from '../../../../../../shared';
import { styles } from './styles';

type Props = {
  review: LessonsReview;
  onEditReview: (review: LessonsReview) => void;
};

export const LessonReviewItem = ({ review, onEditReview }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const correspondingTaskScore = Object.values(taskScores).find(
    ({ value }) => review.score === value,
  );

  const onEditBtnClick = () => {
    onEditReview(review);
  };

  return (
    <ListItem sx={styles.lessonReviewItem(isMobile)}>
      <Typography sx={styles.lessonNumber(isMobile)}>
        <b>№{review.lessonNumber}</b>
      </Typography>
      <Box>
        <Stack sx={styles.lessonReviewStatus(isMobile)} spacing={1}>
          <Typography>Статус: </Typography>
          {typeof review.score !== 'undefined' && (
            <Stack direction={'row'}>
              {correspondingTaskScore && <ColorSwatch color={correspondingTaskScore.color} />}
              <Typography> - "{mapScoreValueToLabel(review.score)}".</Typography>
            </Stack>
          )}
        </Stack>
        {review.retakeCount && (
          <Typography>Кількість перездавань - {review.retakeCount}</Typography>
        )}
      </Box>
      {isMobile ? (
        <Button sx={styles.mobileEditBtn} variant={'outlined'} onClick={onEditBtnClick} fullWidth>
          Редагувати
        </Button>
      ) : (
        <IconButton size='small' onClick={onEditBtnClick} sx={styles.editBtn}>
          <EditIcon />
        </IconButton>
      )}
    </ListItem>
  );
};
