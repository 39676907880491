import { useEffect, useRef, useState } from 'react';

// When receives 'null' value - delays it for some time.
// delayNotNullValueChange arg - delays value change if it was not 'null' value.
// Useful in modals/drawers, so theirs content dont blink when modal is closed and it's entity values is null
type Value<T> = T | null | undefined;
export const useNullDebounce = function <T>(
  value: Value<T>,
  ms = 1500,
  delayNotNullValueChange = false,
) {
  const [state, setState] = useState<Value<T>>(value);
  const nullTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delayValueTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (nullTimeoutRef.current) {
      clearTimeout(nullTimeoutRef.current);
      nullTimeoutRef.current = null;
    }

    if (delayValueTimeoutRef.current) {
      clearTimeout(delayValueTimeoutRef.current);
      delayValueTimeoutRef.current = null;
    }

    if (value) {
      // Set value immediately if no 'delayValue' arg received
      if (!delayNotNullValueChange) {
        setState(value);
        return;
      }

      // Set value immediately if state was null
      if (!state) {
        setState(value);
        return;
      }

      delayValueTimeoutRef.current = setTimeout(() => {
        setState(value);
      }, ms);
      return;
    }

    nullTimeoutRef.current = setTimeout(() => {
      setState(value);
    }, ms);
  }, [value, ms]);

  return state;
};
