import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AddUserFeature } from '../../features/user/add-user.feature';
import { AdminRole, createRelativePath, NavigationRoutes } from '../../shared';
import { UserOverviewFeature } from '../../features/user/user-overview.feature';
import { EditUserFeature } from '../../features/user/edit-user.feature';
import { RequireRole } from '../../features/navigation/guards/require-role';

export const UserPage = () => {
  return (
    <Routes>
      <Route
        path={createRelativePath(NavigationRoutes.user.route, NavigationRoutes.overviewUsers.route)}
        element={<UserOverviewFeature />}
      />
      <Route element={<RequireRole roles={[AdminRole]} />}>
        <Route
          path={createRelativePath(NavigationRoutes.user.route, NavigationRoutes.addUser.route)}
          element={<AddUserFeature />}
        />
        <Route
          path={createRelativePath(NavigationRoutes.user.route, NavigationRoutes.editUser.route)}
          element={<EditUserFeature />}
        />
      </Route>
    </Routes>
  );
};
