import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setAuthData } from '../../shared';
import { setToken } from '../user/redux/user-slice';
import { useLoginMutation } from './redux/auth-api';
import { styles } from './styles';

type FormValues = {
  email: string;
  password: string;
};

const formDefaultValues: FormValues = {
  email: '',
  password: '',
};

export const AuthFeature = () => {
  const { control, handleSubmit, setError, clearErrors } = useForm<FormValues>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  const [login, { isLoading }] = useLoginMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  async function onSubmit(data: FormValues): Promise<void> {
    try {
      const { token } = await login(data).unwrap();
      dispatch(setToken(token));
      setAuthData({ token: token });
    } catch (e) {
      const error = e as FetchBaseQueryError;
      const message = (error.data as { message: string }).message ?? error.data;

      const errorNotification = enqueueSnackbar(message, {
        variant: 'error',
      });

      setError('email', { type: 'custom' });
      setError('password', { type: 'custom' });
      setTimeout(() => closeSnackbar(errorNotification), 2000);
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant='h4' sx={styles.formTitle}>
          Логін
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Controller
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Email'
                  type='email'
                  {...field}
                  error={!!error}
                  fullWidth
                  required
                  {...(error && { onFocus: () => clearErrors('email') })}
                />
              )}
              name={'email'}
            />

            <Controller
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label='Пароль'
                  type='password'
                  {...field}
                  error={!!error}
                  required
                  fullWidth
                  {...(error && { onFocus: () => clearErrors('password') })}
                />
              )}
              name={'password'}
            />

            <LoadingButton
              fullWidth
              size='large'
              sx={styles.button}
              type='submit'
              variant='contained'
              loading={isLoading}
            >
              Continue
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};
