import {
  AcademicCapIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { StudentsByStudyClassChart } from './components/charts/students-by-study-class-chart';
import { StatisticsItem } from './components/statistics-item';
import { useGetDashboardStatisticsQuery } from './redux/dashboard-api';
import { disableAnimation } from './redux/dashboard-slice';

export const DashboardFeature = () => {
  const dispatch = useAppDispatch();
  const { data: statistics = statisticsDefaultValue, isSuccess } = useGetDashboardStatisticsQuery();
  const wasAnimated = useAppSelector((state) => state.dashboard.wasAnimated);

  useEffect(() => {
    if (wasAnimated || !isSuccess) {
      return;
    }

    // prevent numbers animation on component re-mount
    setTimeout(() => {
      dispatch(disableAnimation());
    }, 1500);
  }, [statistics]);

  return (
    <Container maxWidth={'xl'}>
      <Grid container spacing={3} marginBottom={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <StatisticsItem
            title={'Студенти'}
            count={statistics.studentsCount}
            color={'success.main'}
            icon={<UsersIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <StatisticsItem
            title={'Ментори'}
            count={statistics.mentorsCount}
            color={'warning.main'}
            icon={<AcademicCapIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <StatisticsItem
            title={'Потоки'}
            count={statistics.studyClassesCount}
            color={'primary.main'}
            icon={<CalendarDaysIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <StatisticsItem
            title={'Проєкти'}
            count={statistics.projectsCount}
            color={'error.main'}
            icon={<DocumentCheckIcon />}
          />
        </Grid>
      </Grid>

      <StudentsByStudyClassChart />
    </Container>
  );
};

const statisticsDefaultValue = {
  mentorsCount: 0,
  projectsCount: 0,
  studentsCount: 0,
  studyClassesCount: 0,
};
