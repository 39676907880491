import { AuthFeature } from '../../features/auth/auth.feature';
import './auth.scss';

export const AuthAdminPage = () => {
  return (
    <div className={'auth-page'}>
      <AuthFeature />
    </div>
  );
};
