import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useLinkedUsers } from '../../../../../hooks/redux-helper-hooks/useLinkedUsers';
import { MenuProps } from '../../../../../shared';
import { useMentors } from '../../../hooks/use-mentors';
import { FormValues } from './types';

export const UsersSelect = () => {
  const { control } = useFormContext<FormValues>();
  const { field } = useController({ control, name: 'id' });
  const { mentors, isLoading } = useMentors();
  const { linkedUsers } = useLinkedUsers();

  const linkedUsersIds = linkedUsers.map(({ user }) => user.id);
  const availableMentors = mentors.filter((mentor) => !linkedUsersIds.includes(mentor.id));

  return (
    <FormControl sx={{ position: 'relative', my: 4 }} fullWidth>
      <InputLabel id='users-select'>Аккаунт</InputLabel>
      {isLoading && <LinearProgress sx={{ position: 'absolute', width: '100%' }} />}
      <Select
        labelId='gender-select'
        label='Аккаунт'
        MenuProps={MenuProps}
        {...field}
        disabled={isLoading}
        required
      >
        <MenuItem />
        {availableMentors.map((mentor) => (
          <MenuItem key={mentor.id} value={mentor.id}>
            {mentor.fullName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
