import { Box, Button, List, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useProjects } from '../../../../../../hooks/redux-helper-hooks/useProjects';
import { useNullDebounce } from '../../../../../../hooks/useNullDebounce';
import { LessonsReview, UserDto } from '../../../../../../openapi';
import { LessonReviewForm } from '../../lesson-review-form/lesson-review-form';
import { LessonReviewItem } from './lesson-review-item';
import { styles } from './styles';

type Props = {
  student: UserDto;
  projectId: string;
};

export const LessonReviews = ({ student: studentProp, projectId: projectProp }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  // useNullDebounce needed to 'unmount fade animation' to work properly as props are gone at the moment of animation
  const projectId = useNullDebounce(projectProp) as string;
  const student = useNullDebounce(studentProp, 400, true) as UserDto;
  const { normalizedProjects } = useProjects();
  const [showModal, setShowModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState<LessonsReview | null>(null);

  const studentProject = student.projects.find((p) => p.projectId === projectId);
  const lessonReviews = [...(studentProject?.lessonsReviews ?? [])].sort(
    (current, next) => current.lessonNumber - next.lessonNumber,
  );
  const project = normalizedProjects[projectId];
  const canAddReview = project.lessons.length > (studentProject?.lessonsReviews ?? []).length;

  const onEditReview = useCallback((review: LessonsReview) => {
    setShowModal(true);
    setSelectedReview(review);
  }, []);

  const onLessonReviewModalClose = useCallback(() => {
    setSelectedReview(null);
    setShowModal(false);
  }, []);

  const onAddReview = () => {
    setShowModal(true);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Stack sx={styles.header(isMobile)} spacing={1}>
          <Typography>Перевірки завдань</Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'outlined'}
              onClick={onAddReview}
              disabled={!canAddReview}
              fullWidth={isMobile}
            >
              Додати рев'ю
            </Button>
          </Stack>
        </Stack>

        {lessonReviews.length ? (
          <List>
            {lessonReviews.map((review) => (
              <LessonReviewItem
                key={review.lessonNumber}
                review={review}
                onEditReview={onEditReview}
              />
            ))}
          </List>
        ) : (
          <Typography>Рев'ю домашніх завдань по цьому проєкту ще немає!</Typography>
        )}
      </Box>
      {showModal && (
        <LessonReviewForm
          student={student}
          projectId={projectId}
          selectedReview={selectedReview}
          onModalClose={onLessonReviewModalClose}
        />
      )}
    </>
  );
};
