import { Grid } from '@mui/material';
import { FormPageLayout } from '../../components/layout/form-page-layout';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { useLinkedUsers } from '../../hooks/redux-helper-hooks/useLinkedUsers';
import { LinkedAccounts } from './components/linked-accounts/linked-accounts';
import { ProfileDetailsForm } from './components/profile-details-form/profile-details-form';
import { ProfileIntroCard } from './components/profile-intro-card/profile-intro-card';

export const ProfileFeature = () => {
  const { isAdminRole, isMentorRole } = useCurrentUser();
  const { linkedUsers } = useLinkedUsers();
  const showLinkedAccounts = isAdminRole || (isMentorRole && linkedUsers.length);

  return (
    <FormPageLayout title={'Профіль'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <ProfileIntroCard />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ProfileDetailsForm />
        </Grid>
        {showLinkedAccounts ? (
          <Grid item xs={12}>
            <LinkedAccounts />
          </Grid>
        ) : null}
      </Grid>
    </FormPageLayout>
  );
};
