import {
  CreateStudentTaskReviewDto,
  CriteriaProgressItemDto,
  GetUsersResponseDto,
  UpdateStudentsProjectsDto,
  UserDto,
} from '../../../openapi';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema, StudentRole } from '../../../shared';
import { appApi } from '../../../store/app-api';
import {
  GetMentorsStudentsArgs,
  GetMentorsStudentsQuery,
  GetOneMentorStudent,
  GetOneMentorStudentQuery,
  UpdateCriteriaProgressArgs,
  UpdateStudentProjectsArgs,
} from './types';

const baseUrl = 'user/';

const getMentorsStudents: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl),
};

const updateCriteriaProgressEndpoint: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'progress'),
};

const addLessonReviewEndpoint: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'task-review'),
};

const updateStudentProjects: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'projects'),
};

export const classSpreadsheetApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getMentorsStudents: builder.query<GetUsersResponseDto, GetMentorsStudentsArgs>({
      query: ({ mentorEmail, studyClass, page, limit }) => {
        const queryParams: GetMentorsStudentsQuery = {
          mentorEmail,
          role: StudentRole,
          skip: page === 1 ? 0 : (page - 1) * limit,
          registrationConfirmed: true,
          limit,
          ...(studyClass && { studyClass }),
        };
        return {
          url: getMentorsStudents.path,
          method: getMentorsStudents.method,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        result
          ? result.users.map((student) => ({
              type: ApiEntitiesTags.mentorsStudents,
              id: student.email,
            }))
          : [ApiEntitiesTags.mentorsStudents],
    }),
    getOneMentorStudent: builder.query<UserDto | null, GetOneMentorStudent>({
      query: ({ mentorEmail, studentEmail }) => {
        const queryParams: GetOneMentorStudentQuery = {
          mentorEmail,
          role: StudentRole,
          skip: 0,
          registrationConfirmed: true,
          limit: 1,
          searchTerm: studentEmail,
        };
        // Using the same endpoint as for multiple students but with searchTerm query
        return {
          url: getMentorsStudents.path,
          method: getMentorsStudents.method,
          params: queryParams,
        };
      },
      transformResponse(result: GetUsersResponseDto, args, meta) {
        const student = result.users[0];

        if (!student || student.email !== meta.studentEmail) {
          return null;
        }

        return student;
      },
      providesTags: (result) =>
        result ? [{ type: ApiEntitiesTags.oneMentorStudent, id: result.email }] : [],
    }),
    updateCriteriaProgress: builder.mutation<CriteriaProgressItemDto, UpdateCriteriaProgressArgs>({
      query: ({ status, mentorEmail, ...queryParams }) => ({
        method: updateCriteriaProgressEndpoint.method,
        url: updateCriteriaProgressEndpoint.path,
        params: queryParams,
        body: {
          status,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProgressItem } = await queryFulfilled;
          const { mentorEmail, userEmail, projectId, criterionId } = args;

          if (!updatedProgressItem) {
            return;
          }

          dispatch(
            classSpreadsheetApi.util.updateQueryData(
              'getOneMentorStudent',
              { mentorEmail, studentEmail: userEmail },
              (draftOneStudent) => {
                if (!draftOneStudent) {
                  return;
                }

                const projectIdx =
                  draftOneStudent?.projects.findIndex(
                    (project) => project.projectId === projectId,
                  ) ?? -1;
                if (projectIdx === -1) {
                  return;
                }

                const progressItemIdx = draftOneStudent?.projects[
                  projectIdx
                ].criteriaProgress.findIndex(
                  (progressItem) => progressItem.criterionId === criterionId,
                );
                if (progressItemIdx === -1) {
                  return;
                }

                draftOneStudent.projects[projectIdx].criteriaProgress[progressItemIdx] =
                  updatedProgressItem;
              },
            ),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
    }),
    addLessonReview: builder.mutation<UserDto, CreateStudentTaskReviewDto>({
      query: (body) => ({
        method: addLessonReviewEndpoint.method,
        url: addLessonReviewEndpoint.path,
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;

          if (!updatedUser) {
            return;
          }

          dispatch(
            classSpreadsheetApi.util.updateQueryData(
              'getOneMentorStudent',
              { mentorEmail: updatedUser.mentorEmail!, studentEmail: updatedUser.email },
              (draftOneStudent) => {
                if (!draftOneStudent) {
                  return;
                }

                const { projectId, lessonReview: newLessonReview } = args;

                const projectIdx =
                  draftOneStudent?.projects.findIndex(
                    (project) => project.projectId === projectId,
                  ) ?? -1;
                if (projectIdx === -1) {
                  return;
                }

                const existingLessonReviewIdx = draftOneStudent.projects[
                  projectIdx
                ].lessonsReviews.findIndex(
                  (review) => review.lessonNumber === newLessonReview.lessonNumber,
                );
                if (existingLessonReviewIdx !== -1) {
                  draftOneStudent.projects[projectIdx].lessonsReviews[existingLessonReviewIdx] =
                    newLessonReview;
                } else {
                  draftOneStudent.projects[projectIdx].lessonsReviews.push(newLessonReview);
                }
              },
            ),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
    }),
    updateStudentProjects: builder.mutation<UserDto, UpdateStudentProjectsArgs>({
      query: ({ projectsIds, studentEmail }) => {
        const body: UpdateStudentsProjectsDto = {
          projectsIds,
        };
        return {
          method: updateStudentProjects.method,
          url: updateStudentProjects.path + `/${studentEmail}`,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;

          if (!updatedUser) {
            return;
          }

          dispatch(
            classSpreadsheetApi.util.updateQueryData(
              'getOneMentorStudent',
              { mentorEmail: updatedUser.mentorEmail!, studentEmail: updatedUser.email },
              (draftOneStudent) => {
                if (!draftOneStudent) {
                  return;
                }

                const { projects } = updatedUser;
                draftOneStudent.projects = projects;
              },
            ),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
            {
              type: ApiEntitiesTags.mentorsStudents,
              id: result.email,
            },
          ]
          : [],
    }),
  }),
});

export const {
  useGetMentorsStudentsQuery,
  useLazyGetOneMentorStudentQuery,
  useUpdateCriteriaProgressMutation,
  useAddLessonReviewMutation,
  useUpdateStudentProjectsMutation,
} = classSpreadsheetApi;
