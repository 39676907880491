import React, { useState } from 'react';
import { useCurrentUser } from '../../../../../../hooks/redux-helper-hooks/useCurrentUser';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../../../hooks/useFormNotifications';
import {
  CriteriaProgressItemDto,
  CriterionDto,
  ProjectLessonDto,
  UserProjectDto,
} from '../../../../../../openapi';
import { useUpdateCriteriaProgressMutation } from '../../../../redux/class-spreadsheet-api';
import { CriteriaAccordion } from './criteria-accordion';

type Props = {
  studentProject: UserProjectDto;
  lesson: ProjectLessonDto;
  studentEmail: string;
};

export const LessonCriteria = ({ studentProject, lesson, studentEmail }: Props) => {
  const { currentUser } = useCurrentUser();
  // loadingCriterionId state helps determine criterion by id in CriteriaAccordion
  const [loadingCriterionId, setLoadingCriterionId] = useState<string | null>(null);
  const [updateCriteriaProgress, mutationResult] = useUpdateCriteriaProgressMutation();
  const { criteriaProgress, projectId } = studentProject;
  const getLessonCriteriaByCategory = createFilterFn(lesson.criteria, criteriaProgress);

  const commonCriteria = getLessonCriteriaByCategory(CriterionDto.category.COMMON);
  const htmlCriteria = getLessonCriteriaByCategory(CriterionDto.category.HTML);
  const stylesCriteria = getLessonCriteriaByCategory(CriterionDto.category.STYLES);
  const responsiveCriteria = getLessonCriteriaByCategory(CriterionDto.category.RESPONSIVE);

  useFormNotifications(
    buildNotificationArgs(mutationResult, { successMessage: 'Статус успішно оновлений!' }),
  );

  const onStatusChange = (criterionId: string, status: CriteriaProgressItemDto.status) => {
    setLoadingCriterionId(criterionId);

    updateCriteriaProgress({
      status,
      criterionId,
      projectId,
      userEmail: studentEmail,
      mentorEmail: currentUser.email,
    })
      .unwrap()
      .then(() => {
        mutationResult.reset();
        setLoadingCriterionId(null);
      });
  };

  return (
    <div>
      <CriteriaAccordion
        criteria={commonCriteria}
        category={CriterionDto.category.COMMON}
        onStatusChange={onStatusChange}
        loadingCriterionId={loadingCriterionId}
      />
      <CriteriaAccordion
        criteria={htmlCriteria}
        category={CriterionDto.category.HTML}
        onStatusChange={onStatusChange}
        loadingCriterionId={loadingCriterionId}
      />
      <CriteriaAccordion
        criteria={stylesCriteria}
        category={CriterionDto.category.STYLES}
        onStatusChange={onStatusChange}
        loadingCriterionId={loadingCriterionId}
      />
      <CriteriaAccordion
        criteria={responsiveCriteria}
        category={CriterionDto.category.RESPONSIVE}
        onStatusChange={onStatusChange}
        loadingCriterionId={loadingCriterionId}
      />
    </div>
  );
};

// Return function helps minimize parameters duplication
const createFilterFn = (criteria: CriterionDto[], criteriaProgress: CriteriaProgressItemDto[]) => {
  return (category: CriterionDto.category) =>
    criteria
      .filter((criterion) => criterion.category === category)
      .map((criterion) => ({
        ...criterion,
        status:
          criteriaProgress.find((progressItem) => progressItem.criterionId === criterion.id)
            ?.status ?? CriteriaProgressItemDto.status.TODO,
      }))
      .sort((a, b) => a.number - b.number);
};
