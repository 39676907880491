import React, { Ref, useRef } from 'react';
import { FilesUpload, FileUpload, Widget, WidgetAPI } from '@uploadcare/react-widget';
import { Box, Button, ImageList, ImageListItem, Typography } from '@mui/material';
import { envs } from '../../shared';
import { useUploadcareApi } from '../../hooks/useUploadcareApi';

type Props = {
  designPreviewImagesUrls: string[];
  onChange: (updatedArray: string[]) => void;
};

export const DesignPreviewFormField = ({ designPreviewImagesUrls, onChange }: Props) => {
  const uploaderRef = useRef<WidgetAPI>();
  const { deleteFile } = useUploadcareApi();

  const onDesignPreviewAdd = async (fileUpload: FileUpload | FilesUpload | null) => {
    const result = await fileUpload?.promise();

    onChange([...designPreviewImagesUrls, result?.cdnUrl ?? '']);
  };

  const onDesignPreviewDelete = async (itemIdx: number) => {
    const itemToDelete = designPreviewImagesUrls[itemIdx];

    onChange(designPreviewImagesUrls.filter((item) => item !== itemToDelete));
    await deleteFile(itemToDelete);
  };

  return (
    <Box>
      <Typography sx={{ my: 1, fontSize: 20 }}>Посилання на картинки-превью</Typography>
      <Button
        variant={'contained'}
        onClick={() => uploaderRef.current?.openDialog('file')}
        sx={{ mb: 1 }}
      >
        Додати
      </Button>
      <Box sx={{ position: 'absolute', left: '-1000px' }}>
        <Widget
          publicKey={envs.uploadcarePublicKey}
          tabs='file'
          locale={'uk'}
          onFileSelect={onDesignPreviewAdd}
          ref={uploaderRef as Ref<WidgetAPI>}
          imagesOnly
          previewStep={false}
          clearable={false}
          onDialogOpen={(api) => {
            api.hideTab('preview');
            api.switchTab('file');
          }}
          crop={'free'}
        />
      </Box>
      <ImageList gap={3} cols={3} rowHeight={230}>
        {designPreviewImagesUrls.map((item, idx) => (
          <ImageListItem key={item}>
            <Box className={'preview-img-box'}>
              <img
                src={`${item}?w=248&fit=crop&auto=format`}
                srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={`Картинка-прев'ю ${idx + 1}`}
                loading='lazy'
              />
              <Button
                variant={'text'}
                color={'error'}
                onClick={() => onDesignPreviewDelete(idx)}
                sx={{ margin: '0 auto', display: 'block' }}
              >
                Видалити картинку
              </Button>
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
