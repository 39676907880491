import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { approveUserRegistration } from '../../../features/user/redux/thunks';
import {
  selectUserToApproveRegistration,
  selectUserToDelete,
  selectUserToEdit,
} from '../../../features/user/redux/user-slice';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useHistory } from '../../../hooks/useHistory';
import { UserDto } from '../../../openapi';
import { NavigationRoutes, translateRole } from '../../../shared';
import { EntityActions } from '../../../widgets/entity-actions/entity-actions';
import { Loader } from '../../loader/loader';
import { Spinner } from '../../spinner/Spinner';

type Props = {
  page: number;
  limit: 10 | 20 | 30;
  onLimitChange: (limitValue: 10 | 20 | 30) => void;
  onPageChange: (pageValue: number) => void;
};

export const UsersList = ({ limit, page, onLimitChange, onPageChange }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const users = useAppSelector((state) => state.user.users);
  const total = useAppSelector((state) => state.user.total);
  const { isLoading, userEmail: approvingUserEmail } = useAppSelector(
    (state) => state.user.approveRegistrationState,
  );

  const validateLimit = (inputValue: string) => {
    const parsedLimit = parseInt(inputValue);
    if (parsedLimit === 10 || parsedLimit === 20 || parsedLimit === 30) {
      onLimitChange(parsedLimit);
    }
  };

  const onEditHandler = (user: UserDto) => {
    dispatch(selectUserToEdit(user));
    history.push(NavigationRoutes.editUser.route);
  };

  const onDeleteHandler = (user: UserDto) => {
    dispatch(selectUserToDelete(user));
  };

  const onRegistrationApprove = async (userToApprove: UserDto) => {
    dispatch(selectUserToApproveRegistration(userToApprove.email));
    dispatch(approveUserRegistration(userToApprove.email));
  };

  return (
    <Paper sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ім&apos;я</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Потік</TableCell>
              <TableCell>Ментор</TableCell>
              <TableCell>Дата реєстрації</TableCell>
              <TableCell>Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow hover key={user.email}>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Typography color='textPrimary' variant='body1'>
                      {user.fullName ?? '-'}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{translateRole(user.role)}</TableCell>
                <TableCell>{formatUsersStudyClass(user)}</TableCell>
                <TableCell>{user.mentorEmail ?? '-'}</TableCell>
                <TableCell>
                  {user.createdAt ? new Date(user.createdAt).toDateString() : '-'}
                </TableCell>
                <TableCell>
                  <EntityActions
                    onEdit={() => onEditHandler(user)}
                    editUrl={NavigationRoutes.editUser.route}
                    onDelete={() => onDeleteHandler(user)}
                    {...(shouldShowApproveButton(users, user.email) && (
                      <IconButton size='small' onClick={() => onRegistrationApprove(user)}>
                        <Loader
                          spinner={<Spinner size='sm' />}
                          isLoading={isLoading && approvingUserEmail === user.email}
                        >
                          <CheckIcon />
                        </Loader>
                      </IconButton>
                    ))}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component='div'
        count={total}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={({ target }) => {
          validateLimit(target.value);
        }}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </Paper>
  );
};

function findUsersToApprove(users: UserDto[]): UserDto[] {
  return users.filter((user) => user.registrationConfirmed === false);
}

function formatUsersStudyClass(user: UserDto) {
  if (user.studyClass) {
    const startDate = new Date(user.studyClass.startDate).toLocaleDateString();

    return `№${user.studyClass.classNumber} - ${startDate}`;
  }
  return '-';
}

function shouldShowApproveButton(users: UserDto[], email: string) {
  const usersToApprove = findUsersToApprove(users);

  return Boolean(usersToApprove.find((user) => user.email === email));
}
