import { CreateStudyClassDto, StudyClassDto } from '../../../../openapi';
import {
  ApiEntitiesTags,
  createEndpoint,
  EndpointsSchema,
  sharedStudyClassApi,
} from '../../../../shared';
import { appApi } from '../../../../store/app-api';
import { UpdateStudyClassArgs } from './types';
import {
  createOneStudyClassUpdater,
  createStudyClassesOnCreateUpdater,
  createStudyClassesOnDeleteUpdater,
} from './updater-functions';

const baseUrl = 'study-class/';

const getOneStudyClass: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl),
};

const createStudyClass: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint(baseUrl),
};

const updateStudyClass: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl),
};

const deleteStudyClass: EndpointsSchema = {
  method: 'DELETE',
  path: createEndpoint(baseUrl),
};

export const studyClassApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getOneStudyClass: builder.query<StudyClassDto, number>({
      query: (studyClassNumber) => ({
        url: getOneStudyClass.path + studyClassNumber,
        method: getOneStudyClass.method,
      }),
      providesTags: (result, error, studyClassNumber) => [
        { type: ApiEntitiesTags.oneStudyClass, ...(result && { id: studyClassNumber }) },
      ],
    }),
    createStudyClass: builder.mutation<StudyClassDto, CreateStudyClassDto>({
      query: (body) => ({
        url: createStudyClass.path,
        method: createStudyClass.method,
        body,
      }),
      async onQueryStarted(createDto, { dispatch, queryFulfilled }) {
        try {
          const { data: createdStudyClass } = await queryFulfilled;

          if (!createdStudyClass) {
            return;
          }

          dispatch(
            sharedStudyClassApi.util.updateQueryData(
              'getStudyClasses',
              undefined,
              (draftStudyClasses) => {
                draftStudyClasses.push(createdStudyClass);
              },
            ),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
      invalidatesTags: (result, error) =>
        error
          ? []
          : [
            ApiEntitiesTags.currentStudyClass,
            ApiEntitiesTags.dashboardStatistics,
            ApiEntitiesTags.studentsCountByStudyClasses,
          ],
    }),
    updateStudyClass: builder.mutation<StudyClassDto, UpdateStudyClassArgs>({
      query: ({ studyClassNumber, body }) => ({
        url: updateStudyClass.path + studyClassNumber,
        method: updateStudyClass.method,
        body,
      }),
      async onQueryStarted(updateDto, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedStudyClass } = await queryFulfilled;

          if (!updatedStudyClass) {
            return;
          }

          const oneStudyClassUpdaterFunc = createOneStudyClassUpdater(updatedStudyClass);
          const studyClassesUpdaterFunc = createStudyClassesOnCreateUpdater(updatedStudyClass);

          dispatch(
            studyClassApi.util.updateQueryData(
              'getOneStudyClass',
              updatedStudyClass.classNumber,
              oneStudyClassUpdaterFunc,
            ),
          );
          dispatch(
            sharedStudyClassApi.util.updateQueryData(
              'getStudyClasses',
              undefined,
              studyClassesUpdaterFunc,
            ),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
      invalidatesTags: (result, error) => (error ? [] : [ApiEntitiesTags.currentStudyClass]),
    }),
    deleteStudyClass: builder.mutation<StudyClassDto, number>({
      query: (studyClassNumber) => ({
        url: deleteStudyClass.path + studyClassNumber,
        method: deleteStudyClass.method,
      }),
      async onQueryStarted(deleteStudyClassDto, { dispatch, queryFulfilled }) {
        try {
          const { data: deletedStudyClass } = await queryFulfilled;

          if (!deletedStudyClass) {
            return;
          }

          const updaterFunc = createStudyClassesOnDeleteUpdater(deletedStudyClass);

          dispatch(
            sharedStudyClassApi.util.updateQueryData('getStudyClasses', undefined, updaterFunc),
          );
        } catch {
          // Error is handled in useFormNotifications hook
        }
      },
      invalidatesTags: (result, error, studyClassNumber) => {
        return error
          ? []
          : [
            { type: ApiEntitiesTags.currentStudyClass, id: studyClassNumber },
            { type: ApiEntitiesTags.oneStudyClass, id: studyClassNumber },
            ApiEntitiesTags.dashboardStatistics,
            ApiEntitiesTags.studentsCountByStudyClasses,
          ];
      },
    }),
  }),
});

export const {
  useDeleteStudyClassMutation,
  useGetOneStudyClassQuery,
  useCreateStudyClassMutation,
  useUpdateStudyClassMutation,
} = studyClassApi;
