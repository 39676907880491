import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CriterionPreview } from '../../../features/project/redux/types';
import { renderTranslation, UkLang } from '../../../shared';

type Props = {
  criteria: CriterionPreview[];
  category: string;
};

export const CriteriaAccordionList = ({ criteria, category }: Props) => {
  return (
    <Accordion sx={{ my: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Доступні критерії категорії {category}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {criteria.length ? (
          <TableContainer component={Paper}>
            <Table size='small' sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Номер</TableCell>
                  <TableCell>Описание</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {criteria.map((criterion) => (
                  <TableRow
                    key={criterion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{criterion.number}</TableCell>
                    <TableCell>{renderTranslation(UkLang, criterion.info)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>Критерії не знайдено</p>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
