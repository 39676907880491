import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Skeleton,
  TablePagination,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PageTitle } from '../../components/layout/page-title';
import { PageToolbar } from '../../components/page-toolbar/page-toolbar';
import { useCriteria } from '../../hooks/redux-helper-hooks/useCriteria';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { useHistory } from '../../hooks/useHistory';
import { UserDto } from '../../openapi';
import { NavigationRoutes } from '../../shared';
import { useStudyClassSelectWidget } from '../../widgets/study-class-select/use-study-class-select-widget';
import { StudentDrawer } from './components/drawer/student-drawer';
import { StudentListItem } from './components/student-list-item/student-list-item';
import { isStudentSelected } from './helpers';
import {
  useGetMentorsStudentsQuery,
  useLazyGetOneMentorStudentQuery,
} from './redux/class-spreadsheet-api';

export const ClassSpreadsheetFeature = () => {
  const {
    location: { pathname },
    navigate,
  } = useHistory();
  const { studyClass, studyClassSelectComponent } = useStudyClassSelectWidget({
    currentStudyClassAsDefault: true,
  });
  const { currentUser } = useCurrentUser();
  // Prefetch criteria to use in Drawer component
  useCriteria();
  const [drawerOpen, setDrawerOpen] = useState(isStudentSelected(pathname));
  const [selectedStudent, setSelectedStudent] = useState<UserDto | null>(null);
  const [page, setPage] = useState(1);
  const limit = 20;

  const {
    data: { users: students = [], total = 0 } = {},
    isFetching: isLoading,
    isLoading: isLoadingFirstTime,
  } = useGetMentorsStudentsQuery({
    page,
    limit,
    mentorEmail: currentUser.email,
    studyClass: studyClass?.classNumber,
  });
  const [
    getOneStudent,
    {
      data: fetchedOneStudent,
      fulfilledTimeStamp: getOneStudentLoaded,
      isFetching: isOneStudentLoading,
    },
  ] = useLazyGetOneMentorStudentQuery();

  // Trigger student fetching on url update
  useEffect(() => {
    const email = getEmailFromUrl(pathname);

    if (!email) {
      setSelectedStudent(null);
      setDrawerOpen(false);
      return;
    }

    getOneStudent({ studentEmail: email, mentorEmail: currentUser.email }, true);
  }, [pathname]);

  // 'getOneStudent' request handling
  useEffect(() => {
    if (
      !getOneStudentLoaded ||
      isOneStudentLoading ||
      !getEmailFromUrl(pathname) ||
      getEmailFromUrl(pathname) !== fetchedOneStudent?.email
    ) {
      return;
    }

    if (fetchedOneStudent) {
      setDrawerOpen(true);
      setSelectedStudent(fetchedOneStudent);
    }
  }, [getOneStudentLoaded, fetchedOneStudent, pathname, isOneStudentLoading]);

  // Reset pagination on StudyClass change
  useEffect(() => {
    if (page !== 1) {
      return;
    }

    setPage(1);
  }, [studyClass]);

  const onStudentClick = (studentEmail: string) => {
    navigate(studentEmail);
    setDrawerOpen(true);
  };

  const toggleDrawer = () => {
    if (drawerOpen) {
      navigate(NavigationRoutes.classSpreadsheet.route);
      setDrawerOpen(false);
    }

    setDrawerOpen(!drawerOpen);
  };

  return (
    <StudentDrawer
      open={drawerOpen}
      toggleDrawer={toggleDrawer}
      selectedStudent={selectedStudent}
      isLoading={isOneStudentLoading}
    >
      <Container maxWidth={false}>
        <PageTitle text={'Журнал наставника'} />
        <PageToolbar>{studyClassSelectComponent}</PageToolbar>
        <Box sx={{ mt: 3 }}>
          {isLoading && (
            <>
              <LinearProgress />
              <Paper sx={{ width: '100%', overflowX: 'auto' }}>
                {Array(limit)
                  .fill(undefined)
                  .map((el, idx) => (
                    <Box sx={{ my: 1 }} key={idx}>
                      <Skeleton
                        variant={'rectangular'}
                        animation={'wave'}
                        width={'100%'}
                        height={74}
                      />
                    </Box>
                  ))}
              </Paper>
            </>
          )}

          {!isLoadingFirstTime && Boolean(students.length) && (
            <Paper sx={{ width: '100%', p: 0 }}>
              {students.map((student) => (
                <StudentListItem
                  student={student}
                  onStudentClick={onStudentClick}
                  key={student.email}
                />
              ))}
            </Paper>
          )}

          {!isLoading && !students.length && <Typography>Учнів не знайдено</Typography>}

          <TablePagination
            component='div'
            count={total}
            onPageChange={(event, newPage) => setPage(newPage + 1)}
            page={page - 1}
            rowsPerPage={limit}
            rowsPerPageOptions={[]}
          />
        </Box>
      </Container>
    </StudentDrawer>
  );
};

function getEmailFromUrl(pathname: string) {
  const splitUrl = pathname.split(NavigationRoutes.classSpreadsheet.route + '/');

  if (splitUrl.length < 2) {
    return null;
  }

  return splitUrl[1];
}
