import { TypedUseMutationResult } from '@reduxjs/toolkit/query/react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiError } from '../shared';

type UseFormNotificationsArgs = {
  isSuccess: boolean;
  resetMutationState: () => void;
  successMessage: string;
  error?: ApiError;
  closeNotificationTimeout?: number;
  onClose?: () => void;
  onSuccess?: () => void;
};

export const useFormNotifications = ({
  successMessage,
  isSuccess,
  resetMutationState,
  error,
  closeNotificationTimeout = 1800,
  onClose,
  onSuccess,
}: UseFormNotificationsArgs) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error) {
      return;
    }

    if (Array.isArray(error.data)) {
      error.data.forEach(({ messages }) =>
        messages.forEach((message) => {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }),
      );
    } else {
      enqueueSnackbar(error.data.message, {
        variant: 'error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      const successSnackbar = enqueueSnackbar(successMessage, {
        variant: 'success',
      });

      if (onSuccess) {
        onSuccess();
      }

      setTimeout(() => {
        resetMutationState();
        closeSnackbar(successSnackbar);

        if (onClose) {
          onClose();
        }
      }, closeNotificationTimeout);
    }
  }, [isSuccess]);
};

export const buildNotificationArgs = (
  mutationResult: TypedUseMutationResult<any, any, any>,
  data: Omit<UseFormNotificationsArgs, 'isSuccess' | 'error' | 'resetMutationState'>,
): UseFormNotificationsArgs => {
  const { isSuccess, error, reset } = mutationResult;

  return { error: error as ApiError, isSuccess, resetMutationState: reset, ...data };
};

export type UseFormNotificationsWithRedirectArgs = UseFormNotificationsArgs & {
  redirectUrl: string;
};

export const useFormNotificationsWithRedirect = (args: UseFormNotificationsWithRedirectArgs) => {
  const { redirectUrl, onSuccess, ...rest } = args;
  const navigate = useNavigate();

  const onSuccessWithRedirect = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }
    navigate(redirectUrl, { replace: true });
  }, [redirectUrl]);

  useFormNotifications({
    ...rest,
    onSuccess: onSuccessWithRedirect,
  });
};
