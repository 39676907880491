import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getLastItemFromArray } from '../../../../shared';

export const useSelectedStudyClass = () => {
  const location = useLocation();
  const selectedStudyClass = useAppSelector((state) => state.studyClass.selectedStudyClass);

  const studyClassNumberInUrl = Number(getLastItemFromArray(location.pathname.split('/')));

  return selectedStudyClass && selectedStudyClass.classNumber === studyClassNumberInUrl
    ? selectedStudyClass
    : null;
};
