import { green, orange, red } from '@mui/material/colors';
import { CriterionDto, LessonsReview, Translation, UserDto } from '../openapi';
import { FormMode, Language } from './shared.types';

export const AdminRole = UserDto.role.ADMIN;
export const MentorRole = UserDto.role.MENTOR;
export const StudentRole = UserDto.role.STUDENT;

export const NotDoneStatus = LessonsReview.status.NOT_DONE;
export const NotDoneStatusLabel = 'Не виконано';
export const DoneStatus = LessonsReview.status.DONE;
export const DoneStatusLabel = 'Виконано';
export const RetakeStatus = LessonsReview.status.RETAKE;
export const RetakeStatusLabel = 'Перездавання';

export const taskScores = {
  retake: { value: 0, label: 'Перездавання', color: red[500] },
  bad: { value: 1, label: 'Незадовільно', color: orange[500] },
  good: { value: 2, label: 'Добре', color: green.A200 },
  excellent: { value: 3, label: 'Відмінно', color: green.A700 },
};

export const taskScoresLabels = Object.values(taskScores)
  .filter((taskScore) => taskScore.value !== 0)
  .map((taskScore) => taskScore.label);

export const RuLang: Language = 'ru';
export const UkLang: Language = 'uk';

export const EmptyTranslations: Translation = { ru: '', uk: '' };

export const CriterionCommonCategory = CriterionDto.category.COMMON;
export const CriterionHtmlCategory = CriterionDto.category.HTML;
export const CriterionStylesCategory = CriterionDto.category.STYLES;
export const CriterionResponsiveCategory = CriterionDto.category.RESPONSIVE;

export const EditFormMode: FormMode = 'edit';
export const CreateFormMode: FormMode = 'create';
