import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProjectsOverviewFeature } from '../../features/project/projects-overview.feature';
import { createRelativePath, NavigationRoutes } from '../../shared';
import { TagOverviewFeature } from '../../features/project/tag-overview.feature';
import { TagForm } from '../../components/project/tags/tag-form';
import { FormLayout } from '../../components/layout/form-layout';
import { ProjectForm } from '../../components/project/project-form';

export const ProjectPage = () => {
  const TagManagementFeature = () => (
    <FormLayout title={'Тегі - модерація'}>
      <TagForm />
    </FormLayout>
  );

  const ProjectManagementFeature = () => (
    <FormLayout title={'Проєкти - модерація'}>
      <ProjectForm />
    </FormLayout>
  );

  return (
    <Routes>
      <Route
        path={createRelativePath(
          NavigationRoutes.project.route,
          NavigationRoutes.overviewProjects.route,
        )}
        element={<ProjectsOverviewFeature />}
      />
      <Route
        path={createRelativePath(NavigationRoutes.project.route, NavigationRoutes.addProject.route)}
        element={<ProjectManagementFeature />}
      />
      <Route
        path={createRelativePath(
          NavigationRoutes.project.route,
          NavigationRoutes.editProject.route,
        )}
        element={<ProjectManagementFeature />}
      />
      <Route
        path={createRelativePath(
          NavigationRoutes.project.route,
          NavigationRoutes.overviewTags.route,
        )}
        element={<TagOverviewFeature />}
      />
      <Route
        path={createRelativePath(NavigationRoutes.project.route, NavigationRoutes.addTag.route)}
        element={<TagManagementFeature />}
      />
      <Route
        path={createRelativePath(NavigationRoutes.project.route, NavigationRoutes.editTag.route)}
        element={<TagManagementFeature />}
      />
    </Routes>
  );
};
