import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNullDebounce } from '../../../hooks/useNullDebounce';

type Props = {
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
  text: string;
  isLoading: boolean;
};

export const ConfirmDialog = ({ onConfirm, isOpen, onClose, text: textProp, isLoading }: Props) => {
  const text = useNullDebounce(textProp);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={isOpen}
      onClose={handleCancel}
    >
      <DialogTitle>Підтвердження</DialogTitle>
      <DialogContent dividers>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Скасувати
        </Button>
        <LoadingButton
          color='primary'
          variant='text'
          type='button'
          onClick={onConfirm}
          loading={isLoading}
        >
          Підтвердити
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
