import { Translation, UserDto } from '../openapi';
import {
  AdminRole,
  CreateFormMode,
  DoneStatus,
  DoneStatusLabel,
  EditFormMode,
  MentorRole,
  NotDoneStatus,
  NotDoneStatusLabel,
  RetakeStatus,
  RetakeStatusLabel,
  StudentRole,
  taskScores,
  UkLang,
} from './shared.global-values';
import { Language, LessonReviewStatus, Normalized } from './shared.types';

export function isEmptyString(...strings: string[]): boolean {
  return !isNotEmptyString(...strings);
}

export function isNotEmptyString(...strings: string[]): boolean {
  if (!strings.length) {
    return false;
  }

  return (
    strings.filter((str) => typeof str === 'string' && str.length > 0).length === strings.length
  );
}

export function getLastItemFromArray<T>(array: T[]): T {
  return array[array.length - 1];
}

export function isEmptyArray<T>(array: T[]): boolean {
  return array.length === 0;
}

export function translateRole(role: UserDto.role): string {
  switch (role) {
    case UserDto.role.STUDENT:
      return 'Учень';
    case UserDto.role.MENTOR:
      return 'Ментор';
    case UserDto.role.ADMIN:
      return 'Адмін';
  }
}

export function isAdminUser(role: string): boolean {
  return role === AdminRole;
}

export function isMentorUser(role: string): boolean {
  return role === MentorRole;
}

export function isStudentUser(role: string): boolean {
  return role === StudentRole;
}

export function translateStatusLabelToCyrillic(status: LessonReviewStatus) {
  if (status === NotDoneStatus) return NotDoneStatusLabel;

  if (status === DoneStatus) return DoneStatusLabel;

  return RetakeStatusLabel;
}

export function translateStatusLabelToEng(status: string) {
  if (status === NotDoneStatusLabel) return NotDoneStatus;

  if (status === DoneStatusLabel) return DoneStatus;

  return RetakeStatus;
}

export function mapScoreValueToLabel(scoreNumber: number) {
  if (scoreNumber === taskScores.bad.value) return taskScores.bad.label;
  if (scoreNumber === taskScores.good.value) return taskScores.good.label;
  if (scoreNumber === taskScores.excellent.value) return taskScores.excellent.label;

  return taskScores.retake.label;
}

export function mapScoreLabelToValue(label: string) {
  if (label === taskScores.bad.label) return taskScores.bad.value;
  if (label === taskScores.good.label) return taskScores.good.value;
  if (label === taskScores.excellent.label) return taskScores.excellent.value;

  return taskScores.retake.value;
}

export function isNumberType(value: unknown): value is number {
  return typeof value === 'number';
}

export function renderTranslation(language: Language, translation: Translation): string {
  return language === UkLang ? translation.uk : translation.ru;
}

export const getFormMode = <T>(selectedEntity: T | null) => {
  const formMode = selectedEntity ? EditFormMode : CreateFormMode;
  const isEditFormMode = formMode === EditFormMode;
  const isCreateFormMode = formMode === CreateFormMode;

  return {
    isEditFormMode,
    isCreateFormMode,
    formMode,
  };
};

export function normalizeArray<T extends Record<string, unknown>, K extends keyof T = keyof T>(
  array: T[],
  key: K,
): Normalized<T> {
  return array.reduce((acum, item) => {
    const idValue = String(item[key]);
    acum[idValue] = item;
    return acum;
  }, {} as Record<string, T>);
}

export function stringToColor(stringValue: string) {
  let hash = 0;
  let i;

  for (i = 0; i < stringValue.length; i += 1) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}
