import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PageTitle } from '../../components/layout/page-title';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { TagDto } from '../../openapi';
import { EntityActions } from '../../widgets/entity-actions/entity-actions';
import { Loader } from '../../components/loader/loader';
import { ConfirmDialog } from '../../components/modals/confirm-dialog/confirm-dialog';
import { NavigationRoutes } from '../../shared';
import { useHistory } from '../../hooks/useHistory';
import { useTags } from '../../hooks/redux-helper-hooks/useTags';
import { selectProjectTagToDelete, selectProjectTagToEdit } from './redux/project-slice';
import { deleteProjectTag } from './redux/thunks';

export const TagOverviewFeature = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { tags } = useTags();
  const isLoading = useAppSelector((state) => state.project.isProjectTagsLoading);
  const isDeleteLoading = useAppSelector((state) => state.project.isDeleteLoading);
  const selectedTagToDelete = useAppSelector((state) => state.project.selectedProjectTagToDelete);

  const onEditBtnClick = (tag: TagDto) => {
    dispatch(selectProjectTagToEdit(tag));
    history.push(NavigationRoutes.editTag.route);
  };

  const onDeleteBtnClick = (tag: TagDto) => {
    dispatch(selectProjectTagToDelete(tag));
  };

  const deleteTagHandler = () => {
    if (!selectedTagToDelete) {
      return;
    }

    dispatch(deleteProjectTag(selectedTagToDelete.id));
  };

  const onCancelDelete = () => {
    dispatch(selectProjectTagToDelete(null));
  };

  return (
    <Container maxWidth={false}>
      <PageTitle text={'Теги'} />
      <Box sx={{ mt: 3 }}>
        <Loader isLoading={isLoading} centered>
          {tags.length > 0 ? (
            <Paper sx={{ width: '100%', overflowX: 'auto' }}>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Назва тегу</TableCell>
                      <TableCell>Наявність перекладів</TableCell>
                      <TableCell>Дії</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tags.map((tag) => (
                      <TableRow hover key={tag.id}>
                        <TableCell>{tag.title}</TableCell>
                        <TableCell>{tag.translations ? 'Так' : 'Ні'}</TableCell>
                        <TableCell>
                          <EntityActions
                            onEdit={() => onEditBtnClick(tag)}
                            onDelete={() => onDeleteBtnClick(tag)}
                            editUrl={NavigationRoutes.editTag.route}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          ) : (
            <Typography>Тегів не знайдено</Typography>
          )}
        </Loader>
      </Box>

      <ConfirmDialog
        onConfirm={deleteTagHandler}
        text={confirmationText}
        isOpen={Boolean(selectedTagToDelete)}
        onClose={onCancelDelete}
        isLoading={isDeleteLoading}
      />
    </Container>
  );
};

const confirmationText = 'Ви впевнені, що хочете видалити цей тег?';
