import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormFooter } from '../../../../components/forms/form-footer';
import { CreateStudyClassDto } from '../../../../openapi';
import { ApiError, getLastItemFromArray, useGetStudyClassesQuery } from '../../../../shared';
import { useSelectedStudyClass } from '../hooks/useSelectedStudyClass';
import { useGetOneStudyClassQuery } from '../redux/study-class-api';
import { StudyClassMutationsTargetField, UpdateStudyClassArgs } from '../redux/types';

type SubmitCreateHandler = (studyClass: CreateStudyClassDto) => void;
type SubmitUpdateHandler = (data: UpdateStudyClassArgs) => void;

type Props = {
  isSubmitLoading: boolean;
  error?: ApiError;
  submitForm: SubmitCreateHandler | SubmitUpdateHandler;
  resetMutationState: () => void;
  onCancel?: () => void;
};

export const StudyClassForm = ({
  submitForm,
  isSubmitLoading,
  resetMutationState,
  error,
  onCancel,
}: Props) => {
  const location = useLocation();
  const isEditMode = location.pathname.includes('/study-class/edit/');
  const matchesMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const selectedStudyClass = useSelectedStudyClass();
  const { data: studyClassToEdit, isFetching: isGetOneLoading } = useGetOneStudyClassQuery(
    Number(getLastItemFromArray(location.pathname.split('/'))),
    {
      skip: !isEditMode || !!selectedStudyClass,
      selectFromResult: (result) =>
        isEditMode && selectedStudyClass
          ? {
            ...result,
            ...(selectedStudyClass && { data: selectedStudyClass }),
          }
          : result,
    },
  );
  const { data: studyClasses = [] } = useGetStudyClassesQuery();

  const shouldDisableFields = isSubmitLoading || (isEditMode && isGetOneLoading);
  const studyClassToEditNotFound = isEditMode && !isGetOneLoading && !studyClassToEdit;
  const errorTargetFields = Array.isArray(error?.data)
    ? ((error?.data.map((el) => el.targetField) ?? []) as StudyClassMutationsTargetField[])
    : [];

  const initialStudyClassNumber = studyClasses.length + 1;
  const [classNumber, setClassNumber] = useState(String(isEditMode ? '' : initialStudyClassNumber));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    // Set init values for selected to edit study class
    if (studyClassToEdit && !classNumber) {
      setClassNumber(String(studyClassToEdit.classNumber));
      setStartDate(new Date(studyClassToEdit.startDate));
      setEndDate(new Date(studyClassToEdit.endDate));
    }
  }, [studyClassToEdit, isGetOneLoading, isEditMode]);

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (error) {
      resetMutationState();
    }

    const newStartDate = startDate.toISOString();
    const newEndDate = endDate.toISOString();

    if (isEditMode && studyClassToEdit) {
      const data: UpdateStudyClassArgs = {
        studyClassNumber: studyClassToEdit.classNumber,
        body: {
          startDate: newStartDate,
          endDate: newEndDate,
        },
      };

      (submitForm as SubmitUpdateHandler)(data);
    } else {
      const body: CreateStudyClassDto = {
        classNumber: +classNumber,
        startDate: newStartDate,
        endDate: newEndDate,
      };

      (submitForm as SubmitCreateHandler)(body);
    }
  };

  const onDateChange = (newValue: Date | null, dateType: 'start' | 'end') => {
    if (newValue) {
      if (error) {
        resetMutationState();
      }

      switch (dateType) {
        case 'start':
          setStartDate(newValue);
          break;
        case 'end':
          setEndDate(newValue);
          break;
      }
    }
  };

  if (studyClassToEditNotFound) {
    return <Typography>Потік не знайдено</Typography>;
  }

  const canSubmit = isEditMode
    ? startDate.toISOString() !== studyClassToEdit?.startDate ||
      endDate.toISOString() !== studyClassToEdit?.endDate
    : true;

  return (
    <form onSubmit={onSubmitHandler}>
      <Card>
        <Box sx={{ position: 'relative' }}>
          <CardHeader title={isEditMode ? 'Редагувати потік' : 'Додати потік'} />
          {shouldDisableFields && <LinearProgress sx={{ position: 'absolute', width: '100%' }} />}
        </Box>

        <Divider />
        <CardContent>
          <TextField
            id='id'
            label='Номер потоку'
            margin='normal'
            type='number'
            variant='outlined'
            value={classNumber}
            onFocus={resetMutationState}
            onChange={({ target }) => setClassNumber(target.value)}
            disabled={isEditMode || shouldDisableFields}
            error={errorTargetFields.includes('classNumber')}
            required
            fullWidth
          />
          <FormControl
            margin={'normal'}
            fullWidth={matchesMobile}
            sx={{ marginRight: matchesMobile ? 0 : 2 }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Дата початку'
                inputFormat='dd/MM/yyyy'
                value={startDate}
                onChange={(date) => onDateChange(date, 'start')}
                renderInput={(params) => (
                  <TextField {...params} error={errorTargetFields.includes('startDate')} />
                )}
                disabled={shouldDisableFields}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl margin={'normal'} fullWidth={matchesMobile}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Дата закінчення'
                inputFormat='dd/MM/yyyy'
                value={endDate}
                onChange={(date) => onDateChange(date, 'end')}
                renderInput={(params) => (
                  <TextField {...params} error={errorTargetFields.includes('endDate')} />
                )}
                disabled={shouldDisableFields}
              />
            </LocalizationProvider>
          </FormControl>
        </CardContent>
        <Divider />
        <FormFooter
          isLoading={shouldDisableFields}
          disabled={shouldDisableFields || Boolean(error) || !canSubmit}
          onCancel={onCancel}
        />
      </Card>
    </form>
  );
};
