import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Language, RuLang, UkLang } from '../../shared';

type Props = {
  value: Language;
  onChange: (value: Language) => void;
};

export const LanguageSelect = ({ onChange, value }: Props) => {
  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='language'>Мова</InputLabel>
      <Select
        labelId='language'
        id='language'
        label='Мова'
        value={value}
        onChange={({ target }) => onChange(target.value as Language)}
        fullWidth
      >
        <MenuItem value={UkLang}>Українська</MenuItem>
        <MenuItem value={RuLang}>Російська</MenuItem>
      </Select>
    </FormControl>
  );
};
