import { useMemo } from 'react';
import { useGetCriteriaQuery } from '../../features/criteria/redux/criteria-api';
import { CriterionDto } from '../../openapi';

export const useCriteria = () => {
  const { data: criteria = [], isFetching: isLoading } = useGetCriteriaQuery();

  const sortedCriteria = useMemo(() => [...criteria].sort(sortFn), [isLoading, criteria.length]);

  const commonCriteria = useMemo(
    () => sortedCriteria.filter((criterion) => criterion.category === CriterionDto.category.COMMON),
    [sortedCriteria.length],
  );

  const htmlCriteria = useMemo(
    () => sortedCriteria.filter((criterion) => criterion.category === CriterionDto.category.HTML),
    [sortedCriteria.length],
  );

  const stylesCriteria = useMemo(
    () => sortedCriteria.filter((criterion) => criterion.category === CriterionDto.category.STYLES),
    [sortedCriteria.length],
  );

  const responsiveCriteria = useMemo(
    () =>
      sortedCriteria.filter((criterion) => criterion.category === CriterionDto.category.RESPONSIVE),
    [sortedCriteria.length],
  );

  return {
    criteria: sortedCriteria,
    commonCriteria,
    htmlCriteria,
    stylesCriteria,
    responsiveCriteria,
    isLoading,
  };
};

const sortFn = (a: CriterionDto, b: CriterionDto) => a.number - b.number;
