import { envs } from '../shared';

export const useUploadcareApi = () => {
  const deleteFile: (fileUrl: string) => Promise<{ success: boolean; error: any | null }> = async (
    fileUrl: string,
  ) => {
    try {
      const fileUuid = fileUrl.split('ucarecdn.com/')[1].replace('/', '');
      await fetch(`https://api.uploadcare.com/files/${fileUuid}/storage/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Uploadcare.Simple ${envs.uploadcarePublicKey}:${envs.uploadcareSecretKey}`,
          Accept: 'application/vnd.uploadcare-v0.7+json',
        },
      });

      return { success: true, error: null };
    } catch (error) {
      console.log('UPLOADCARE API ERROR', error);
      return { success: false, error };
    }
  };

  return { deleteFile };
};
