import { useEffect } from 'react';
import { FormLayout } from '../../components/layout/form-layout';
import { UserForm } from '../../components/user/user-form/user-form';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { CreateUserDto, UpdateUserDto } from '../../openapi';
import { createUser, fetchMentors } from './redux/thunks';

export const AddUserFeature = () => {
  const dispatch = useAppDispatch();
  const shouldRefreshMentors = useAppSelector((state) => state.user.shouldRefreshMentors);

  useEffect(() => {
    if (shouldRefreshMentors) {
      dispatch(fetchMentors());
    }
  }, [shouldRefreshMentors]);

  const onSubmitHandler = async (user: CreateUserDto | UpdateUserDto) => {
    dispatch(createUser(user as CreateUserDto));
  };

  return (
    <FormLayout title='Користувачі - модерація'>
      <UserForm onSubmit={onSubmitHandler} />
    </FormLayout>
  );
};
