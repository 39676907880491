import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Divider, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CreateCriterionDto, CriterionDto, CriterionExample, Translation } from '../../../openapi';
import {
  EmptyTranslations,
  getLastItemFromArray,
  Language,
  NavigationRoutes,
  renderTranslation,
  UkLang,
  validateCategory,
} from '../../../shared';
import { FormFooter } from '../../forms/form-footer';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { dismissError, resetFormSubmit } from '../../../features/criteria/redux/criteria-slice';
import { useHistory } from '../../../hooks/useHistory';
import { addCriteria, updateCriteria } from '../../../features/criteria/redux/thunks';
import { LanguageSelect } from '../../form-fields/language-select';
import { CriteriaCategorySelect } from './criteria-category-select';
import { SampleField } from './sample-field/sample-field';

type Props = {
  selectedCriterion?: CriterionDto;
};

export const CriteriaForm = ({ selectedCriterion }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.criteria.error);
  const isFormSubmitted = useAppSelector((state) => state.criteria.isFormSubmitted);
  const isLoading = useAppSelector((state) => state.criteria.isLoading);

  const [language, setLanguage] = useState<Language>(UkLang);
  const [criteriaCategory, setCriteriaCategory] = useState<CriterionDto.category | EmptyCategory>(
    selectedCriterion?.category ?? emptyCategory,
  );
  const [criterionNumber, setCriterionNumber] = useState(
    selectedCriterion?.number ?? defaultCriterionNumber,
  );
  const [criterionDescription, setCriterionDescription] = useState<Translation>(
    selectedCriterion?.description ?? EmptyTranslations,
  );
  const [criterionDetails, setCriterionDetails] = useState<Translation>(
    selectedCriterion?.details ?? EmptyTranslations,
  );
  const [criterionExamples, setCriterionExamples] = useState<CriterionExample[]>(
    selectedCriterion?.examples ?? [],
  );
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  useEffect(() => {
    if (!isFormSubmitted) return;

    if (history.location.pathname === NavigationRoutes.addCriteria.route) {
      setCriterionNumber(defaultCriterionNumber);
      setCriterionDescription(EmptyTranslations);
      setCriterionDetails(EmptyTranslations);
      setCriterionExamples([]);
      setCriteriaCategory(emptyCategory);
      setSuccessMsg('Критерій був успішно створений!');
      setTimeout(() => {
        hideErrorAndSuccess();
      }, 5000);

      dispatch(resetFormSubmit());

      return;
    }

    if (history.location.pathname === NavigationRoutes.editCriteria.route) {
      setSuccessMsg('Критерій був успішно оновлен, перенаправлення через пару секунд!');
      setTimeout(() => {
        history.goBack();
      }, 1300);
      dispatch(resetFormSubmit());
    }
  }, [isFormSubmitted]);

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (criteriaCategory === emptyCategory) {
      alert('Необхідно обрати тип критерія');
      return;
    }

    const criteriaData = {
      category: criteriaCategory,
      number: criterionNumber,
      description: criterionDescription,
      details: criterionDetails,
      examples: criterionExamples,
    };

    if (history.location.pathname === NavigationRoutes.addCriteria.route) {
      dispatch(addCriteria(criteriaData as CreateCriterionDto));
    }

    if (history.location.pathname === NavigationRoutes.editCriteria.route && selectedCriterion) {
      dispatch(
        updateCriteria({
          id: selectedCriterion.id,
          criterion: { ...criteriaData },
        }),
      );
    }
  };

  const hideErrorAndSuccess = () => {
    if (error) {
      dispatch(dismissError());
    }

    if (!error && !successMsg) {
      setSuccessMsg(null);
    }
  };

  const onLanguageChange = (value: string) => {
    setLanguage(value as Language);
  };

  const onSelectTypeHandler = (categoryValue: string) => {
    if (validateCategory(categoryValue)) {
      setCriteriaCategory(categoryValue);
    }
  };

  const onDescriptionChange = (value: string) => {
    setCriterionDescription(
      language === UkLang
        ? { ...criterionDescription, uk: value }
        : { ...criterionDescription, ru: value },
    );
  };
  const onDetailsChange = (value: string) => {
    setCriterionDetails(
      language === UkLang ? { ...criterionDetails, uk: value } : { ...criterionDetails, ru: value },
    );
  };

  const onAddNewExample = () => {
    setCriterionExamples((prevState) => [
      ...prevState,
      {
        id: prevState.length ? getLastItemFromArray(prevState).id + 1 : '1',
        language: CriterionExample.language.HTMLBARS,
        code: EmptyTranslations,
        description: EmptyTranslations,
      },
    ]);
  };

  const updateExample = (id: string, updatedSample: CriterionExample) => {
    setCriterionExamples((prevState) => [
      ...[...prevState.filter((sample) => sample.id !== id), updatedSample].sort(
        (a, b) => +a.id - +b.id,
      ),
    ]);
  };

  const onRemoveExample = (id: string) => {
    setCriterionExamples((prevState) => prevState.filter((sample) => sample.id !== id));
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Card>
        <CardHeader title={selectedCriterion ? 'Редагувати критерій' : 'Додати критерій'} />
        <Divider />
        <CardContent>
          <LanguageSelect value={language} onChange={onLanguageChange} />
          <CriteriaCategorySelect
            currentValue={criteriaCategory}
            onChangeHandler={onSelectTypeHandler}
            onFocusHandler={hideErrorAndSuccess}
          />
          <TextField
            id='id'
            label='Номер критерію'
            margin='normal'
            type='number'
            inputProps={{ min: 1 }}
            variant='outlined'
            value={criterionNumber}
            onFocus={hideErrorAndSuccess}
            onChange={({ target }) => setCriterionNumber(Number(target.value))}
            required
            fullWidth
          />
          <TextField
            id='description'
            label='Опис критерію'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, criterionDescription)}
            onChange={({ target }) => onDescriptionChange(target.value)}
            minRows={4}
            required
            fullWidth
            multiline
          />
          <TextField
            id='details'
            label='Докладніше (дод. інфа, посилання)'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, criterionDetails)}
            onChange={({ target }) => onDetailsChange(target.value)}
            minRows={4}
            fullWidth
            multiline
          />
          <Button
            onClick={onAddNewExample}
            variant='outlined'
            sx={{ marginY: 2 }}
            startIcon={<AddIcon />}
          >
            Додати приклад
          </Button>
          {criterionExamples.map((sample) => (
            <SampleField
              example={sample}
              removeExample={onRemoveExample}
              updateExample={updateExample}
              key={sample.id}
              criterionLanguage={language}
            />
          ))}
        </CardContent>
        <Divider />
        <FormFooter error={error} isLoading={isLoading} successMessage={successMsg ?? ''} />
      </Card>
    </form>
  );
};

const defaultCriterionNumber = 1;
export type EmptyCategory = 'Обрати категорію';
export const emptyCategory: EmptyCategory = 'Обрати категорію';
