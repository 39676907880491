import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../components/layout/page-title';
import { Link } from '../../../components/link/link';
import { ConfirmDialog } from '../../../components/modals/confirm-dialog/confirm-dialog';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { buildNotificationArgs, useFormNotifications } from '../../../hooks/useFormNotifications';
import { StudyClassDto } from '../../../openapi';
import {
  AdminRole,
  createEditRouteUrl,
  createShowRouteUrl,
  getCreateRouteUrl,
  NavigationRoutes,
  useGetCurrentStudyClassQuery,
  useGetStudyClassesQuery,
} from '../../../shared';
import { EntityActions } from '../../../widgets/entity-actions/entity-actions';
import { useDeleteStudyClassMutation } from './redux/study-class-api';
import { selectStudyClass } from './redux/study-class-slice';

export const StudyClassListFeature = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isFetching: isLoading, data: studyClasses = [] } = useGetStudyClassesQuery();
  const [deleteStudyClass, deleteMutationState] = useDeleteStudyClassMutation();
  const { isLoading: isDeleteLoading } = deleteMutationState;
  const { data: currentStudyClass } = useGetCurrentStudyClassQuery();
  useFormNotifications(
    buildNotificationArgs(deleteMutationState, {
      successMessage: 'Потік було успішно видалено!',
    }),
  );

  const [studyClassToDelete, setStudyClassToDelete] = useState<StudyClassDto | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onSelectActionClick = (studyClass: StudyClassDto) => {
    dispatch(selectStudyClass(studyClass));
    const showPageUrl = createShowRouteUrl(NavigationRoutes.studyClass, studyClass.classNumber);
    navigate(showPageUrl);
  };
  const onEditActionClick = (studyClass: StudyClassDto) => {
    dispatch(selectStudyClass(studyClass));
    const editPageUrl = createEditRouteUrl(NavigationRoutes.studyClass, studyClass.classNumber);
    navigate(editPageUrl);
  };
  const onDeleteActionClick = (studyClass: StudyClassDto) => {
    setStudyClassToDelete(studyClass);
    setShowConfirmModal(true);
  };

  const onDeleteConfirm = () => {
    if (!studyClassToDelete) {
      return;
    }

    deleteStudyClass(studyClassToDelete.classNumber)
      .unwrap()
      .finally(() => {
        setStudyClassToDelete(null);
        setShowConfirmModal(false);
      });
  };
  const onDeleteCancel = () => {
    setShowConfirmModal(false);
    setTimeout(() => setStudyClassToDelete(null), 500);
  };

  return (
    <Container maxWidth={false}>
      <PageTitle text={'Потоки'} createPagePath={getCreateRouteUrl(NavigationRoutes.studyClass)} />
      <Box sx={{ mt: 3 }}>
        {isLoading ? (
          <LinearProgress />
        ) : studyClasses.length > 0 ? (
          <Paper sx={{ width: '100%', overflowX: 'auto' }}>
            <Box sx={{ minWidth: 700 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Номер потоку</TableCell>
                    <TableCell align={'right'}>Дата старту</TableCell>
                    <TableCell align={'right'}>Дата закінчення</TableCell>
                    <TableCell align={'right'} sx={{ marginRight: '42px', display: 'block' }}>
                      Дії
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...studyClasses].reverse().map((studyClass) => {
                    const isCurrentStudyClass =
                      currentStudyClass?.classNumber === studyClass.classNumber;
                    return (
                      <TableRow hover key={studyClass.classNumber}>
                        <TableCell>
                          <Link
                            onClick={() => onSelectActionClick(studyClass)}
                            url={createShowRouteUrl(
                              NavigationRoutes.studyClass,
                              studyClass.classNumber,
                            )}
                          >
                            <>
                              {isCurrentStudyClass ? (
                                <b>{studyClass.classNumber}</b>
                              ) : (
                                studyClass.classNumber
                              )}

                              {isCurrentStudyClass && <span> (Поточний)</span>}
                            </>
                          </Link>
                        </TableCell>
                        <TableCell align={'right'}>
                          {new Date(studyClass.startDate).toLocaleDateString('uk')}
                        </TableCell>
                        <TableCell align={'right'}>
                          {new Date(studyClass.endDate).toLocaleDateString('uk')}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                            <EntityActions
                              onEdit={() => onEditActionClick(studyClass)}
                              onDelete={() => onDeleteActionClick(studyClass)}
                              onSelect={() => onSelectActionClick(studyClass)}
                              editUrl={createEditRouteUrl(
                                NavigationRoutes.studyClass,
                                studyClass.classNumber,
                              )}
                              selectUrl={createShowRouteUrl(
                                NavigationRoutes.studyClass,
                                studyClass.classNumber,
                              )}
                              requiredRoles={[AdminRole]}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Paper>
        ) : (
          <Typography>Потоків не знайдено</Typography>
        )}
      </Box>

      <ConfirmDialog
        onConfirm={onDeleteConfirm}
        text={studyClassToDelete ? confirmationText(studyClassToDelete.classNumber) : ''}
        isOpen={showConfirmModal}
        onClose={onDeleteCancel}
        isLoading={isDeleteLoading}
      />
    </Container>
  );
};

const confirmationText = (studyClassNumber: number) =>
  `Ви впевнені, що хочете видалити потік №${studyClassNumber}?`;
