import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppApiError, RequiredDataStatusType, State } from './types';

const initialState: State = {
  apiErrorState: { isError: false, message: '' },
  initLoadingProgress: 0,
  requiredDataStatus: 'unset',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<AppApiError>) => {
      state.apiErrorState = action.payload;
    },
    updateRequiredDataStatus: (state, { payload }: PayloadAction<RequiredDataStatusType>) => {
      state.requiredDataStatus = payload;
    },
    updateLoadingProgress: (state, { payload }: PayloadAction<Record<'actionsCount', number>>) => {
      state.initLoadingProgress += 100 / payload.actionsCount;
    },
    clearLoadingProcess(state, { payload }: PayloadAction<number>) {
      state.initLoadingProgress = payload;
    },
  },
});

export default appSlice.reducer;

export const { setApiError, updateRequiredDataStatus } = appSlice.actions;
