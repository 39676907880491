import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { UserDto } from '../../../../openapi';
import { MenuProps } from '../../../../shared';
import { FormValues } from './types';

export const GenderSelect = () => {
  const { control } = useFormContext<FormValues>();
  const { field } = useController({ control, name: 'gender' });

  return (
    <FormControl fullWidth>
      <InputLabel id='gender-select'>Стать</InputLabel>
      <Select labelId='gender-select' label='Стать' MenuProps={MenuProps} {...field}>
        <MenuItem />
        <MenuItem value={UserDto.gender.MALE}>Чоловік</MenuItem>
        <MenuItem value={UserDto.gender.FEMALE}>Жінка</MenuItem>
      </Select>
    </FormControl>
  );
};
