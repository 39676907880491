import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { StudyClassDto } from '../../../../openapi';

export function createOneStudyClassUpdater(updatedStudyClass: StudyClassDto) {
  const { classNumber, endDate, startDate } = updatedStudyClass;

  return (draftStudyClass: MaybeDrafted<StudyClassDto>) => {
    if (!draftStudyClass || draftStudyClass.classNumber !== classNumber) {
      return;
    }

    draftStudyClass.startDate = startDate;
    draftStudyClass.endDate = endDate;
  };
}

export function createStudyClassesOnCreateUpdater(newStudyClass: StudyClassDto) {
  return (draftStudyClasses: MaybeDrafted<StudyClassDto[]>) => {
    const updatedDraftIdx = draftStudyClasses.findIndex(
      (studyClass) => studyClass.classNumber === newStudyClass.classNumber,
    );

    if (updatedDraftIdx === -1) {
      return;
    }

    draftStudyClasses[updatedDraftIdx] = newStudyClass;
  };
}

export function createStudyClassesOnDeleteUpdater(deletedStudyClass: StudyClassDto) {
  return (draftStudyClasses: MaybeDrafted<StudyClassDto[]>) => {
    const deletedDraftIdx = draftStudyClasses.findIndex(
      (studyClass) => studyClass.classNumber === deletedStudyClass.classNumber,
    );

    if (deletedDraftIdx === -1) {
      return;
    }

    draftStudyClasses.splice(deletedDraftIdx, 1);
  };
}
