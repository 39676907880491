export const styles = {
  container: {
    width: '100%',
    px: 2,
    py: 2,
    cursor: 'pointer',
    justifyContent: 'flex-start',
    color: 'inherit',
    fontSize: '1rem',
    borderTop: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee',
    '&:hover': { background: '#eeeeee' },
  },
  inner: {
    py: 1,
    px: 1,
  },
  textMedium: {
    fontWeight: '600',
  },
  textBaseFontSize: {
    fontSize: '1rem',
  },
  textLargeFontSize: {
    fontSize: '1.125rem',
  },
};
