import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { FilesUpload, FileUpload, Widget, WidgetAPI } from '@uploadcare/react-widget';
import React, { useRef, useState } from 'react';
import { useCurrentUser } from '../../../../hooks/redux-helper-hooks/useCurrentUser';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../hooks/useFormNotifications';
import { useUploadcareApi } from '../../../../hooks/useUploadcareApi';
import { envs, translateRole } from '../../../../shared';
import { useUpdateProfileMutation } from '../../redux/profile-api';
import { UpdateProfileArgs } from '../../redux/types';
import { styles } from './styles';

export const ProfileIntroCard = () => {
  const { currentUser } = useCurrentUser();
  const widgetApi = useRef<WidgetAPI>(null);
  const [updateProfile, mutationResult] = useUpdateProfileMutation();
  useFormNotifications(
    buildNotificationArgs(mutationResult, { successMessage: 'Аватар успішно оновлений!' }),
  );
  const { deleteFile } = useUploadcareApi();
  const [isLoading, setIsLoading] = useState(false);

  const onPhotoUploadClick = () => {
    widgetApi.current?.openDialog('file');
  };

  const onFileUpload = async (fileUpload: FileUpload | FilesUpload | null) => {
    if (!fileUpload) {
      return;
    }
    const result = await fileUpload.promise();

    if (!result.cdnUrl) {
      return;
    }

    setIsLoading(true);

    const updateProfileDto: UpdateProfileArgs = {
      avatarUrl: result.cdnUrl,
    };

    if (currentUser.avatarUrl) {
      await deleteFile(currentUser.avatarUrl);
    }

    updateProfile(updateProfileDto)
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card elevation={8}>
      <CardContent>
        <Box sx={styles.cardContent}>
          <Avatar src={currentUser.avatarUrl} sx={styles.avatar} />
          <Typography gutterBottom variant='h5'>
            {currentUser.fullName}
          </Typography>
          <Typography color='text.secondary' variant='body2'>
            {translateRole(currentUser.role)}
          </Typography>
          <Typography color='text.secondary' variant='body2'>
            {new Date(currentUser.createdAt).toLocaleDateString()}
          </Typography>
          <Widget
            publicKey={envs.uploadcarePublicKey}
            tabs='file'
            locale={'uk'}
            ref={widgetApi}
            onFileSelect={(fileUpload) => onFileUpload(fileUpload)}
            preloader={null}
            clearable
            imagesOnly
            previewStep
          />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <LoadingButton onClick={onPhotoUploadClick} variant='text' loading={isLoading} fullWidth>
          Завантажити фото
        </LoadingButton>
      </CardActions>
    </Card>
  );
};
