import { UserForm } from '../../components/user/user-form/user-form';
import { FormLayout } from '../../components/layout/form-layout';
import { UpdateUserDto } from '../../openapi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { updateUser } from './redux/thunks';

export const EditUserFeature = () => {
  const dispatch = useAppDispatch();
  const selectedUserToEdit = useAppSelector((state) => state.user.selectedUserToEdit);

  const onSubmitHandler = (user: UpdateUserDto) => {
    if (!selectedUserToEdit) {
      return;
    }

    dispatch(updateUser({ user, email: selectedUserToEdit.email }));
  };

  return (
    <FormLayout title='Користувачі - модерація'>
      <UserForm onSubmit={onSubmitHandler} selectedUser={selectedUserToEdit ?? undefined} />
    </FormLayout>
  );
};
