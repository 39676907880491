import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../../hooks/useFormNotifications';
import { LinkAccountDto } from '../../../../../openapi';
import { useMentors } from '../../../hooks/use-mentors';
import { useAddLinkedAccountMutation } from '../../../redux/profile-api';
import { styles } from './styles';
import { FormValues } from './types';
import { UsersSelect } from './users-select';

export const AddLinkedAccountForm = () => {
  const [addLinkedAccount, mutationResult] = useAddLinkedAccountMutation();
  const form = useForm<FormValues>({
    defaultValues: formDefaultValues,
    mode: 'onTouched',
  });
  useFormNotifications(
    buildNotificationArgs(mutationResult, { successMessage: 'Аккаунт успішно додано!' }),
  );
  const { control, handleSubmit, setError, clearErrors, formState } = form;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { normalizedMentors } = useMentors();
  const canSubmit = formState.dirtyFields.id && formState.dirtyFields.password;

  const onSubmit = (data: FormValues) => {
    const { id, password } = data;

    const linkAccountDto: LinkAccountDto = {
      userId: id,
      password,
      email: normalizedMentors[id].email,
    };

    addLinkedAccount(linkAccountDto)
      .unwrap()
      .then(() => {
        setIsModalOpen(false);
      })
      .catch(() => {
        setError('password', { type: 'custom' });
      });
  };

  const onAddBtnClick = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon fontSize='small'>
            <PlusIcon />
          </SvgIcon>
        }
        variant='contained'
        onClick={onAddBtnClick}
      >
        Додати
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog open={isModalOpen} onClose={onModalClose} disablePortal>
          <DialogTitle>Додати аккаунт</DialogTitle>
          <DialogContent>
            <FormProvider {...form}>
              <Box sx={styles.formContent}>
                <UsersSelect />
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label={'Пароль'}
                      type={'password'}
                      error={!!error}
                      fullWidth
                      required
                      {...(error && { onFocus: () => clearErrors('password') })}
                    />
                  )}
                  control={control}
                  name={'password'}
                />
                <Typography marginY={2} fontSize={16} fontStyle={'italic'}>
                  Цей аккаунт також отримає доступ на вхід в ваш обліковий запис
                </Typography>
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <>
              <Button onClick={onModalClose} type={'button'}>
                Закрити
              </Button>
              <LoadingButton
                type={'submit'}
                loading={mutationResult.isLoading}
                disabled={!canSubmit}
              >
                Підтвердити
              </LoadingButton>
            </>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

const formDefaultValues: FormValues = {
  id: '',
  password: '',
};
