import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ConfirmDialog } from '../../components/modals/confirm-dialog/confirm-dialog';
import { Spinner } from '../../components/spinner/Spinner';
import { UsersOverviewToolbar } from '../../components/user/user-overview-toolbar/users-overview-toolbar';
import { UsersList } from '../../components/user/users-list/users-list';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { UserDto } from '../../openapi';
import { useStudyClassSelectWidget } from '../../widgets/study-class-select/use-study-class-select-widget';
import { deleteUser, fetchMentors, fetchUsers } from './redux/thunks';
import { resetUserToDelete } from './redux/user-slice';

export const UserOverviewFeature = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector((state) => state.user.users);
  const usersLoading = useAppSelector((state) => state.user.usersLoading);
  const shouldRefreshMentors = useAppSelector((state) => state.user.shouldRefreshMentors);
  const selectedUserToDelete = useAppSelector((state) => state.user.selectedUserToDelete);
  const deleteUserLoading = useAppSelector((state) => state.user.deleteUserLoading);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<10 | 20 | 30>(20);
  const [role, setRole] = useState<UserRolesFilterType>(AllRoles);
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [mentorEmail, setMentorEmail] = useState<string | null>(null);
  const [registrationNotConfirmed, setRegistrationNotConfirmed] = useState(false);
  const { studyClass, studyClassSelectComponent } = useStudyClassSelectWidget({
    sx: studyClassWidgetStyles,
  });

  useEffect(() => {
    findUsers(lastSearchTerm).catch((error) => {
      console.log(error);
    });
  }, [page, limit, role, studyClass, mentorEmail, registrationNotConfirmed]);

  useEffect(() => {
    if (shouldRefreshMentors) {
      dispatch(fetchMentors());
    }
  }, [shouldRefreshMentors]);

  const findUsers = async (searchTerm = '') => {
    const options = {
      limit,
      skip: page * limit,
      searchTerm,
      ...(role !== AllRoles && { role }),
      ...(studyClass && { studyClass: Number(studyClass.classNumber) }),
      ...(mentorEmail && { mentorEmail }),
      ...(registrationNotConfirmed && { registrationConfirmed: false }),
    };

    dispatch(fetchUsers(options));
    setLastSearchTerm(searchTerm);
  };

  // pagination flow
  const onLimitChange = (limitValue: 10 | 20 | 30) => {
    setLimit(limitValue);
  };

  const onPageChange = (pageValue: number) => {
    setPage(pageValue);
  };

  // search flow
  const findBySearchTerm = async (searchTerm: string) => {
    setPage(0);
    await findUsers(searchTerm);
  };

  //filter flow
  const findByRole = (roleValue: UserRolesFilterType) => {
    setRole(roleValue);
  };

  const findByMentorEmail = (email: string) => {
    setMentorEmail(email === '' ? null : email);
  };

  const findUsersToApproveRegistration = (value: boolean) => {
    setRegistrationNotConfirmed(value);
  };

  // delete user flow
  const onDeleteUser = async () => {
    if (!selectedUserToDelete) {
      return;
    }

    dispatch(deleteUser(selectedUserToDelete.email));
  };

  const onConfirmCancel = () => {
    dispatch(resetUserToDelete());
  };

  return (
    <Container maxWidth={false}>
      <UsersOverviewToolbar
        onSearch={findBySearchTerm}
        onRoleChange={findByRole}
        role={role}
        studyClassSelectComponent={studyClassSelectComponent}
        onMentorEmailChange={findByMentorEmail}
        mentorEmail={mentorEmail ?? ''}
        approveRegistration={registrationNotConfirmed}
        onApproveRegistrationChange={findUsersToApproveRegistration}
      />
      <Box sx={{ mt: 3 }}>
        {usersLoading ? (
          <Spinner centered />
        ) : users.length > 0 ? (
          <UsersList
            page={page}
            limit={limit}
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
          />
        ) : (
          <Typography>Пользователи не найдены</Typography>
        )}
      </Box>

      <ConfirmDialog
        onConfirm={onDeleteUser}
        text={confirmationText}
        isOpen={Boolean(selectedUserToDelete)}
        onClose={onConfirmCancel}
        isLoading={deleteUserLoading}
      />
    </Container>
  );
};

export const AllRoles = 'всі';
export type UserRolesFilterType = UserDto.role | typeof AllRoles;

const confirmationText = 'Ви впевнені, що хочете видалити цього користувача?';

const studyClassWidgetStyles = { margin: 0 };
