import React from 'react';
import { Outlet } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/redux-helper-hooks/useCurrentUser';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { StudentRole } from '../../../shared';
import { logout } from '../../user/redux/user-slice';

type Props = {
  children?: JSX.Element;
};

export const RequireNotStudentRole = ({ children }: Props) => {
  const { currentUser } = useCurrentUser();
  const dispatch = useAppDispatch();

  if (currentUser && currentUser.role === StudentRole) {
    dispatch(logout());
    window.location.reload();
  }

  return children ? children : <Outlet />;
};
