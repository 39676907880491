import { createAsyncThunkWithTypedError } from '../../../shared';
import {
  CreateProjectDto,
  CreateTagDto,
  ProjectApi,
  ProjectDto,
  TagApi,
  TagDto,
  UpdateProjectDto,
  UpdateTagDto,
} from '../../../openapi';

export const fetchProjects = createAsyncThunkWithTypedError<ProjectDto[], void>(
  'project/fetch-projects',
  () => ProjectApi.projectControllerFindAll(),
  (state) => state.project.shouldRefreshProjects,
);

export const createProject = createAsyncThunkWithTypedError(
  'project/create',
  (project: CreateProjectDto) => ProjectApi.projectControllerCreate({ requestBody: project }),
);

type UpdateProjectArgs = { id: string; project: UpdateProjectDto };
export const updateProject = createAsyncThunkWithTypedError(
  'project/update',
  ({ project, id }: UpdateProjectArgs) =>
    ProjectApi.projectControllerUpdate({ id, requestBody: project }),
);

export const deleteProject = createAsyncThunkWithTypedError('project/delete', (projectId: string) =>
  ProjectApi.projectControllerRemove({ id: projectId }),
);

export const fetchProjectTags = createAsyncThunkWithTypedError<TagDto[], void>(
  'project/fetch-tags',
  () => TagApi.tagControllerFindAll(),
  (state) => state.project.shouldRefreshTags,
);

export const createProjectTag = createAsyncThunkWithTypedError(
  'project/create-tag',
  (tag: CreateTagDto) => TagApi.tagControllerCreate({ requestBody: tag }),
);

type UpdateProjectTagArgs = { id: string; tag: UpdateTagDto };
export const updateProjectTag = createAsyncThunkWithTypedError(
  'project/update-tag',
  ({ tag, id }: UpdateProjectTagArgs) => TagApi.tagControllerUpdate({ id, requestBody: tag }),
);

export const deleteProjectTag = createAsyncThunkWithTypedError(
  'project/delete-tag',
  (tagId: string) => TagApi.tagControllerRemove({ id: tagId }),
);
