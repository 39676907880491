export const styles = {
  container: { py: 2 },
  header: (isMobile: boolean) => ({
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: isMobile ? 'flex-start' : 'center',
  }),
  lessonReviewItem: (isMobile: boolean) => ({
    borderBottom: '1px solid #E6E8F0',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
  }),
  lessonNumber: (isMobile: boolean) => ({
    ...(!isMobile && {
      borderRight: '1px solid #E6E8F0',
      paddingRight: '10px',
      marginRight: 2,
    }),
    py: 2,
    height: '100%',
  }),
  lessonReviewStatus: (isMobile: boolean) => ({
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
  }),
  editBtn: {
    marginLeft: 'auto',
  },
  mobileEditBtn: {
    marginTop: 1,
  },
};
