export const styles = {
  container: {
    maxWidth: 550,
    width: '100%',
    m: 1,
    px: 3,
    py: '40px',
    backgroundColor: 'background.paper',
    flex: '1 1 auto',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 1,
  },
  content: {
    px: 3,
    py: '40px',
    width: '100%',
  },
  formTitle: {
    my: 2,
  },
  button: {
    mt: 3,
  },
};
