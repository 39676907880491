import * as React from 'react';
import { Box, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { StringAvatar } from '../../../../components/avatar/string-avatar';
import { useProjects } from '../../../../hooks/redux-helper-hooks/useProjects';
import { UserDto } from '../../../../openapi';
import { DoneStatus } from '../../../../shared';
import { getStudentTelegram } from '../../helpers';
import { styles } from './styles';

type Props = {
  student: UserDto;
  onStudentClick: (studentEmail: string) => void;
};

export const StudentListItem = ({ student, onStudentClick }: Props) => {
  const { normalizedProjects } = useProjects();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onStudentClick(student.email);
  };

  const telegramUsername = getStudentTelegram(student);

  return (
    <Box onClick={handleClick} sx={styles.container} title={'Відкрити повну інформацію о студенті'}>
      <Stack sx={styles.inner} {...columnStackProps}>
        <Stack {...rowStackProps} width={'100%'}>
          <StringAvatar value={student.fullName} />
          <Stack direction={'column'} width={'70%'}>
            <Typography
              sx={{ ...styles.textMedium, ...styles.textLargeFontSize }}
              noWrap
              {...textProps}
            >
              {student.fullName}
            </Typography>
            {telegramUsername && <Typography noWrap>{telegramUsername}</Typography>}
          </Stack>
        </Stack>
        <Box width={'100%'} sx={styles.textMedium}>
          Виконано:{' '}
          <Typography {...textProps}>
            {student.projects
              .map(
                (studentProject) =>
                  studentProject.lessonsReviews.filter((review) => review.status === DoneStatus)
                    .length,
              )
              .reduce((sum, completedLessonsCount) => sum + completedLessonsCount, 0)}
            /
            {student.projects
              .map((studentProject) => normalizedProjects[studentProject.projectId].lessons.length)
              .reduce((sum, lessonsCount) => sum + lessonsCount, 0)}{' '}
            уроків
          </Typography>
        </Box>
        <Box width={'100%'} sx={styles.textMedium}>
          Проєкти:{' '}
          <Typography {...textProps}>
            {student.projects
              .map((studentProject) => normalizedProjects[studentProject.projectId].name.uk)
              .join(', ')}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const textProps: TypographyProps & { component: string } = {
  component: 'span',
  fontSize: 'inherit',
};
const rowStackProps: StackProps = {
  direction: 'row',
  alignItems: 'center',
  spacing: 2,
};
const columnStackProps: StackProps = {
  direction: 'column',
  alignItems: 'flex-start',
  width: '100%',
};
