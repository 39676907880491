import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { FormInput } from '../../../../../components/form-fields/form-input';
import { FormDialog } from '../../../../../components/modals/form-dialog';
import { useProjects } from '../../../../../hooks/redux-helper-hooks/useProjects';
import { useNullDebounce } from '../../../../../hooks/useNullDebounce';
import { UserDto } from '../../../../../openapi';
import { styles } from '../styles';
import { LessonCriteria } from './lesson-criteria/lesson-criteria';
import { ProjectLessonsSelect } from './project-lessons-select';

type Props = {
  student: UserDto | null;
  projectId: string;
  onModalClose: () => void;
};

export const CriteriaProgressForm = ({ student: studentProp, onModalClose, projectId }: Props) => {
  const student = useNullDebounce(studentProp, 500);
  const matchesDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { normalizedProjects } = useProjects();
  const project = normalizedProjects[projectId];
  const [selectedLesson, setSelectedLesson] = useState(project.lessons[0]?.number ?? 1);

  const isModalOpen = !!studentProp;
  const lessonDto = project.lessons.find((lesson) => lesson.number === selectedLesson);
  const studentProject = student?.projects.find((p) => p.projectId === projectId);
  const onLessonChange = (lessonNumber: number) => {
    setSelectedLesson(lessonNumber);
  };

  return (
    <FormDialog
      title={'Прогрес виконання критеріїв'}
      isOpen={isModalOpen}
      onClose={onModalClose}
      dialogActions={
        <Button onClick={onModalClose} type={'button'}>
          Закрити
        </Button>
      }
    >
      <Box sx={() => styles.modalForm(matchesDesktop)}>
        <FormInput
          id={'criteria-progress-project'}
          label={'Назва проєкту'}
          value={project.name.uk}
          readOnly
        />
        {project.lessons.length ? (
          <ProjectLessonsSelect
            project={project}
            selectedLesson={selectedLesson}
            onLessonChange={onLessonChange}
          />
        ) : (
          <p>В проєкті ще нема уроків</p>
        )}
        {lessonDto && studentProject && student && (
          <LessonCriteria
            lesson={lessonDto}
            studentProject={studentProject}
            studentEmail={student.email}
          />
        )}
      </Box>
    </FormDialog>
  );
};
