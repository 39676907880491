import { LoadingButton } from '@mui/lab';
import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormInput } from '../../../../../components/form-fields/form-input';
import { FormDialog } from '../../../../../components/modals/form-dialog';
import { ProjectsSelect } from '../../../../../components/user/user-form/projects-select';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../../hooks/useFormNotifications';
import { UserDto } from '../../../../../openapi';
import { useUpdateStudentProjectsMutation } from '../../../redux/class-spreadsheet-api';
import { styles } from '../styles';

type Props = {
  student: UserDto | null;
  isModalOpen: boolean;
  onModalClose: () => void;
};

export const ProjectSettingsForm = ({ isModalOpen, onModalClose, student }: Props) => {
  const matchesDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [projectsIds, setProjectsIds] = useState<string[]>([]);
  const [updateStudentsProjects, mutationResult] = useUpdateStudentProjectsMutation();

  useFormNotifications(
    buildNotificationArgs(mutationResult, {
      successMessage: 'Проєкти учня були успішно оновлені!',
      onSuccess: onModalClose,
    }),
  );

  useEffect(() => {
    if (!student || !isModalOpen) {
      return;
    }

    const ids = student.projects.map(({ projectId }) => projectId);
    setProjectsIds(ids);
  }, [student, isModalOpen]);

  const onSubmit = () => {
    if (!student) {
      return;
    }

    updateStudentsProjects({ studentEmail: student.email, projectsIds })
      .unwrap()
      .catch(() => onModalClose());
  };

  const onProjectSelectChange = (value: string | string[]) =>
    setProjectsIds(typeof value === 'string' ? value.split(',') : value);

  const canSubmit =
    projectsIds.length !== (student?.projects ?? []).length
      ? true
      : (student?.projects ?? []).reduce(
        (isChanged, { projectId }) => (isChanged ? true : !projectsIds.includes(projectId)),
        false,
      );

  return (
    <FormDialog
      title={'Налаштування проєктів'}
      isOpen={isModalOpen && !!student}
      onClose={onModalClose}
      dialogActions={
        <>
          <Button onClick={onModalClose} type={'button'}>
            Закрити
          </Button>
          <LoadingButton
            onClick={onSubmit}
            type={'submit'}
            loading={mutationResult.isLoading}
            disabled={!canSubmit}
          >
            Підтвердити
          </LoadingButton>
        </>
      }
    >
      <Box sx={() => styles.modalForm(matchesDesktop)}>
        <FormInput
          id={'student-name'}
          label={'Студент'}
          value={String(student?.fullName)}
          readOnly
        />
        <ProjectsSelect projectsIds={projectsIds} onChangeHandler={onProjectSelectChange} />
      </Box>
    </FormDialog>
  );
};
