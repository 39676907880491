import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { UserRolesFilterType } from '../../../features/user/user-overview.feature';
import { PageTitle } from '../../layout/page-title';
import { PageToolbar } from '../../page-toolbar/page-toolbar';
import { ApproveRegistrationFilter } from './filters/approve-registration-filter';
import { MentorFilter } from './filters/mentor-filter';
import { RoleFilter } from './filters/role-filter';
import { SearchFilter } from './filters/search-filter';

type Props = {
  onSearch: (searchTerm: string) => void;
  role: UserRolesFilterType;
  onRoleChange: (role: UserRolesFilterType) => void;
  studyClassSelectComponent: JSX.Element;
  onMentorEmailChange: (value: string) => void;
  mentorEmail: string;
  approveRegistration: boolean;
  onApproveRegistrationChange: (value: boolean) => void;
};

export const UsersOverviewToolbar = ({
  onSearch,
  onRoleChange,
  role,
  onMentorEmailChange,
  mentorEmail,
  approveRegistration,
  onApproveRegistrationChange,
  studyClassSelectComponent,
}: Props) => {
  return (
    <Box>
      <PageTitle text={'Користувачі'} />
      <PageToolbar>
        <>
          <Box sx={{ display: 'flex', marginBottom: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ ...fullWidthStyles, marginRight: 2 }}>
                  <SearchFilter onSearch={onSearch} />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={fullWidthStyles}>
                  <RoleFilter role={role} onRoleChange={onRoleChange} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', marginBottom: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={fullWidthStyles}>{studyClassSelectComponent}</Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={fullWidthStyles}>
                  <MentorFilter
                    mentorEmail={mentorEmail}
                    onMentorEmailChange={onMentorEmailChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ApproveRegistrationFilter
                value={approveRegistration}
                onValueChange={onApproveRegistrationChange}
              />
            </Grid>
          </Grid>
        </>
      </PageToolbar>
    </Box>
  );
};

const fullWidthStyles = { maxWidth: 500, width: '100%' };
