import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import {
  buildNotificationArgs,
  useFormNotificationsWithRedirect,
  UseFormNotificationsWithRedirectArgs,
} from '../../../../hooks/useFormNotifications';
import { getShowRouteUrl, NavigationRoutes } from '../../../../shared';
import { selectStudyClass } from '../redux/study-class-slice';

export const useFormSuccess = (
  args: {
    mutationResult: Parameters<typeof buildNotificationArgs>[0];
  } & Partial<UseFormNotificationsWithRedirectArgs>,
) => {
  const dispatch = useAppDispatch();
  const { mutationResult, successMessage = 'Успіх!' } = args;

  useFormNotificationsWithRedirect({
    ...buildNotificationArgs(mutationResult, { successMessage }),
    redirectUrl: getShowRouteUrl(
      NavigationRoutes.studyClass,
      String(mutationResult.data?.classNumber),
    ),
    onSuccess: () => {
      if (!mutationResult.data) {
        return;
      }

      dispatch(selectStudyClass(mutationResult.data));
    },
  });
};
