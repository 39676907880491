import { CriterionDto } from '../../../openapi';
import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';

const basePath = 'criteria/';

const getCriteriaEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(basePath),
};

export const criteriaApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCriteria: builder.query<CriterionDto[], void>({
      query: () => ({
        url: getCriteriaEndpoint.path,
        method: getCriteriaEndpoint.method,
      }),
    }),
  }),
});

export const { useGetCriteriaQuery } = criteriaApi;
