import { LoadingButton } from '@mui/lab';
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCurrentUser } from '../../../../hooks/redux-helper-hooks/useCurrentUser';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../hooks/useFormNotifications';
import { SocialMediaDto, UserDto } from '../../../../openapi';
import { useUpdateProfileMutation } from '../../redux/profile-api';
import { UpdateProfileArgs } from '../../redux/types';
import { GenderSelect } from './gender-select';
import { TextInput } from './text-input';
import { FormValues } from './types';

export const ProfileDetailsForm = () => {
  const { currentUser } = useCurrentUser();
  const form = useForm<FormValues>({ defaultValues: getDefaultValues(currentUser) });
  const { handleSubmit, formState, reset } = form;
  const [updateProfile, mutationResult] = useUpdateProfileMutation();
  const onSuccessUpdate = useCallback(() => {
    reset(getDefaultValues(currentUser), { keepDirty: false });
  }, [currentUser, reset]);
  useFormNotifications(
    buildNotificationArgs(mutationResult, {
      successMessage: 'Профіль успішно оновлений',
      onSuccess: onSuccessUpdate,
    }),
  );
  const { isLoading } = mutationResult;

  const onSubmit = (data: FormValues) => {
    if (!formState.isDirty) {
      return;
    }

    const { country, gender, phoneNumber, lastName, firstName, telegram } = data;
    const fullName = `${firstName} ${lastName}`;
    const socialMedia: SocialMediaDto[] = [
      ...(currentUser.socialMedia ?? []).filter(
        (media) => media.mediaTitle !== SocialMediaDto.mediaTitle.TELEGRAM,
      ),
      ...(telegram
        ? [
          {
            mediaTitle: SocialMediaDto.mediaTitle.TELEGRAM,
            profileLink: `https://t.me/${telegram.replace('@', '')}`,
            username: telegram,
          },
        ]
        : []),
    ];

    const updateProfileDto: UpdateProfileArgs = {
      ...(currentUser.fullName !== fullName && { fullName }),
      ...(currentUser.country !== country && { country }),
      ...(currentUser.phoneNumber !== phoneNumber && { phoneNumber }),
      ...(currentUser.gender !== gender && { gender: gender as UserDto.gender }),
      socialMedia,
    };

    updateProfile(updateProfileDto);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <Card elevation={8}>
          <CardHeader title='Основна інформація' />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <TextInput name={'firstName'} label={"Ім'я"} required />
                <TextInput name={'lastName'} label={'Прізвище'} required />
                <TextInput name={'email'} label={'Email'} readonly />
                <TextInput name={'phoneNumber'} label={'Телефон'} type={'tel'} />
                <TextInput name={'country'} label={'Країна'} />
                <TextInput name={'telegram'} label={'Телеграм'} />
                <Grid item xs={12}>
                  <GenderSelect />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <LoadingButton
              variant='contained'
              type={'submit'}
              loading={isLoading}
              disabled={!formState.isDirty}
            >
              Зберегти
            </LoadingButton>
          </CardActions>
        </Card>
      </FormProvider>
    </form>
  );
};

const getDefaultValues = (user: UserDto): FormValues => {
  const { email, country, gender, phoneNumber, fullName, socialMedia = [] } = user;

  const telegram = socialMedia.find(
    (media) => media.mediaTitle === SocialMediaDto.mediaTitle.TELEGRAM,
  )?.username;
  const [firstName, lastName] = fullName.split(' ');

  return {
    email,
    country: country ?? '',
    gender: gender ?? '',
    phoneNumber: phoneNumber ?? '',
    telegram: telegram ?? '',
    firstName,
    lastName: lastName ?? '',
  };
};
