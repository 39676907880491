import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { ApiError as OpenapiError } from '../../openapi';
import { ApiError } from '../../shared';

// TODO: remove 'error' prop usage
type Props = {
  // Deprecated
  error?: OpenapiError | undefined;
  apiError?: ApiError;
  isLoading?: boolean;
  successMessage?: string;
  disabled?: boolean;
  onCancel?: () => void;
};

export const FormFooter = ({ isLoading, disabled = false, onCancel }: Props) => (
  <Stack
    direction={'row'}
    spacing={1}
    justifyContent={'flex-end'}
    sx={{
      p: 2,
    }}
  >
    {onCancel && (
      <Button variant='outlined' type='button' onClick={onCancel}>
        Скасувати
      </Button>
    )}
    <LoadingButton
      color='primary'
      variant='contained'
      type='submit'
      loading={isLoading}
      disabled={disabled}
    >
      Підтвердити
    </LoadingButton>
  </Stack>
);
