import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormPageLayout } from '../../../components/layout/form-page-layout';
import { ConfirmDialog } from '../../../components/modals/confirm-dialog/confirm-dialog';
import {
  buildNotificationArgs,
  useFormNotificationsWithRedirect,
} from '../../../hooks/useFormNotifications';
import {
  createEditRouteUrl,
  getLastItemFromArray,
  getListRouteUrl,
  NavigationRoutes,
} from '../../../shared';
import { FieldRow } from './components/field-row';
import { useSelectedStudyClass } from './hooks/useSelectedStudyClass';
import { useDeleteStudyClassMutation, useGetOneStudyClassQuery } from './redux/study-class-api';

export const ShowStudyClassFeature = () => {
  const navigate = useNavigate();
  const selectedStudyClass = useSelectedStudyClass();
  const { data: studyClass, isFetching: isGetOneLoading } = useGetOneStudyClassQuery(
    Number(getLastItemFromArray(location.pathname.split('/'))),
    {
      skip: !!selectedStudyClass,
      selectFromResult: (result) => ({
        ...result,
        ...(selectedStudyClass && { data: selectedStudyClass }),
      }),
    },
  );
  const [deleteStudyClass, deleteMutationState] = useDeleteStudyClassMutation();
  useFormNotificationsWithRedirect({
    ...buildNotificationArgs(deleteMutationState, {
      successMessage: 'Потік було успішно видалено!',
    }),
    redirectUrl: getListRouteUrl(NavigationRoutes.studyClass),
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const editPageUrl = createEditRouteUrl(
    NavigationRoutes.studyClass,
    studyClass?.classNumber ?? '',
  );

  const onEditClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (!studyClass) {
      return;
    }

    navigate(editPageUrl);
  };

  const onDeleteClick = () => {
    setShowConfirmModal(true);
  };

  const onDeleteConfirm = () => {
    if (!studyClass) {
      return;
    }

    deleteStudyClass(studyClass.classNumber)
      .unwrap()
      .finally(() => {
        setShowConfirmModal(false);
      });
  };

  const onDeleteCancel = () => {
    setShowConfirmModal(false);
  };

  return (
    <FormPageLayout
      title={'Модерація потоків'}
      listUrl={getListRouteUrl(NavigationRoutes.studyClass)}
    >
      <>
        <Card>
          <Box sx={{ position: 'relative' }}>
            <CardHeader title={'Деталі потоку'} />
            {isGetOneLoading && <LinearProgress sx={{ position: 'absolute', width: '100%' }} />}
          </Box>
          <Divider />
          <CardContent>
            <FieldRow
              title={'Номер потоку'}
              value={studyClass ? studyClass.classNumber : ''}
              isLoading={isGetOneLoading}
            />
            <Divider />
            <FieldRow
              title={'Дата початку'}
              value={studyClass ? getLocaleDate(studyClass.startDate) : ''}
              isLoading={isGetOneLoading}
            />
            <Divider />
            <FieldRow
              title={'Дата закінчення'}
              value={studyClass ? getLocaleDate(studyClass.endDate) : ''}
              isLoading={isGetOneLoading}
            />
            <Divider />
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', paddingBottom: 2, px: 2 }}>
            <Box>
              <Button variant={'outlined'} component={'a'} onClick={onEditClick} href={editPageUrl}>
                Редагувати
              </Button>
            </Box>
            <Box>
              <Button variant={'contained'} color={'error'} onClick={onDeleteClick}>
                Видалити
              </Button>
            </Box>
          </CardActions>
        </Card>

        <ConfirmDialog
          onConfirm={onDeleteConfirm}
          text={confirmationText(studyClass?.classNumber ?? 0)}
          isOpen={showConfirmModal}
          onClose={onDeleteCancel}
          isLoading={deleteMutationState.isLoading}
        />
      </>
    </FormPageLayout>
  );
};

function getLocaleDate(date: string) {
  return new Date(date).toLocaleDateString('uk', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

const confirmationText = (studyClassNumber: number) =>
  `Ви впевнені, що хочете видалити потік №${studyClassNumber}?`;
