import { createSelector } from '@reduxjs/toolkit';
import { GetUsersResponseDto, LinkedUserDto, UserApi, UserDto } from '../../../openapi';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';
import { GetUsersArgs } from './types';

const basePath = 'user/';

const getCurrentUserEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(basePath + 'current'),
};

const getOneUserEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(basePath + 'one'),
};

const getLinkedUsersEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(basePath + 'linked-users'),
};

const getUsersEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(basePath),
};

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserDto, void>({
      query: () => ({
        url: getCurrentUserEndpoint.path,
        method: getCurrentUserEndpoint.method,
      }),
    }),
    getOneUser: builder.query<UserDto | null, string>({
      query: (email) => ({
        url: getOneUserEndpoint.path,
        method: getOneUserEndpoint.method,
        params: {
          email,
        },
      }),
    }),
    getLinkedUsers: builder.query<LinkedUserDto[], void>({
      query: () => ({
        url: getLinkedUsersEndpoint.path,
        method: getLinkedUsersEndpoint.method,
      }),
      providesTags: [ApiEntitiesTags.linkedUsers],
    }),
    getUsers: builder.query<GetUsersResponseDto, GetUsersArgs>({
      query: (args) => {
        const { page, studyClass, ...params } = args;

        const queryParams: Parameters<typeof UserApi.userControllerFindAll>[number] = {
          ...params,
          skip: page === 1 ? 0 : (page - 1) * (params.limit ?? 200),
          ...(studyClass && { studyClass }),
        };
        return {
          url: getUsersEndpoint.path,
          method: getUsersEndpoint.method,
          params: queryParams,
        };
      },
    }),
  }),
});

// TODO: using study class query on users fetching ensure that 0 values in not passed
export const { useGetOneUserQuery, useGetLinkedUsersQuery, useGetUsersQuery } = userApi;

export const currentUserSelector = createSelector(
  userApi.endpoints.getCurrentUser.select(),
  (result) => result.data ?? null,
);
