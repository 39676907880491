import { createSvgIcon } from '@mui/material/utils';

export const HighPriorityIcon = createSvgIcon(
  <svg viewBox='0 0 24 24' fill='currentColor'>
    <path d='M0 0h24v24H0z' fill='none' />
    <circle cx='12' cy='19' r='2' />
    <path d='M10 3h4v12h-4z' />
  </svg>,
  'High Priority',
);
