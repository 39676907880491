import { Box, Divider, Drawer, Link as MLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { translateRole } from '../../shared';
import { Loader } from '../loader/loader';
import { Spinner } from '../spinner/Spinner';
import logo from './assets/logo.svg';
import { NavAccordion } from './sidebar-items/nav-accordion';
import { NavItem } from './sidebar-items/nav-item';
import { items } from './sidebar-items/nav-ltems';

type Props = {
  open: boolean;
  onClose: () => void;
  lgUp: boolean;
};

export const DashboardSidebar = ({ open, onClose, lgUp }: Props) => {
  const { currentUser } = useCurrentUser();

  const content = (
    <nav>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            <Link to='/'>
              <img src={logo} width={42} height={42} alt='Логотип' />
            </Link>
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1,
              }}
            >
              <Loader isLoading={!currentUser} spinner={<Spinner centered />}>
                <div>
                  <Typography color='inherit' variant='subtitle1'>
                    {currentUser && currentUser.fullName}
                  </Typography>
                  <Typography color='neutral.400' variant='body2'>
                    Роль : {currentUser && translateRole(currentUser.role)}
                  </Typography>
                </div>
              </Loader>
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3,
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Loader isLoading={!currentUser} spinner={<Spinner centered />}>
              <>
                {items.map((item) =>
                  currentUser?.role && item.requiredRoles.includes(currentUser.role) ? (
                    item.children ? (
                      <NavAccordion item={item} key={item.title} />
                    ) : (
                      <NavItem item={item} key={item.title} />
                    )
                  ) : null,
                )}
              </>
            </Loader>
          </Box>
          <Divider sx={{ borderColor: '#2D3748' }} />
          <Box
            sx={{
              px: 2,
              py: 3,
            }}
          >
            <Typography color='neutral.100' variant='subtitle2'>
              Знайшли проблему?
            </Typography>
            <MLink
              href='https://t.me/quant2m_breaker'
              color='neutral.500'
              variant='body2'
              target='_blank'
              underline='hover'
            >
              Пишіть сюди!
            </MLink>
          </Box>
        </div>
      </Box>
    </nav>
  );

  return (
    <Drawer
      anchor='left'
      variant={lgUp ? 'permanent' : 'temporary'}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
    >
      {content}
    </Drawer>
  );
};
