import { Alert, Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { getCriteriaPreview } from '../../../features/project/redux/utils';
import { useCriteria } from '../../../hooks/redux-helper-hooks/useCriteria';
import { CriterionDto, ProjectLessonDto, RequirementCriteriaDto } from '../../../openapi';
import { EmptyTranslations, Language, renderTranslation, UkLang } from '../../../shared';
import { LanguageSelect } from '../../form-fields/language-select';
import { CriteriaAccordionList } from './criteria-accordion-list';
import { CriteriaSelect } from './criteria-select';

const { HTML, STYLES, RESPONSIVE, COMMON } = RequirementCriteriaDto.category;

type Props = {
  lesson: ProjectLessonDto;
  onLessonUpdate: (lesson: ProjectLessonDto) => void;
};

export const ProjectLessonForm = ({ lesson, onLessonUpdate }: Props) => {
  const { criteria: allCriteria } = useCriteria();
  const criteriaPreview = getCriteriaPreview(allCriteria);
  const [language, setLanguage] = useState<Language>(UkLang);

  const commonCriteria = getCriteriaByCategory(lesson.criteria, COMMON);
  const htmlCriteria = getCriteriaByCategory(lesson.criteria, HTML);
  const stylesCriteria = getCriteriaByCategory(lesson.criteria, STYLES);
  const responsiveCriteria = getCriteriaByCategory(lesson.criteria, RESPONSIVE);

  const onlanguagechange = (value: Language) => {
    setLanguage(value);
  };

  const onValuesChange = (
    value: string,
    key: keyof Omit<ProjectLessonDto, 'number' | 'criteria'>,
  ) => {
    onLessonUpdate({
      ...lesson,
      [key]: {
        ...(lesson[key] ?? EmptyTranslations),
        ...(language === UkLang ? { uk: value } : { ru: value }),
      },
    });
  };

  const onCriteriaChange = (value: string[], category: CriterionDto.category) => {
    const updatedCriteriaList = value.map(Number);
    const notUpdatedCriteria = lesson.criteria.filter(
      (criterion) => criterion.category !== category,
    );

    const updatedCriteriaByCategory = allCriteria.filter(
      (criterion) =>
        criterion.category === category && updatedCriteriaList.includes(criterion.number),
    );

    onLessonUpdate({ ...lesson, criteria: [...notUpdatedCriteria, ...updatedCriteriaByCategory] });
  };

  return (
    <Box>
      <LanguageSelect value={language} onChange={onlanguagechange} />
      <CriteriaSelect
        criteriaPreview={criteriaPreview.commonCriteria}
        criteriaState={commonCriteria}
        handleChange={(value) => onCriteriaChange(value, COMMON)}
        label='Критерії "Common"'
      />
      <CriteriaAccordionList criteria={criteriaPreview.commonCriteria} category='Common' />
      <CriteriaSelect
        criteriaPreview={criteriaPreview.htmlCriteria}
        criteriaState={htmlCriteria}
        handleChange={(value) => onCriteriaChange(value, HTML)}
        label='Критерії "HTML"'
      />
      <CriteriaAccordionList criteria={criteriaPreview.htmlCriteria} category='HTML' />
      <CriteriaSelect
        criteriaPreview={criteriaPreview.stylesCriteria}
        criteriaState={stylesCriteria}
        handleChange={(value) => onCriteriaChange(value, STYLES)}
        label='Критерії "Styles"'
      />
      <CriteriaAccordionList criteria={criteriaPreview.stylesCriteria} category='Styles' />
      <CriteriaSelect
        criteriaPreview={criteriaPreview.responsiveCriteria}
        criteriaState={responsiveCriteria}
        handleChange={(value) => onCriteriaChange(value, RESPONSIVE)}
        label='Критерії "Responsive"'
      />
      <CriteriaAccordionList criteria={criteriaPreview.responsiveCriteria} category='Responsive' />
      <Alert severity='info' sx={{ marginTop: 4, marginBottom: 3 }}>
        Наступні поля потрібно заповнювати розміткою, швидше за все це буде список - отже, потрібно
        оформити все через ul-li, при необхідності можна вкласти посилання, і будь-які інші теги
      </Alert>
      <TextField
        id='task-description'
        label='Блок - Задачі*'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(language, lesson.taskDescription)}
        onChange={({ target }) => onValuesChange(target.value, 'taskDescription')}
        minRows={4}
        fullWidth
        multiline
      />
      <TextField
        id='markup-description'
        label='Блок - Верстаєм'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(language, lesson.markupDescription ?? EmptyTranslations)}
        onChange={({ target }) => onValuesChange(target.value, 'markupDescription')}
        minRows={4}
        fullWidth
        multiline
      />
      <TextField
        id='hints-description'
        label='Блок - Підказки'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(language, lesson.hints ?? EmptyTranslations)}
        onChange={({ target }) => onValuesChange(target.value, 'hints')}
        minRows={4}
        fullWidth
        multiline
      />
      <TextField
        id='additional-tasks'
        label='Блок - Додаткові завдання'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(language, lesson.additionalTasks ?? EmptyTranslations)}
        onChange={({ target }) => onValuesChange(target.value, 'additionalTasks')}
        minRows={4}
        fullWidth
        multiline
      />
    </Box>
  );
};

const getCriteriaByCategory = (
  criteriaIds: CriterionDto[],
  category: RequirementCriteriaDto.category,
) =>
  criteriaIds
    .filter((criterion) => criterion.category === category)
    .map((criterion) => String(criterion.number));
