import {
  CriterionCommonCategory,
  CriterionHtmlCategory,
  CriterionResponsiveCategory,
  CriterionStylesCategory,
} from '../../../shared';
import { CriterionDto } from '../../../openapi';
import { AllCriteriaPreview, CriterionPreview } from './types';

export const getCriteriaPreview = (criteria: CriterionDto[]) => {
  const mapToPreview = (criteriaToMap: CriterionDto[]) =>
    criteriaToMap.map(
      (criterion) =>
        ({
          id: criterion.id,
          number: criterion.number,
          info: criterion.description,
        } as CriterionPreview),
    );

  return {
    commonCriteria: mapToPreview(
      criteria.filter((criterion) => criterion.category === CriterionCommonCategory),
    ),
    htmlCriteria: mapToPreview(
      criteria.filter((criterion) => criterion.category === CriterionHtmlCategory),
    ),
    stylesCriteria: mapToPreview(
      criteria.filter((criterion) => criterion.category === CriterionStylesCategory),
    ),
    responsiveCriteria: mapToPreview(
      criteria.filter((criterion) => criterion.category === CriterionResponsiveCategory),
    ),
  } as AllCriteriaPreview;
};
