import { Container } from '@mui/material';
import { ProfileFeature } from '../../features/profile/profile.feature';

export const ProfilePage = () => {
  return (
    <main>
      <Container maxWidth={false}>
        <ProfileFeature />
      </Container>
    </main>
  );
};
