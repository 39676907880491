import React from 'react';
import { useCriteria } from '../../hooks/redux-helper-hooks/useCriteria';
import { CriteriaAccordion } from './criteria-accordion';

export const CriteriaList = () => {
  const { htmlCriteria, stylesCriteria, commonCriteria, responsiveCriteria } = useCriteria();

  return (
    <>
      <CriteriaAccordion criteria={commonCriteria} category={'Common'} />
      <CriteriaAccordion criteria={htmlCriteria} category={'HTML'} />
      <CriteriaAccordion criteria={stylesCriteria} category={'Styles'} />
      <CriteriaAccordion criteria={responsiveCriteria} category={'Responsive'} />
    </>
  );
};
