export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type EndpointsSchema = { method: HTTPMethod; path: string };

export const ApiEntitiesTags = {
  criteria: 'criteria',
  studyClasses: 'studyClasses',
  currentStudyClass: 'currentStudyClass',
  oneStudyClass: 'oneStudyClass',
  users: 'users',
  projects: 'projects',
  mentorsStudents: 'mentorsStudents',
  oneMentorStudent: 'oneMentorStudent',
  linkedUsers: 'linkedUsers',
  dashboardStatistics: 'dashboardStatistics',
  studentsCountByStudyClasses: 'studentsCountByStudyClasses',
};

export function createEndpoint(path: string) {
  return `/api/${path}`;
}
