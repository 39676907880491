import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunkWithTypedError } from '../../../shared';
import {
  CreateCriterionDto,
  CriteriaApi,
  CriterionDto,
  UpdateCriterionDto,
} from '../../../openapi';

export const fetchCriteria = createAsyncThunkWithTypedError<CriterionDto[], void>(
  'criteria/fetch',
  () => CriteriaApi.criterionControllerFindAll(),
  (state) => !state.criteria.isDataLoaded,
);

export const addCriteria = createAsyncThunkWithTypedError(
  'criteria/add',
  (criterion: CreateCriterionDto) =>
    CriteriaApi.criterionControllerCreate({ requestBody: criterion }),
);

type UpdateCriteriaArgs = { id: string; criterion: UpdateCriterionDto };

export const updateCriteria = createAsyncThunkWithTypedError(
  'criteria/update',
  ({ criterion, id }: UpdateCriteriaArgs) =>
    CriteriaApi.criterionControllerUpdate({ id, requestBody: criterion }),
);

export const deleteCriteria = createAsyncThunk('criteria/delete', (id: string) =>
  CriteriaApi.criterionControllerRemove({ id }),
);
