import { UserDto, LinkedAccountDto, LinkAccountDto } from '../../../openapi';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';
import { userApi } from '../../user/redux/user-api';
import { DeleteLinkedAccountArgs, UpdateProfileArgs } from './types';

const updateProfileEndpoint: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint('user/profile'),
};

const addLinkedAccountEndpoint: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint('linked-account'),
};

const deleteLinkedAccountEndpoint: EndpointsSchema = {
  method: 'DELETE',
  path: createEndpoint('linked-account'),
};

export const profileApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation<UserDto, UpdateProfileArgs>({
      query: (updateProfileDto) => ({
        url: updateProfileEndpoint.path,
        method: updateProfileEndpoint.method,
        body: updateProfileDto,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getCurrentUser', undefined, (draftCurrentUser) => {
            if (!draftCurrentUser) {
              return;
            }

            const { fullName, gender, phoneNumber, socialMedia, country, avatarUrl } = args;

            const shouldUpdate = <T>(value: T | undefined): value is T =>
              typeof value !== 'undefined';

            if (shouldUpdate(fullName)) draftCurrentUser.fullName = fullName;
            if (shouldUpdate(gender)) draftCurrentUser.gender = gender;
            if (shouldUpdate(phoneNumber)) draftCurrentUser.phoneNumber = phoneNumber;
            if (shouldUpdate(socialMedia)) draftCurrentUser.socialMedia = socialMedia;
            if (shouldUpdate(country)) draftCurrentUser.country = country;
            if (shouldUpdate(avatarUrl)) draftCurrentUser.avatarUrl = avatarUrl;
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          // Error is handled in useFormNotifications hook
          patchResult.undo();
        }
      },
    }),
    deleteLinkedAccount: builder.mutation<void, DeleteLinkedAccountArgs>({
      query: ({ linkedAccountId }) => ({
        url: `${deleteLinkedAccountEndpoint.path}/${linkedAccountId}`,
        method: deleteLinkedAccountEndpoint.method,
      }),
      invalidatesTags: [ApiEntitiesTags.linkedUsers],
    }),
    addLinkedAccount: builder.mutation<LinkedAccountDto, LinkAccountDto>({
      query: (linkAccountDto) => ({
        url: addLinkedAccountEndpoint.path,
        method: addLinkedAccountEndpoint.method,
        body: linkAccountDto,
      }),
      invalidatesTags: [ApiEntitiesTags.linkedUsers],
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useDeleteLinkedAccountMutation,
  useAddLinkedAccountMutation,
} = profileApi;
