import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';

type Props = {
  mentorEmail: string;
  onMentorEmailChange: (value: string) => void;
};

export const MentorFilter = ({ mentorEmail, onMentorEmailChange }: Props) => {
  const mentors = useAppSelector((state) => state.user.mentors);

  const onSelectChange = (value: string) => {
    onMentorEmailChange(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id='user-role'>Ментор учня</InputLabel>
      <Select
        labelId='student-email'
        id='student-email'
        label='Ментор учня'
        value={mentorEmail}
        onChange={({ target }) => onSelectChange(target.value)}
        fullWidth
      >
        <MenuItem value={''} defaultChecked>
          Без фільтру
        </MenuItem>
        {mentors.map((mentor) => (
          <MenuItem value={mentor.email} key={mentor.email}>
            {mentor.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
