import { useNavigate } from 'react-router-dom';
import { FormPageLayout } from '../../../components/layout/form-page-layout';
import { ApiError, getListRouteUrl, NavigationRoutes } from '../../../shared';
import { StudyClassForm } from './components/study-class-form';
import { useFormSuccess } from './hooks/useFormSuccess';
import { useCreateStudyClassMutation } from './redux/study-class-api';

export const CreateStudyClassFeature = () => {
  const [createStudyClassMutation, mutationResult] = useCreateStudyClassMutation();
  const { isLoading, error, reset } = mutationResult;
  const navigate = useNavigate();

  useFormSuccess({ mutationResult, successMessage: 'Потік був успішно створений!' });

  const cancelEditHandler = () => {
    navigate(-1);
  };

  return (
    <FormPageLayout
      title={'Модерація потоків'}
      listUrl={getListRouteUrl(NavigationRoutes.studyClass)}
    >
      <StudyClassForm
        submitForm={createStudyClassMutation}
        error={error as ApiError}
        isSubmitLoading={isLoading}
        resetMutationState={reset}
        onCancel={cancelEditHandler}
      />
    </FormPageLayout>
  );
};
