import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateUserDto, UpdateUserDto, UserApi, UserDto } from '../../../openapi';
import { createAsyncThunkWithTypedError } from '../../../shared';
import { FetchUsersOptions } from './types';

export const createUser = createAsyncThunkWithTypedError<UserDto, CreateUserDto>(
  'user/create',
  (user: CreateUserDto) => UserApi.userControllerCreate({ requestBody: user }),
);

export const updateUser = createAsyncThunkWithTypedError(
  'user/update',
  ({ user, email }: { email: string; user: UpdateUserDto }) =>
    UserApi.userControllerUpdateUser({ email, requestBody: user }),
);

export const fetchUsers = createAsyncThunk('user/fetch', async (options: FetchUsersOptions) => {
  return UserApi.userControllerFindAll(options);
});

export const fetchMentors = createAsyncThunk('users/fetchMentors', async () => {
  return UserApi.userControllerFindAll({ role: UserDto.role.MENTOR, skip: 0 });
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (email: string) =>
  UserApi.userControllerDeleteUser({ email }),
);

export const approveUserRegistration = createAsyncThunk(
  'users/approveRegistration',
  (email: string) =>
    UserApi.userControllerUpdateUser({ email, requestBody: { registrationConfirmed: true } }),
);
