import { LoadingButton } from '@mui/lab';
import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { FormInput } from '../../../../../components/form-fields/form-input';
import { FormSelect } from '../../../../../components/form-fields/form-select';
import { FormDialog } from '../../../../../components/modals/form-dialog';
import { useProjects } from '../../../../../hooks/redux-helper-hooks/useProjects';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../../hooks/useFormNotifications';
import { useNullDebounce } from '../../../../../hooks/useNullDebounce';
import { LessonsReview, UserDto } from '../../../../../openapi';
import {
  DoneStatus,
  LessonReviewStatus,
  mapScoreLabelToValue,
  mapScoreValueToLabel,
  NotDoneStatus,
  RetakeStatus,
  taskScores,
  taskScoresLabels,
  translateStatusLabelToCyrillic,
  translateStatusLabelToEng,
} from '../../../../../shared';
import { useAddLessonReviewMutation } from '../../../redux/class-spreadsheet-api';
import { styles } from '../styles';

const taskStatusLabels = [
  translateStatusLabelToCyrillic(DoneStatus),
  translateStatusLabelToCyrillic(NotDoneStatus),
  translateStatusLabelToCyrillic(RetakeStatus),
];

const defaultScoreValue = taskScores.good.value;

interface Props {
  student: UserDto | null;
  projectId: string;
  selectedReview: LessonsReview | null;
  onModalClose: () => void;
}

export const LessonReviewForm = ({
  student: studentProp,
  projectId,
  selectedReview: selectedReviewProp,
  onModalClose,
}: Props) => {
  const student = useNullDebounce(studentProp, 500);
  const selectedReview = useNullDebounce(selectedReviewProp, 500);
  const matchesDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [addLessonReview, mutationResult] = useAddLessonReviewMutation();
  useFormNotifications(
    buildNotificationArgs(mutationResult, {
      successMessage: "Рев'ю було успішно створено!",
      onSuccess: onModalClose,
    }),
  );
  const { isLoading } = mutationResult;
  const { normalizedProjects } = useProjects();
  const project = normalizedProjects[projectId];
  const studentProject = student?.projects.find((p) => p.projectId === projectId);

  // If no lessons to review modal should not be rendered at all in 'Add' mode
  const availableLessonsToReview = project.lessons.filter(
    (lesson) =>
      !studentProject?.lessonsReviews.map((review) => review.lessonNumber).includes(lesson.number),
  );

  const [lesson, setLesson] = useState(
    selectedReview?.lessonNumber ?? availableLessonsToReview[0].number,
  );
  const [taskStatus, setTaskStatus] = useState<LessonReviewStatus>(
    selectedReview?.status ?? NotDoneStatus,
  );
  const [score, setScore] = useState(selectedReview?.score ?? defaultScoreValue);

  const onLessonChange = (value: string) => {
    setLesson(Number(value));
  };

  const onTaskStatusLessonChange = (value: string) => {
    const newStatus = translateStatusLabelToEng(value);
    const retakeTaskStatusScore = 0;

    if (newStatus === DoneStatus && score === retakeTaskStatusScore) {
      setScore(defaultScoreValue);
    }
    setTaskStatus(newStatus);
  };

  const onTaskScoreChange = (value: string) => {
    setScore(mapScoreLabelToValue(value));
  };

  const onSubmit = async () => {
    if (!student) {
      return;
    }

    const lessonReview: LessonsReview = {
      lessonNumber: lesson,
      status: taskStatus,
      ...(taskStatus !== NotDoneStatus
        ? taskStatus === RetakeStatus
          ? { score: taskScores.retake.value }
          : { score }
        : {}),
      ...(selectedReview?.retakeCount && {
        retakeCount: selectedReview?.retakeCount,
      }),
      ...(taskStatus === RetakeStatus && {
        retakeCount: selectedReview ? (selectedReview?.retakeCount ?? 0) + 1 : 1,
      }),
    };

    addLessonReview({ lessonReview, projectId, userEmail: student.email });
  };

  const isAddMode = !selectedReview;
  const isModalOpen = !!studentProp;
  const lessonSelectOptions = availableLessonsToReview.map((l) => String(l.number));
  const showRetakeCount =
    selectedReview && selectedReview.retakeCount && selectedReview.retakeCount > 0;
  const canSetScore = taskStatus === DoneStatus;

  return (
    <FormDialog
      title={isAddMode ? "Додати рев'ю" : "Редагувати рев'ю"}
      isOpen={isModalOpen}
      onClose={onModalClose}
      dialogActions={
        <>
          <Button onClick={onModalClose} type={'button'}>
            Закрити
          </Button>
          <LoadingButton
            onClick={onSubmit}
            type={'submit'}
            loading={isLoading}
            disabled={taskStatus === NotDoneStatus}
          >
            Підтвердити
          </LoadingButton>
        </>
      }
    >
      <Box sx={() => styles.modalForm(matchesDesktop)}>
        <FormInput
          id={'student-name'}
          label={'Студент'}
          value={String(student?.fullName)}
          readOnly
        />
        {isAddMode ? (
          <FormSelect
            id={'lesson-number-select'}
            label={'Номер уроку'}
            value={String(lesson)}
            items={lessonSelectOptions}
            onChange={onLessonChange}
          />
        ) : (
          <FormInput
            id={'lesson-number-input'}
            label={'Номер уроку'}
            value={String(lesson)}
            readOnly
          />
        )}

        <FormSelect
          id={'task-status'}
          label={'Статус'}
          items={taskStatusLabels}
          value={translateStatusLabelToCyrillic(taskStatus)}
          onChange={onTaskStatusLessonChange}
        />
        {showRetakeCount && (
          <FormInput
            id={'retake-count'}
            label={'Кількість перездавань'}
            value={String(selectedReview?.retakeCount)}
            readOnly
          />
        )}
        {canSetScore && (
          <FormSelect
            id={'task-score'}
            label={'Оцінка'}
            items={taskScoresLabels}
            value={mapScoreValueToLabel(score)}
            onChange={onTaskScoreChange}
          />
        )}
      </Box>
    </FormDialog>
  );
};
