import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import {
  getLastItemFromArray,
  useGetCurrentStudyClassQuery,
  useGetStudyClassesQuery,
} from '../../shared';

type Props = {
  // '0' value means 'all study classes'
  studyClass: number;
  onStudyClassChange: (studyClassNumber: number) => void;
  currentStudyClassAsDefault: boolean;
};

export const StudyClassSelect = ({
  studyClass,
  onStudyClassChange,
  currentStudyClassAsDefault,
}: Props) => {
  const { data: studyClasses = [] } = useGetStudyClassesQuery();
  const { data: currentStudyClass } = useGetCurrentStudyClassQuery();
  const [isCurrentStudyClassLocked, setIsCurrentStudyClassLocked] = useState(
    currentStudyClass && currentStudyClassAsDefault,
  );

  const onSelectChange = (value: number) => {
    onStudyClassChange(value);
  };

  const onCheckboxChange = () => {
    if (!currentStudyClass) {
      return;
    }

    const newValue = !isCurrentStudyClassLocked;

    setIsCurrentStudyClassLocked(newValue);
    // set current or 'last'
    onSelectChange(
      newValue
        ? currentStudyClass.classNumber
        : getLastItemFromArray(studyClasses)?.classNumber ?? 0,
    );
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='user-role'>Поток</InputLabel>
        <Select
          labelId='student-studyClass'
          id='student-studyClass'
          label='Потік'
          value={studyClass}
          onChange={({ target }) => onSelectChange(Number(target.value))}
          fullWidth
          disabled={isCurrentStudyClassLocked}
        >
          <MenuItem value={0} defaultChecked>
            Всі
          </MenuItem>
          {[...studyClasses].reverse().map((studyClassItem) => (
            <MenuItem value={studyClassItem.classNumber} key={studyClassItem.classNumber}>
              {studyClassItem.classNumber} потік
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentStudyClass && (
        <FormControlLabel
          control={<Checkbox checked={isCurrentStudyClassLocked} onChange={onCheckboxChange} />}
          label='Поточний потік'
        />
      )}
    </Box>
  );
};
