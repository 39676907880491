import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import Chart from 'react-apexcharts';
import { useGetStudentsCountByStudyClassesQuery } from '../../redux/dashboard-api';
import { useChartOptions } from './use-chart-options';

export const StudentsByStudyClassChart = () => {
  const { data: studentsByStudyClasses = [], isSuccess } = useGetStudentsCountByStudyClassesQuery();
  const chartOptions = useChartOptions(
    studentsByStudyClasses.map(({ studyClassNumber }) => String(studyClassNumber)),
  );

  if (isSuccess && studentsByStudyClasses.length < 6) {
    return null;
  }

  const chartSeries = [
    {
      name: 'Кількість учнів',
      data: studentsByStudyClasses.slice(-6).map(({ studentsCount }) => studentsCount),
    },
  ];

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title='Статистика по потокам' />
      <CardContent>
        <Chart height={350} options={chartOptions} series={chartSeries} type='bar' width='100%' />
      </CardContent>
      <Divider />
    </Card>
  );
};
