/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTagDto } from '../models/CreateTagDto';
import type { UpdateTagDto } from '../models/UpdateTagDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TagApi {

    /**
     * @returns any 
     * @throws ApiError
     */
    public static tagControllerCreate({
requestBody,
}: {
requestBody: CreateTagDto,
}): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/tag`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static tagControllerFindAll(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/tag`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static tagControllerFindOne({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/tag/${id}`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static tagControllerUpdate({
id,
requestBody,
}: {
id: string,
requestBody: UpdateTagDto,
}): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/tag/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static tagControllerRemove({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/tag/${id}`,
        });
    }

}