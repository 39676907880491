import { Box, styled, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { DashboardSidebar } from './dashboard-sidebar';
import { DashboardTopNavbar } from './dashboard-top-navbar';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false,
  });
  useDocumentTitle();

  return (
    <>
      <DashboardTopNavbar onSidebarOpen={() => setIsSidebarOpen(true)} />
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </DashboardLayoutRoot>
      <DashboardSidebar onClose={() => setIsSidebarOpen(false)} open={isSidebarOpen} lgUp={lgUp} />
    </>
  );
};
