import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AddCriteriaFeature } from '../../features/criteria/add-criteria.feature';
import { CriteriaOverviewFeature } from '../../features/criteria/criteria-overview.feature';
import { EditCriteriaFeature } from '../../features/criteria/edit-criteria.feature';
import { RequireRole } from '../../features/navigation/guards/require-role';
import { useCriteria } from '../../hooks/redux-helper-hooks/useCriteria';
import { AdminRole, createRelativePath, NavigationRoutes } from '../../shared';

export const CriteriaPage = () => {
  useCriteria();

  return (
    <Routes>
      <Route
        path={createRelativePath(
          NavigationRoutes.criteria.route,
          NavigationRoutes.overviewCriteria.route,
        )}
        element={<CriteriaOverviewFeature />}
      />
      <Route element={<RequireRole roles={[AdminRole]} />}>
        <Route
          path={createRelativePath(
            NavigationRoutes.criteria.route,
            NavigationRoutes.addCriteria.route,
          )}
          element={<AddCriteriaFeature />}
        />
        <Route
          path={createRelativePath(
            NavigationRoutes.criteria.route,
            NavigationRoutes.editCriteria.route,
          )}
          element={<EditCriteriaFeature />}
        />
      </Route>
    </Routes>
  );
};
