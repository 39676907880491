import { Theme } from '@mui/material';
import { customScrollbar } from '../../../../shared';
import { drawerAvatarWidth, drawerPaperWidth, drawerWidth } from './shared';

export const styles = {
  drawerContainer: {
    overflow: 'hidden',
    display: 'flex',
  },
  drawer: (isMobile: boolean, open: boolean) => ({
    width: drawerWidth(isMobile),
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerPaperWidth(isMobile),
      ...(!isMobile && customScrollbar()),
    },
    ...(!open && isMobile && { zIndex: '-1' }),
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 200,
    position: 'relative',
  },
  drawerCloseBtn: {
    position: 'absolute',
    right: 0,
    color: '#fff',
    fontSize: 36,
    p: 4,
  },
  drawerContent: {
    position: 'relative',
    px: 4,
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  studentInfo: {
    marginTop: `-${drawerAvatarWidth / 2}px`,
  },
  studentAvatar: {
    width: 128,
    height: 128,
  },
  studentName: {
    fontSize: '1.925rem',
    fontWeight: '600',
  },
  pageContent: (theme: Theme, open: boolean, isMobile: boolean) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: `-${drawerWidth(isMobile)}`,
    ...(open &&
      !isMobile && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
  openCriteriaProgressBtn: {
    my: 1,
  },
  projectSettingsBtn: {
    my: 1,
  },
  modalForm: (matchesDesktop: boolean) => ({
    minWidth: 200,
    maxWidth: 550,
    width: matchesDesktop ? 550 : '100%',
  }),
};
