import * as React from 'react';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useNavigate } from 'react-router-dom';

type Props = {
  text: string;
  createPagePath?: string;
};

export const PageTitle = ({ text, createPagePath }: Props) => {
  const navigate = useNavigate();
  const onBtnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (!createPagePath) {
      return;
    }

    navigate(createPagePath);
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant='h4'>
          {text}
        </Typography>
        {createPagePath && (
          <Button
            component='a'
            startIcon={
              <SvgIcon fontSize='small'>
                <PlusIcon />
              </SvgIcon>
            }
            variant='contained'
            onClick={onBtnClick}
            href={createPagePath}
          >
            Додати
          </Button>
        )}
      </Box>
    </Box>
  );
};
