import { useLocation, useNavigate } from 'react-router-dom';

export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateEnhancer = (
    to: string,
    options?: { persistParams?: boolean; replace?: boolean },
  ) => {
    const { persistParams, replace } = options ?? { persistParams: true };

    const url = persistParams ? to + location.search : to;
    navigate(url, { ...(replace && { replace }) });
  };

  return {
    location,
    push: navigateEnhancer,
    navigate: navigateEnhancer,
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
  };
};
