import { Container } from '@mui/material';
import { CriteriaList } from '../../components/criteria/criteria-list';
import { PageTitle } from '../../components/layout/page-title';
import { Loader } from '../../components/loader/loader';
import { ConfirmDialog } from '../../components/modals/confirm-dialog/confirm-dialog';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { resetCriterionToDelete } from './redux/criteria-slice';
import { deleteCriteria } from './redux/thunks';

export const CriteriaOverviewFeature = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.criteria.isLoading);
  const selectedCriterionToDelete = useAppSelector((state) => state.criteria.criterionToDelete);

  const deleteRequirement = () => {
    if (!selectedCriterionToDelete) {
      return;
    }

    dispatch(deleteCriteria(selectedCriterionToDelete.id));
  };

  const onConfirmCancel = () => {
    dispatch(resetCriterionToDelete());
  };

  return (
    <div>
      <Container maxWidth={false}>
        <PageTitle text='Критерії' />
        <Loader isLoading={isLoading && !selectedCriterionToDelete} centered>
          <CriteriaList />
        </Loader>

        <ConfirmDialog
          onConfirm={deleteRequirement}
          text={confirmationText}
          isOpen={Boolean(selectedCriterionToDelete)}
          onClose={onConfirmCancel}
          isLoading={isLoading && !!selectedCriterionToDelete}
        />
      </Container>
    </div>
  );
};

const confirmationText = 'Ви впевнені, що хочете видалити цей критерій?';
