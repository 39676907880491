import { Box, Container, Typography } from '@mui/material';
import { CriteriaForm } from '../../components/criteria/criteria-form/criteria-form';

export const AddCriteriaFeature = () => {
  return (
    <Container maxWidth='lg'>
      <Typography sx={{ mb: 3 }} variant='h4'>
        Модерація
      </Typography>
      <Box sx={{ pt: 3 }}>
        <CriteriaForm />
      </Box>
    </Container>
  );
};
