import { FormControl, TextField } from '@mui/material';

type Props = {
  id: string;
  label: string;
  value: string;
  readOnly?: boolean;
};

export const FormInput = ({ readOnly = false, value, id, label }: Props) => {
  const readOnlyStyles = {
    readOnly: true,
    disabled: true,
  };

  return (
    <FormControl fullWidth sx={{ my: 3 }}>
      <TextField id={id} label={label} value={value} InputProps={readOnly ? readOnlyStyles : {}} />
    </FormControl>
  );
};
