import { Route, Routes } from 'react-router-dom';
import { DashboardLayout } from '../../components/dashboard/dashboard-layout';
import { AuthAdminPage } from '../../pages/auth/auth-admin.page';
import { ClassSpreadsheetPage } from '../../pages/class-spreadsheet/class-spreadsheet.page';
import { CriteriaPage } from '../../pages/criteria/criteria.page';
import { HomePage } from '../../pages/home/home.page';
import { NotFoundPage } from '../../pages/not-found/not-found.page';
import { ProfilePage } from '../../pages/profile/profile.page';
import { ProjectPage } from '../../pages/project/project.page';
import { ServerErrorPage } from '../../pages/server-error/server-error.page';
import { StudyClassPage } from '../../pages/study-class/study-class.page';
import { UserPage } from '../../pages/user/user.page';
import { AdminRole, MentorRole, NavigationRoutes } from '../../shared';
import { RequireAuth } from './guards/require-auth';
import { RequireNotStudentRole } from './guards/require-non-student-role';
import { RequireRole } from './guards/require-role';
import { RequireInitData } from './guards/require-init-data';
import { LoggedInRedirect } from './redirects/logged-in-redirect';

export const NavigationOutput = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<RequireInitData />}>
          <Route element={<RequireNotStudentRole />}>
            <Route element={<DashboardLayout />}>
              <Route path={NavigationRoutes.home.route} element={<HomePage />} />
              <Route path={NavigationRoutes.user.route + '/*'} element={<UserPage />} />
              <Route path={NavigationRoutes.criteria.route + '/*'} element={<CriteriaPage />} />
              <Route path={NavigationRoutes.profile.route} element={<ProfilePage />} />

              <Route
                path={NavigationRoutes.classSpreadsheet.route + '/*'}
                element={
                  <RequireRole roles={[MentorRole]}>
                    <ClassSpreadsheetPage />
                  </RequireRole>
                }
              />

              <Route element={<RequireRole roles={[AdminRole]} />}>
                <Route
                  path={NavigationRoutes.studyClass.list.route + '/*'}
                  element={<StudyClassPage />}
                />
                <Route path={NavigationRoutes.project.route + '/*'} element={<ProjectPage />} />
              </Route>
            </Route>

            <Route path={NavigationRoutes.notFound.route} element={<NotFoundPage />} />
            <Route path={NavigationRoutes.serverError.route} element={<ServerErrorPage />} />
          </Route>
        </Route>
      </Route>

      <Route
        path={NavigationRoutes.login.route}
        element={
          <LoggedInRedirect>
            <AuthAdminPage />
          </LoggedInRedirect>
        }
      />
      <Route path={NavigationRoutes.notFound.route} element={<NotFoundPage />} />
      <Route path={NavigationRoutes.serverError.route} element={<ServerErrorPage />} />
    </Routes>
  );
};
