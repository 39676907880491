import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { dismissError, resetFormSubmit } from '../../../features/user/redux/user-slice';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useHistory } from '../../../hooks/useHistory';
import { CreateUserDto, UpdateUserDto, UserDto } from '../../../openapi';
import {
  isEmptyString,
  isNotEmptyString,
  NavigationRoutes,
  useGetStudyClassesQuery,
  validateUserRole,
} from '../../../shared';
import { FormFooter } from '../../forms/form-footer';
import { AvailableStudyClassesSelect } from './available-study-classes-select';
import { ProjectsSelect } from './projects-select';

type Props = {
  onSubmit: (user: CreateUserDto | UpdateUserDto) => void;
  selectedUser?: UserDto;
};

export const UserForm = ({ onSubmit, selectedUser }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const mentors = useAppSelector((state) => state.user.mentors);
  const error = useAppSelector((state) => state.user.error);
  const isLoading = useAppSelector((state) => state.user.isLoading);
  const isFormSubmitted = useAppSelector((state) => state.user.isFormSubmitted);
  const { data: studyClasses = [] } = useGetStudyClassesQuery();
  const [createUserDtoValues, setCreateUserDtoValues] = useState<CreateUserDto>(
    initState(selectedUser),
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [mentorEmail, setMentorEmail] = useState(selectedUser?.mentorEmail ?? '');
  const [studyClass, setStudyClass] = useState(selectedUser?.studyClass?.classNumber ?? '');
  const [projects, setProjects] = useState<string[]>(
    selectedUser?.projects?.map((userProject) => userProject.projectId) ?? [],
  );
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [registrationConfirmed, setRegistrationConfirmed] = useState(
    selectedUser?.registrationConfirmed ?? false,
  );
  const [availableStudyClasses, setAvailableStudyClasses] = useState<string[]>(
    selectedUser?.availableStudyClasses
      ? selectedUser.availableStudyClasses.map((st) => st.classNumber.toString())
      : [],
  );

  useEffect(() => {
    if (!isFormSubmitted) return;

    if (history.location.pathname === NavigationRoutes.addUser.route) {
      setMentorEmail('');
      setStudyClass('');
      setProjects(['portfolio']);
      setCreateUserDtoValues(emptyState);
      setSuccessMessage('Користувач був успешно створений');
      dispatch(resetFormSubmit());

      setTimeout(() => {
        hideErrorAndSuccess();
      }, 5000);
    }

    if (history.location.pathname === NavigationRoutes.editUser.route) {
      setSuccessMessage('Користувач був успішно оновлен, перенаправлення через пару секунд!');
      setTimeout(() => {
        history.goBack();
      }, 1300);

      dispatch(resetFormSubmit());
    }
  }, [isFormSubmitted]);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      isEmptyString(
        createUserDtoValues.email,
        createUserDtoValues.role,
        createUserDtoValues.password,
        createUserDtoValues.fullName,
      )
    ) {
      return;
    }

    const user = {
      ...createUserDtoValues,
      ...(createUserDtoValues.role === UserDto.role.STUDENT &&
        isNotEmptyString(mentorEmail) && { mentorEmail }),
      ...(createUserDtoValues.role === UserDto.role.STUDENT && { studyClassNumber: studyClass }),
      ...(createUserDtoValues.role === UserDto.role.STUDENT && { projects }),
      ...(createUserDtoValues.role === UserDto.role.STUDENT && { registrationConfirmed }),
      ...(createUserDtoValues.role === UserDto.role.MENTOR && {
        availableStudyClasses: availableStudyClasses.map((stNumber) =>
          studyClasses.find((st) => st.classNumber === Number(stNumber)),
        ),
      }),
    } as CreateUserDto;

    onSubmit(selectedUser ? (user as UpdateUserDto) : user);
  };

  const onValueChange = <Key extends keyof CreateUserDto>(key: Key) => {
    return (value: CreateUserDto[Key]) =>
      setCreateUserDtoValues((prevState) => ({ ...prevState, [key]: value }));
  };

  const onRoleSelectChange = (value: string) => {
    if (!validateUserRole(value)) {
      return;
    }

    onValueChange('role')(value);
  };

  const onProjectSelectChange = (value: string | string[]) =>
    setProjects(typeof value === 'string' ? value.split(',') : value);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const onRegistrationConfirmedChange = () => {
    setRegistrationConfirmed(!registrationConfirmed);
  };

  const onAvailableStudyClassesChange = (value: string | string[]) => {
    setAvailableStudyClasses(typeof value === 'string' ? value.split(',') : value);
  };

  const onDismissError = () => {
    if (error) {
      dispatch(dismissError());
    }
  };

  const hideErrorAndSuccess = () => {
    if (error) {
      onDismissError();
    }

    if (!error && !successMessage) {
      setSuccessMessage(null);
    }
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Card>
        <CardHeader title={selectedUser ? 'Редагувати користувача' : 'Додати користувача'} />
        <Divider />
        <CardContent>
          <TextField
            label="Прізвище - ім'я"
            name='fullName'
            type='text'
            variant='outlined'
            margin='normal'
            value={createUserDtoValues.fullName}
            onChange={({ target }) => onValueChange('fullName')(target.value)}
            onFocus={onDismissError}
            required
            fullWidth
          />
          <TextField
            id='email'
            label='Email'
            margin='normal'
            type='email'
            variant='outlined'
            value={createUserDtoValues.email}
            onChange={({ target }) => onValueChange('email')(target.value)}
            onFocus={onDismissError}
            required
            fullWidth
          />
          <FormControl variant='outlined' margin='normal' fullWidth>
            <InputLabel htmlFor='password'>Пароль*</InputLabel>
            <OutlinedInput
              id='password'
              label='Пароль*'
              type={isPasswordVisible ? 'text' : 'password'}
              value={createUserDtoValues.password}
              onChange={({ target }) => onValueChange('password')(target.value)}
              onFocus={onDismissError}
              required
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <InputLabel id='user-role'>Роль</InputLabel>
            <Select
              labelId='user-role'
              id='user-role'
              label='Роль'
              value={createUserDtoValues.role}
              onChange={({ target }) => onRoleSelectChange(target.value)}
              fullWidth
              required
            >
              <MenuItem value={UserDto.role.STUDENT}>Учень</MenuItem>
              <MenuItem value={UserDto.role.MENTOR}>Ментор</MenuItem>
            </Select>
          </FormControl>
          {createUserDtoValues.role === UserDto.role.MENTOR && (
            <AvailableStudyClassesSelect
              studyClasses={studyClasses}
              value={availableStudyClasses}
              onChangeHandler={onAvailableStudyClassesChange}
            />
          )}
          {createUserDtoValues.role === UserDto.role.STUDENT && (
            <>
              <FormControl margin='normal' fullWidth>
                <InputLabel id='mentor'>Ментор</InputLabel>
                <Select
                  labelId='mentor'
                  id='mentor'
                  label='Ментор'
                  value={mentorEmail}
                  onChange={({ target }) => setMentorEmail(target.value)}
                  fullWidth
                >
                  {mentors.map((mentor) => (
                    <MenuItem value={mentor.email} key={mentor.email}>
                      {mentor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <InputLabel id='studyClass'>Потік</InputLabel>
                <Select
                  labelId='studyClass'
                  id='studyClass'
                  label='Потік'
                  value={studyClass}
                  onChange={({ target }) => setStudyClass(target.value)}
                  fullWidth
                  required
                >
                  {studyClasses.map((stClass) => (
                    <MenuItem value={stClass.classNumber} key={stClass.classNumber}>
                      Потік №{stClass.classNumber} -{' '}
                      {new Date(stClass.startDate).toLocaleDateString()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ProjectsSelect projectsIds={projects} onChangeHandler={onProjectSelectChange} />

              {selectedUser && selectedUser.registrationConfirmed === false && (
                <FormControlLabel
                  control={<Checkbox />}
                  label='Підтвердити реєстрацію'
                  checked={registrationConfirmed}
                  onChange={onRegistrationConfirmedChange}
                />
              )}
            </>
          )}
        </CardContent>

        <FormFooter error={error} isLoading={isLoading} successMessage={successMessage ?? ''} />
      </Card>
    </form>
  );
};

const emptyState: CreateUserDto = {
  email: '',
  role: UserDto.role.STUDENT,
  password: '',
  fullName: '',
};

function initState(user?: UserDto): CreateUserDto {
  return user
    ? { email: user.email, role: user.role, fullName: user.fullName, password: user.password }
    : emptyState;
}
