import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { CreateTagDto, Translation } from '../../../openapi';
import { LanguageSelect } from '../../form-fields/language-select';
import {
  EmptyTranslations,
  getFormMode,
  isEmptyString,
  isNotEmptyString,
  Language,
  renderTranslation,
  UkLang,
} from '../../../shared';
import { FormFooter } from '../../forms/form-footer';
import { useHistory } from '../../../hooks/useHistory';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { createProjectTag, updateProjectTag } from '../../../features/project/redux/thunks';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { resetFormSubmit } from '../../../features/project/redux/project-slice';

export const TagForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const selectedTagToUpdate = useAppSelector((state) => state.project.selectedProjectTagToEdit);
  const isLoading = useAppSelector((state) => state.project.isFormLoading);
  const isFormSubmitted = useAppSelector((state) => state.project.isFormSubmitted);
  const mode = selectedTagToUpdate ? 'update' : 'create';
  const [successMessage, setSuccessMessage] = useState('');
  const [translationMode, setTranslationMode] = React.useState(!!selectedTagToUpdate?.translations);
  const [language, setLanguage] = useState<Language>(UkLang);
  const [title, setTitle] = useState(selectedTagToUpdate?.title ?? '');
  const [translations, setTranslations] = useState<Translation>(
    selectedTagToUpdate?.translations ?? EmptyTranslations,
  );
  const { isEditFormMode, isCreateFormMode } = getFormMode(selectedTagToUpdate);

  useEffect(() => {
    if (!isFormSubmitted) {
      return;
    }

    if (translationMode && isEmptyString(translations.uk, translations.ru) && { translations }) {
      alert('Всі доступні мови перекладів повинні бути заповненими');
      return;
    }

    if (isCreateFormMode) {
      setTitle('');
      setTranslationMode(false);
      setTranslations(EmptyTranslations);
      setSuccessMessage('Тег був успешно створений');

      setTimeout(() => {
        hideErrorAndSuccess();
      }, 5000);
    }

    if (isEditFormMode) {
      setSuccessMessage('Тег був успішно оновлен, перенаправлення через пару секунд!');
      setTimeout(() => {
        history.goBack();
      }, 1300);
    }

    dispatch(resetFormSubmit());
  }, [isFormSubmitted]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const tag: CreateTagDto = {
      title,
      ...(isNotEmptyString(translations.uk, translations.ru) && { translations }),
    };

    if (isCreateFormMode) {
      dispatch(createProjectTag(tag));
    }

    if (isEditFormMode && selectedTagToUpdate) {
      dispatch(updateProjectTag({ id: selectedTagToUpdate.id, tag }));
    }
  };

  const onLanguageChange = (value: string) => {
    setLanguage(value as Language);
  };

  const onTranslationModeChange = () => {
    setTranslationMode(!translationMode);
    if (isNotEmptyString(translations.uk, translations.ru) && translations) {
      setTranslations(EmptyTranslations);
    }
  };

  const onTranslationChange = (value: string) => {
    setTranslations(
      language === UkLang ? { ...translations, uk: value } : { ...translations, ru: value },
    );
  };

  const hideErrorAndSuccess = () => {
    if (successMessage) {
      setSuccessMessage('');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <CardHeader title={mode === 'create' ? 'Додати тег' : 'Редагувати тег'} />
        <Divider />
        <CardContent>
          <TextField
            id='tag-title'
            label='Назва тега'
            margin='normal'
            type='text'
            variant='outlined'
            value={title}
            onChange={({ target }) => setTitle(target.value)}
            required
            fullWidth
          />
          <FormControlLabel
            control={<Checkbox checked={translationMode} onChange={onTranslationModeChange} />}
            label='Переклади'
          />
          {translationMode && (
            <>
              <LanguageSelect value={language} onChange={onLanguageChange} />

              <TextField
                id='tag-translation-uk'
                label='Переклад'
                margin='normal'
                type='text'
                variant='outlined'
                required
                fullWidth
                value={renderTranslation(language, translations)}
                onChange={({ target }) => onTranslationChange(target.value)}
              />
            </>
          )}

          <FormFooter isLoading={isLoading} successMessage={successMessage} />
        </CardContent>
      </Card>
    </form>
  );
};
