import { currentUserSelector } from '../../features/user/redux/user-api';
import { UserDto } from '../../openapi';
import { useAppSelector } from '../useAppSelector';

const { MENTOR, ADMIN } = UserDto.role;

export const useCurrentUser = () => {
  // RTK query subscription with 'skipToken' arg does not get updated value
  // So moved to useAppSelector to just get data, not fetch it.
  // currentUser must be defined, thanks to useRequiredDataLoader hook
  const currentUser = useAppSelector(currentUserSelector)!;
  const { role } = currentUser ?? {};

  const isAdminRole = role === ADMIN;
  const isMentorRole = role === MENTOR;

  return { currentUser, isAdminRole, isMentorRole };
};
