import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { MenuProps, renderValue } from '../../../shared';
import { StudyClassDto } from '../../../openapi';

type Props = {
  onChangeHandler: (value: string | string[]) => void;
  value: string[];
  studyClasses: StudyClassDto[];
};

export const AvailableStudyClassesSelect = ({ value, onChangeHandler, studyClasses }: Props) => {
  const onChange = (event: SelectChangeEvent<string[]>) => {
    onChangeHandler(event.target.value);
  };

  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='available-study-classes'>Доступні потоки</InputLabel>
      <Select
        labelId='available-study-classes'
        id='available-study-classes'
        label='Доступні потоки'
        value={value}
        onChange={onChange}
        fullWidth
        required
        multiple
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {[...studyClasses].reverse().map((stClass) => (
          <MenuItem value={stClass.classNumber} key={stClass.classNumber}>
            Потік №{stClass.classNumber} - {new Date(stClass.startDate).toLocaleDateString()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
