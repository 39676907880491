import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/app/app-slice';
import classSpreadsheetReducer from '../features/class-spreadsheet/redux/class-spreadsheet-slice';
import criteriaRedicer from '../features/criteria/redux/criteria-slice';
import projectReducer from '../features/project/redux/project-slice';
import studyClassReducer from '../pages/study-class/features/redux/study-class-slice';
import userReducer from '../features/user/redux/user-slice';
import dashboardReducer from '../features/dashboard/redux/dashboard-slice';
import { appApi } from './app-api';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  studyClass: studyClassReducer,
  criteria: criteriaRedicer,
  classSpreadsheet: classSpreadsheetReducer,
  project: projectReducer,
  dashboard: dashboardReducer,
  [appApi.reducerPath]: appApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appApi.middleware),
});
