import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

type RowProps = {
  title: string;
  value: string | number;
  isLoading: boolean;
};

export const FieldRow = ({ value, title, isLoading }: RowProps) => {
  const content = (
    <Stack direction={{ sm: 'row' }} alignItems={{ sm: 'center' }} py={2}>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: isLoading ? '100%' : 250,
          fontSize: '1.25rem',
          marginBottom: { sm: 0, xs: 2 },
        }}
      >
        {title}
      </Box>
      <Box>{value}</Box>
    </Stack>
  );

  return isLoading ? <Skeleton sx={{ maxWidth: '100%' }}>{content}</Skeleton> : content;
};
