import { Box, Card, CardContent, CardHeader, List } from '@mui/material';
import React, { useState } from 'react';
import { ConfirmDialog } from '../../../../components/modals/confirm-dialog/confirm-dialog';
import { useCurrentUser } from '../../../../hooks/redux-helper-hooks/useCurrentUser';
import { useLinkedUsers } from '../../../../hooks/redux-helper-hooks/useLinkedUsers';
import {
  buildNotificationArgs,
  useFormNotifications,
} from '../../../../hooks/useFormNotifications';
import { LinkedUserDto } from '../../../../openapi';
import { useMentors } from '../../hooks/use-mentors';
import { useDeleteLinkedAccountMutation } from '../../redux/profile-api';
import { AddLinkedAccountForm } from './add-linked-account-form/add-linked-account-form';
import { LinkedAccountItem } from './linked-account-item';

export const LinkedAccounts = () => {
  // Prefetch, used in AddLinkedAccountForm component
  useMentors();
  const { isAdminRole } = useCurrentUser();
  const { linkedUsers } = useLinkedUsers();
  const [deleteLinkedAccount, mutationResult] = useDeleteLinkedAccountMutation();
  useFormNotifications(
    buildNotificationArgs(mutationResult, {
      successMessage: 'Зв`язаний аккаунт успішно видалено!',
    }),
  );
  const [accountToDelete, setAccountToDelete] = useState<LinkedUserDto | null>(null);

  const onAccountDelete = (linkedUser: LinkedUserDto) => {
    setAccountToDelete(linkedUser);
  };

  const onDeleteConfirm = () => {
    if (!accountToDelete) {
      return;
    }

    deleteLinkedAccount({ linkedAccountId: accountToDelete.linkedAccountId })
      .unwrap()
      .finally(() => {
        setAccountToDelete(null);
      });
  };

  const onDeleteCancel = () => {
    setAccountToDelete(null);
  };

  return (
    <>
      <Card elevation={8}>
        <CardHeader
          title="Зв'язані аккаунти"
          subheader={"Ви можете переключатися між зв'язаними аккаунтами"}
          {...(isAdminRole && { action: <AddLinkedAccountForm /> })}
        />
        {linkedUsers.length ? (
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <List>
                {linkedUsers.map(({ linkedAccountId, user }) => (
                  <LinkedAccountItem
                    key={linkedAccountId}
                    user={user}
                    linkedAccountId={linkedAccountId}
                    onDelete={onAccountDelete}
                  />
                ))}
              </List>
            </Box>
          </CardContent>
        ) : null}
      </Card>
      <ConfirmDialog
        onConfirm={onDeleteConfirm}
        text={accountToDelete ? confirmationText(accountToDelete.user.fullName) : ''}
        isOpen={!!accountToDelete}
        onClose={onDeleteCancel}
        isLoading={mutationResult.isLoading}
      />
    </>
  );
};

const confirmationText = (fullName: string) =>
  `Ви впевнені, що хочете відв'язати аккаунт "${fullName}"?`;
