import React from 'react';
import {
  selectCriterionToDelete,
  selectCriterionToEdit,
} from '../../features/criteria/redux/criteria-slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useHistory } from '../../hooks/useHistory';
import { CriterionDto } from '../../openapi';
import { NavigationRoutes } from '../../shared';
import { EntityActions } from '../../widgets/entity-actions/entity-actions';

type Props = {
  criterion: CriterionDto;
};
export const CriteriaActions = ({ criterion }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onEdit = () => {
    dispatch(selectCriterionToEdit(criterion));
    history.push(NavigationRoutes.editCriteria.route);
  };

  const onDelete = () => {
    dispatch(selectCriterionToDelete(criterion));
  };

  return (
    <EntityActions
      onEdit={onEdit}
      onDelete={onDelete}
      editUrl={NavigationRoutes.editCriteria.route}
    />
  );
};
