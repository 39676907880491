import { Box } from '@mui/material';
import { styles } from './styles';

type Props = {
  open: boolean;
  isMobile: boolean;
  children: JSX.Element;
};

export const PageContent = ({ open, isMobile, children }: Props) => {
  return <Box sx={(theme) => styles.pageContent(theme, open, isMobile)}>{children}</Box>;
};
