import { createSlice } from '@reduxjs/toolkit';
import { State } from './types';

const initialState: State = {
  wasAnimated: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    disableAnimation: (state) => {
      state.wasAnimated = true;
    },
  },
});

export const { disableAnimation } = dashboardSlice.actions;

export default dashboardSlice.reducer;
