import React from 'react';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { AdminRole, UserRole } from '../../shared';

type Props = {
  onEdit: () => void;
  editUrl: string;
  onDelete: () => void;
  selectUrl?: string;
  onSelect?: () => void;
  requiredRoles?: UserRole[];
  additionalActions?: JSX.Element;
};

export const EntityActions = ({
  onEdit,
  onDelete,
  onSelect,
  requiredRoles = [AdminRole],
  additionalActions,
  selectUrl,
  editUrl,
}: Props) => {
  const { currentUser } = useCurrentUser();

  const onSelectClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (onSelect) {
      onSelect();
    }
  };

  const onEditClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onEdit();
  };

  return requiredRoles.includes(currentUser.role) ? (
    <Box sx={{ display: 'flex' }}>
      {onSelect && (
        <IconButton
          size='small'
          onClick={onSelectClick}
          component='a'
          href={selectUrl}
          title={'Деталі'}
        >
          <ArticleIcon />
        </IconButton>
      )}
      <IconButton
        size='small'
        onClick={onEditClick}
        component='a'
        href={editUrl}
        title={'Редагувати'}
      >
        <EditIcon />
      </IconButton>
      <IconButton size='small' onClick={onDelete} title={'Видалити'}>
        <DeleteIcon />
      </IconButton>
      {additionalActions}
    </Box>
  ) : (
    <Box>Дії недоступні</Box>
  );
};
