import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { LinkedUserDto, UserDto } from '../../../../openapi';
import { translateRole } from '../../../../shared';
import { styles } from './styles';

type Props = {
  user: UserDto;
  linkedAccountId: string;
  onDelete: (linkedUser: LinkedUserDto) => void;
};

export const LinkedAccountItem = ({ user, linkedAccountId, onDelete }: Props) => {
  const onDeleteBtnClick = () => {
    onDelete({ user, linkedAccountId });
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton edge='end' onClick={onDeleteBtnClick}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar sx={styles.avatar}>
        <Avatar alt={`${user.fullName} аватар`} src={user.avatarUrl ?? ''} />
      </ListItemAvatar>
      <ListItemText
        primary={`${user.fullName} | ${translateRole(user.role)}`}
        secondary={
          <Typography component='span' variant='body2' color='text.primary'>
            {user.email}
          </Typography>
        }
      />
    </ListItem>
  );
};
