import { Box, Container, Typography } from '@mui/material';

type Props = {
  children: JSX.Element;
  title: string;
};

export const FormLayout = ({ children, title }: Props) => {
  return (
    <Container maxWidth='lg'>
      <Typography sx={{ mb: 3 }} variant='h4'>
        {title}
      </Typography>
      <Box sx={{ pt: 3 }}>{children}</Box>
    </Container>
  );
};
