import { useEffect } from 'react';
import { getPageTypeFromUrl, isRouteModule, NavigationRoutes } from '../shared';
import { useHistory } from './useHistory';

export const useDocumentTitle = (overrideRouteValue?: string) => {
  const { location } = useHistory();

  const route = Object.values(NavigationRoutes).find((navRoute) => {
    if (isRouteModule(navRoute)) {
      return location.pathname.includes(navRoute.list.route);
    }

    return navRoute.route === location.pathname;
  });

  useEffect(() => {
    if (overrideRouteValue) {
      document.title = overrideRouteValue;
      return;
    }

    if (!route) {
      return;
    }

    const title = isRouteModule(route)
      ? route[getPageTypeFromUrl(location.pathname, route)].title
      : route.title;

    document.title = `${title} | Mentors-help`;
  }, [route, location.pathname]);
};
