import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from '../../../openapi';
import { getAuthData } from '../../../shared';
import {
  approveUserRegistration,
  createUser,
  deleteUser,
  fetchMentors,
  fetchUsers,
  updateUser,
} from './thunks';
import { State } from './types';

const initialState: State = {
  isLoading: false,
  error: undefined,
  shouldRefreshUsers: true,
  shouldRefreshMentors: true,
  isFormSubmitted: false,
  users: [],
  usersLoading: false,
  mentors: [],
  total: 0,
  selectedUserToEdit: null,
  selectedUserToDelete: null,
  deleteUserLoading: false,
  token: getAuthData()?.token ?? '',
  approveRegistrationState: { isLoading: false, userEmail: '' },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    dismissError: (state) => {
      state.error = undefined;
    },
    resetFormSubmit: (state) => {
      state.isFormSubmitted = false;
    },
    selectUserToEdit: (state, action: PayloadAction<UserDto>) => {
      state.selectedUserToEdit = action.payload;
    },
    selectUserToDelete: (state, action: PayloadAction<UserDto>) => {
      state.selectedUserToDelete = action.payload;
    },
    resetUserToDelete: (state) => {
      state.selectedUserToDelete = null;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    selectUserToApproveRegistration: (state, action: PayloadAction<string>) => {
      state.approveRegistrationState.userEmail = action.payload;
    },
    logout: (state) => {
      state.token = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action: PayloadAction<UserDto>) => {
      state.isLoading = false;
      state.shouldRefreshUsers = true;
      state.isFormSubmitted = true;

      if (action.payload.role === UserDto.role.MENTOR) {
        state.shouldRefreshMentors = true;
      }
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isFormSubmitted = true;
      state.shouldRefreshUsers = true;

      if (action.payload.role === UserDto.role.MENTOR) {
        state.shouldRefreshMentors = true;
      }
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchUsers.pending, (state) => {
      state.usersLoading = true;
      state.selectedUserToEdit = null;
    });

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload.users;
      state.total = action.payload.total;
      state.shouldRefreshUsers = false;
      state.usersLoading = false;
    });

    builder.addCase(fetchMentors.fulfilled, (state, action) => {
      state.mentors = action.payload.users.map((user) => ({
        email: user.email,
        name: user.fullName ?? '',
      }));
      state.shouldRefreshMentors = false;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.deleteUserLoading = true;
    });

    builder.addCase(deleteUser.fulfilled, (state) => {
      const deletedUser = state.selectedUserToDelete;

      state.deleteUserLoading = false;
      state.users = state.users.filter((user) => user.email !== deletedUser?.email ?? '');
      state.selectedUserToDelete = null;
    });

    builder.addCase(approveUserRegistration.pending, (state) => {
      state.approveRegistrationState.isLoading = true;
    });

    builder.addCase(approveUserRegistration.fulfilled, (state) => {
      state.approveRegistrationState.isLoading = false;
      state.users = state.users.map((user) =>
        user.email === state.approveRegistrationState.userEmail
          ? { ...user, registrationConfirmed: true }
          : user,
      );
      state.approveRegistrationState.userEmail = '';
    });
  },
});

export const {
  resetFormSubmit,
  dismissError,
  selectUserToEdit,
  selectUserToDelete,
  resetUserToDelete,
  setToken,
  selectUserToApproveRegistration,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
