/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectDto } from '../models/CreateProjectDto';
import type { ProjectDto } from '../models/ProjectDto';
import type { UpdateProjectDto } from '../models/UpdateProjectDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ProjectApi {

    /**
     * @returns any 
     * @throws ApiError
     */
    public static projectControllerCreate({
requestBody,
}: {
requestBody: CreateProjectDto,
}): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/project`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static projectControllerFindAll(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/project`,
        });
    }

    /**
     * @returns ProjectDto 
     * @throws ApiError
     */
    public static projectControllerGetUserProjects(): CancelablePromise<Array<ProjectDto>> {
        return __request({
            method: 'GET',
            path: `/api/project/user`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static projectControllerUpdate({
id,
requestBody,
}: {
id: string,
requestBody: UpdateProjectDto,
}): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/api/project/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static projectControllerRemove({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/project/${id}`,
        });
    }

}