import React from 'react';
import { Box } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const TabContent = ({ children, value, index, ...other }: Props) => {
  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
