import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectDto, TagDto } from '../../../openapi';
import { State } from './types';
import {
  createProject,
  createProjectTag,
  deleteProject,
  updateProject,
  fetchProjects,
  fetchProjectTags,
  updateProjectTag,
  deleteProjectTag,
} from './thunks';

const initialState: State = {
  projects: [],
  isProjectsLoading: false,
  shouldRefreshProjects: true,
  isFormLoading: false,
  isFormSubmitted: false,
  selectedProjectToDelete: null,
  selectedProjectToEdit: null,
  isDeleteLoading: false,
  isProjectTagsLoading: false,
  projectTags: [],
  selectedProjectTagToDelete: null,
  selectedProjectTagToEdit: null,
  shouldRefreshTags: true,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetFormSubmit: (state) => {
      state.isFormSubmitted = false;
    },
    selectProjectToDelete: (state, action: PayloadAction<ProjectDto | null>) => {
      state.selectedProjectToDelete = action.payload;
    },
    selectProjectToEdit: (state, action: PayloadAction<ProjectDto | null>) => {
      state.selectedProjectToEdit = action.payload;
    },
    selectProjectTagToDelete: (state, action: PayloadAction<TagDto | null>) => {
      state.selectedProjectTagToDelete = action.payload;
    },
    selectProjectTagToEdit: (state, action: PayloadAction<TagDto | null>) => {
      state.selectedProjectTagToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.isProjectsLoading = true;
    });
    builder.addCase(fetchProjects.rejected, (state) => {
      state.isProjectsLoading = false;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.isProjectsLoading = false;
      state.shouldRefreshProjects = false;
      state.selectedProjectToEdit = null;
      state.projects = action.payload;
    });

    builder.addCase(createProject.pending, (state) => {
      state.isFormLoading = true;
    });
    builder.addCase(createProject.rejected, (state) => {
      state.isFormLoading = false;
    });
    builder.addCase(createProject.fulfilled, (state) => {
      state.isFormLoading = false;
      state.shouldRefreshProjects = true;
      state.isFormSubmitted = true;
    });

    builder.addCase(updateProject.pending, (state) => {
      state.isFormLoading = true;
    });

    builder.addCase(updateProject.rejected, (state) => {
      state.isFormLoading = false;
    });

    builder.addCase(updateProject.fulfilled, (state) => {
      state.isFormLoading = false;
      state.isFormSubmitted = true;
      state.shouldRefreshProjects = true;
    });

    builder.addCase(deleteProject.pending, (state) => {
      state.isDeleteLoading = true;
    });
    builder.addCase(deleteProject.rejected, (state) => {
      state.isDeleteLoading = false;
    });
    builder.addCase(deleteProject.fulfilled, (state) => {
      const deletedProject = state.selectedProjectToDelete;

      state.isDeleteLoading = false;
      state.projects = state.projects.filter((project) => project.id !== deletedProject?.id ?? '');
      state.selectedProjectToDelete = null;
    });

    builder.addCase(fetchProjectTags.pending, (state) => {
      state.isProjectTagsLoading = true;
      state.selectedProjectTagToEdit = null;
    });
    builder.addCase(fetchProjectTags.rejected, (state) => {
      state.isProjectTagsLoading = false;
    });
    builder.addCase(fetchProjectTags.fulfilled, (state, action) => {
      state.isProjectTagsLoading = false;
      state.shouldRefreshTags = false;
      state.projectTags = action.payload;
    });

    builder.addCase(createProjectTag.pending, (state) => {
      state.isFormLoading = true;
    });
    builder.addCase(createProjectTag.rejected, (state) => {
      state.isFormLoading = false;
    });
    builder.addCase(createProjectTag.fulfilled, (state) => {
      state.isFormLoading = false;
      state.isFormSubmitted = true;
      state.shouldRefreshTags = true;
    });

    builder.addCase(updateProjectTag.pending, (state) => {
      state.isFormLoading = true;
    });
    builder.addCase(updateProjectTag.rejected, (state) => {
      state.isFormLoading = false;
    });
    builder.addCase(updateProjectTag.fulfilled, (state) => {
      state.isFormLoading = false;
      state.shouldRefreshTags = true;
      state.isFormSubmitted = true;
    });

    builder.addCase(deleteProjectTag.pending, (state) => {
      state.isDeleteLoading = true;
    });
    builder.addCase(deleteProjectTag.rejected, (state) => {
      state.isDeleteLoading = false;
    });
    builder.addCase(deleteProjectTag.fulfilled, (state) => {
      const deletedTag = state.selectedProjectTagToDelete;

      state.isDeleteLoading = false;
      state.projectTags = state.projectTags.filter((tag) => tag.id !== deletedTag?.id ?? '');
      state.selectedProjectTagToDelete = null;
    });
  },
});

export const {
  resetFormSubmit,
  selectProjectToDelete,
  selectProjectToEdit,
  selectProjectTagToDelete,
  selectProjectTagToEdit,
} = projectSlice.actions;

export default projectSlice.reducer;
