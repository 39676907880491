import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../openapi';
import { RootState } from '../store/types';

export const createAsyncThunkWithTypedError = function <Response, Argument>(
  thunkName: string,
  request: (requestArg: Argument) => Promise<Response>,
  shouldLoadData?: (state: RootState) => boolean,
) {
  return createAsyncThunk<Response, Argument, { rejectValue: ApiError; state: RootState }>(
    thunkName,
    async (argument: Argument, ThunkApi) => {
      return request(argument).catch((error) => {
        return ThunkApi.rejectWithValue({ ...error } as ApiError);
      });
    },
    {
      condition: (_, api) => {
        const state = api.getState();

        return shouldLoadData ? shouldLoadData(state) : true;
      },
    },
  );
};
