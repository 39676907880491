import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { CriterionDto } from '../../openapi';
import { CriteriaActions } from './criteria-actions';

type Props = {
  category: string;
  criteria: CriterionDto[];
};

export const CriteriaAccordion = ({ category, criteria }: Props) => {
  return (
    <Accordion sx={{ my: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Категорія - {category}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {criteria.length ? (
          <Paper sx={{ overflowX: 'auto' }}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Номер</TableCell>
                    <TableCell>Основний текст</TableCell>
                    <TableCell>Дії</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {criteria.map((criterion) => (
                    <TableRow hover key={criterion.id}>
                      <TableCell>
                        <Typography color='textPrimary' variant='body1'>
                          {criterion.number}
                        </Typography>
                      </TableCell>
                      <TableCell>{criterion.description.uk}</TableCell>
                      <TableCell>
                        <CriteriaActions criterion={criterion} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Paper>
        ) : (
          <p>Критерії за цією категорією не знайдені</p>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
