import React from 'react';
import { Box, Tabs as MuiTabs } from '@mui/material';

type Props = {
  activeTab: number;
  tabsHeader: JSX.Element | JSX.Element[];
  tabsContent: JSX.Element | JSX.Element[];
};

export const Tabs = ({ activeTab, tabsHeader, tabsContent }: Props) => {
  return (
    <Box>
      <MuiTabs value={activeTab} variant='scrollable' scrollButtons='auto'>
        {tabsHeader}
      </MuiTabs>
      {tabsContent}
    </Box>
  );
};
