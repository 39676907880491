import { Grid, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { FormValues } from './types';

type Props = {
  name: keyof FormValues;
  label: string;
  readonly?: boolean;
  required?: boolean;
  type?: string;
};

export const TextInput = ({ name, readonly = false, type = 'text', label, required }: Props) => {
  const { control } = useFormContext();
  const { field } = useController({ control, name });

  return (
    <Grid item xs={12} md={6}>
      <TextField
        {...field}
        label={label}
        required={required}
        disabled={readonly}
        type={type}
        fullWidth
      />
    </Grid>
  );
};
