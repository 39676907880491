// TODO: Group by modules
import { z } from 'zod';

export const NavigationRoutes = {
  home: { route: '/', title: 'Головна' },
  criteria: { route: '/criteria', title: 'Критерії' },
  addCriteria: { route: '/criteria/add', title: 'Додати критерій' },
  editCriteria: { route: '/criteria/edit', title: 'Редагувати критерій' },
  overviewCriteria: { route: '/criteria/overview', title: 'Критерії' },
  criteriaStudentsProgress: { route: '/criteria/progress', title: 'Прогрес студента' },
  login: { route: '/login', title: 'Вхід' },
  profile: { route: '/profile', title: 'Профіль' },
  user: { route: '/user', title: 'Користувачі' },
  addUser: { route: '/user/add', title: 'Додати користувача' },
  editUser: { route: '/user/edit', title: 'Редагувати користувача' },
  overviewUsers: { route: '/user/overview', title: 'Користувачі' },
  studyClass: createModule('study-class', {
    list: 'Потоки',
    show: 'Потік',
    edit: 'потік',
    create: 'потік',
    notFound: 'потік',
  }),
  requirement: { route: '/requirement', title: '' },
  addRequirement: { route: '/requirement/add', title: '' },
  editRequirement: { route: '/requirement/edit', title: '' },
  overviewRequirement: { route: '/requirement/overview', title: '' },
  classSpreadsheet: { route: '/class-spreadsheet', title: 'Журнал' },
  project: { route: '/project', title: 'Проєкти' },
  overviewProjects: { route: '/project/overview', title: 'Проєкти' },
  addProject: { route: '/project/add', title: 'Додати проєкт' },
  editProject: { route: '/project/edit', title: 'Редагувати проєкт' },
  overviewTags: { route: '/project/tags/overview', title: 'Теги' },
  addTag: { route: '/project/tags/add', title: 'Додати тег' },
  editTag: { route: '/project/tags/edit', title: 'Редагувати Тег' },
  serverError: { route: '/error', title: 'Щось пішло не так' },
  notFound: { route: '*', title: '404' },
};

const routeSchema = z.object({
  route: z.string(),
  title: z.string(),
});
const routeModuleSchema = z.object({
  list: routeSchema,
  show: routeSchema,
  create: routeSchema,
  edit: routeSchema,
  notFound: routeSchema,
});

type RouteModule = z.infer<typeof routeModuleSchema>;

export function createRelativePath(parent: string, child: string) {
  return child.split(parent)[1];
}

function createModule(
  module: string,
  titles: { list: string; show: string; create: string; edit: string; notFound: string },
): RouteModule {
  return {
    list: { route: `/${module}`, title: titles.list },
    show: { route: `/${module}/:id`, title: titles.show },
    create: { route: `/${module}/create`, title: `Додати ${titles.create}` },
    edit: { route: `/${module}/edit/:id`, title: `Редагувати ${titles.edit}` },
    notFound: { route: `/${module}/*`, title: `${titles.notFound} не знайдено` },
  };
}

export function getShowRouteUrl(module: RouteModule, id?: string) {
  return id ? module.show.route.replace(':id', id) : module.show.route;
}

export function getListRouteUrl(module: RouteModule) {
  return module.list.route;
}

export function getCreateRouteUrl(module: RouteModule) {
  return module.create.route;
}

export function getEditRouteUrl(module: RouteModule) {
  return module.edit.route;
}

export function createEditRouteUrl(module: RouteModule, id: string | number) {
  return module.edit.route.replace(':id', typeof id === 'string' ? id : String(id));
}

export function createShowRouteUrl(module: RouteModule, id: string | number) {
  return module.show.route.replace(':id', typeof id === 'string' ? id : String(id));
}

export function getPageTypeFromUrl(url: string, module: RouteModule): keyof RouteModule {
  if (url.includes('/create')) return 'create';
  if (url.includes('/edit/')) return 'edit';
  if (url.split(module.list.route)[1]) return 'show';
  if (url.includes(module.list.route)) return 'list';
  return 'notFound';
}

export function isRouteModule(value: unknown): value is RouteModule {
  return routeModuleSchema.safeParse(value).success;
}
