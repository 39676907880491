import { Navigate } from 'react-router-dom';
import { NavigationRoutes } from '../../../shared';
import { useAuth } from '../../../hooks/useAuth';

type Props = {
  children: JSX.Element;
};

export const LoggedInRedirect = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to={NavigationRoutes.home.route} replace />;
  }

  return children;
};
