import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ProjectDto } from '../../../../../openapi';

type Props = {
  project: ProjectDto;
  selectedLesson: number;
  onLessonChange: (lessonNumber: number) => void;
};

export const ProjectLessonsSelect = ({ project, selectedLesson, onLessonChange }: Props) => {
  const onChange = (event: SelectChangeEvent) => {
    onLessonChange(Number(event.target.value));
  };

  return (
    <FormControl fullWidth margin={'normal'}>
      <InputLabel id='project-lesson-select-label'>Номер уроку</InputLabel>
      <Select
        labelId='project-lesson-select-label'
        id='project-lesson-select'
        value={String(selectedLesson)}
        label='Номер уроку'
        onChange={onChange}
      >
        {(project?.lessons ?? []).map(({ number }) => (
          <MenuItem value={number} key={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
