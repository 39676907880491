import './spinner.scss';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

type Props = {
  centered?: boolean;
  color?: CircularProgressProps['color'];
  size?: Size;
};

export const Spinner = ({ centered, color = 'inherit', size = 'default' }: Props) => (
  <Box sx={centered ? { display: 'flex', justifyContent: 'center', margin: '0 auto' } : {}}>
    <CircularProgress color={color} size={mapSizeToNumber(size)} />
  </Box>
);

type Size = 'sm' | 'default';
function mapSizeToNumber(size: Size) {
  switch (size) {
    case 'default':
      return 36;
    case 'sm':
      return 24;
  }
}
