import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  LinearProgress,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { sanitize } from 'dompurify';
import React from 'react';
import { CriteriaProgressItemDto, CriterionDto } from '../../../../../../openapi';
import { styles } from './styles';

type Props = {
  criteria: (CriterionDto & { status: CriteriaProgressItemDto.status })[];
  category: string;
  onStatusChange: (criterionId: string, status: CriteriaProgressItemDto.status) => void;
  loadingCriterionId: string | null;
};

export const CriteriaAccordion = ({
  category,
  criteria,
  onStatusChange,
  loadingCriterionId,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Accordion sx={styles.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Категорія - {category}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {criteria.map((criterion) => {
          const isLoading = loadingCriterionId === criterion.id;
          const isDisabled = !!loadingCriterionId;
          return (
            <Box key={criterion.id} sx={styles.criterion}>
              <Stack direction={'row'} spacing={1}>
                <Typography>
                  <b>№{criterion.number}. </b>
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{ __html: sanitize(criterion.description.uk) }}
                />
              </Stack>
              <Divider />
              <Stack sx={styles.statusBar(isMobile)}>
                <Typography>
                  Статус - <b>{getStatusText(criterion.status)}</b>
                </Typography>
                <LoadingButton
                  variant={'text'}
                  onClick={() => onStatusChange(criterion.id, getReversedStatus(criterion.status))}
                  disabled={isDisabled}
                >
                  Змінити статус на "{getStatusText(criterion.status, true)}"
                </LoadingButton>
              </Stack>
              {isLoading && <LinearProgress sx={styles.linearProgress} />}
              <Divider sx={styles.darkDivider} />
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

function getStatusText(status: CriteriaProgressItemDto.status, reverse?: boolean) {
  if (reverse) {
    return status === CriteriaProgressItemDto.status.DONE ? 'не виконано' : 'виконано';
  }
  return status === CriteriaProgressItemDto.status.DONE ? 'виконано' : 'не виконано';
}

function getReversedStatus(status: CriteriaProgressItemDto.status) {
  return status === CriteriaProgressItemDto.status.DONE
    ? CriteriaProgressItemDto.status.TODO
    : CriteriaProgressItemDto.status.DONE;
}
