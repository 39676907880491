import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

type Props = {
  value: boolean;
  onValueChange: (value: boolean) => void;
};

export const ApproveRegistrationFilter = ({ value, onValueChange }: Props) => {
  const onCheckboxChange = () => {
    onValueChange(!value);
  };

  return (
    <FormControlLabel
      control={<Checkbox />}
      label='Тільки очікуючі підтвердження'
      value={value}
      onChange={onCheckboxChange}
    />
  );
};
