import { useGetProjectsQuery } from '../../features/project/redux/project-api';
import { normalizeArray } from '../../shared';
import { ProjectDto } from '../../openapi';

export const useProjects = () => {
  const { data: projects = [] } = useGetProjectsQuery();

  const normalizedProjects = normalizeArray<ProjectDto>(projects, 'id');

  return { projects, normalizedProjects };
};
