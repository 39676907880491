import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
  Box,
  Divider,
  Drawer,
  Fade,
  IconButton,
  LinearProgress,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StringAvatar } from '../../../../components/avatar/string-avatar';
import { useNullDebounce } from '../../../../hooks/useNullDebounce';
import { UserDto } from '../../../../openapi';
import drawerHeaderBg from '../../assets/drawer-header-bg.jpg';
import { getStudentTelegram } from '../../helpers';
import { LessonReviews } from './drawer-content/lesson-review/lesson-reviews';
import { StudentProgressByProject } from './drawer-content/student-progress-by-project/student-progress-by-project';
import { StudentProjectsSelect } from './drawer-content/student-projects-select';
import { PageContent } from './page-content';
import { styles } from './styles';

type Props = {
  open: boolean;
  toggleDrawer: () => void;
  selectedStudent: UserDto | null;
  isLoading?: boolean;
  children: JSX.Element;
};

export const StudentDrawer = ({
  toggleDrawer,
  open,
  children,
  isLoading,
  selectedStudent: selectedStudentProp,
}: Props) => {
  const selectedStudent = useNullDebounce(selectedStudentProp, 500);
  const selectedStudentChanged = useMemo(() => selectedStudent?.email, [selectedStudent?.email]);
  const telegramUsername = getStudentTelegram(selectedStudent);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [selectedProjectId, setSelectedProjectId] = useState('');

  // Reset selected project on new student select
  useEffect(() => {
    if (selectedProjectId) {
      setSelectedProjectId('');
    }
  }, [selectedStudentChanged]);

  const onProjectChange = useCallback((projectId: string) => {
    setSelectedProjectId(projectId);
  }, []);

  return (
    <>
      <Box sx={styles.drawerContainer}>
        <PageContent open={open} isMobile={isMobile}>
          {children}
        </PageContent>
        <Drawer
          sx={() => styles.drawer(isMobile, open)}
          variant='persistent'
          anchor='right'
          open={open}
        >
          <Box sx={styles.drawerHeader}>
            <img src={drawerHeaderBg} height={'100%'} width={'100%'} alt={'Фон'} />
            <IconButton onClick={toggleDrawer} sx={styles.drawerCloseBtn}>
              <CloseIcon fontSize={'inherit'} />
            </IconButton>
          </Box>

          <Box sx={styles.drawerContent}>
            {isLoading && <LinearProgress sx={styles.linearProgress} />}
            <Box sx={styles.studentInfo}>
              <StringAvatar
                value={selectedStudent?.fullName ?? ''}
                sx={styles.studentAvatar}
                fontSize={'4rem'}
              />

              {selectedStudent && (
                <>
                  <Typography sx={styles.studentName}>{selectedStudent.fullName}</Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ py: 2 }}>
                    <EmailIcon color={'inherit'} />
                    <Typography>{selectedStudent.email}</Typography>
                  </Stack>

                  {telegramUsername && (
                    <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ py: 2 }}>
                      <TelegramIcon color={'inherit'} />
                      <Typography>{telegramUsername}</Typography>
                    </Stack>
                  )}

                  <StudentProjectsSelect
                    student={selectedStudent}
                    selectedProject={selectedProjectId}
                    onProjectChange={onProjectChange}
                  />

                  <Divider />

                  <Fade in={!!selectedProjectId} mountOnEnter unmountOnExit timeout={200}>
                    <div>
                      <StudentProgressByProject
                        student={selectedStudent}
                        projectId={selectedProjectId}
                      />
                      <Divider />
                      <LessonReviews student={selectedStudent} projectId={selectedProjectId} />
                    </div>
                  </Fade>
                </>
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};
