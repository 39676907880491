import React from 'react';
import { Box, Button, ListItem } from '@mui/material';
import { useHistory } from '../../../hooks/useHistory';
import { SidebarNavItem } from './nav-ltems';

type Props = {
  item: Pick<SidebarNavItem, 'href' | 'icon' | 'title'>;
};

export const NavItem = ({ item }: Props) => {
  const { href, icon, title } = item;
  const history = useHistory();
  const active =
    href === '/' ? history.location.pathname === href : history.location.pathname.includes(href);

  const navigate = () => {
    history.push(href, { persistParams: false });
  };

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        component='a'
        startIcon={icon}
        disableRipple
        onClick={navigate}
        sx={{
          backgroundColor: active ? 'rgba(255,255,255, 0.08)' : '',
          borderRadius: 1,
          color: active ? 'secondary.main' : 'neutral.300',
          fontWeight: active ? 'fontWeightBold' : 'fontWeightRegular',
          fontSize: '1rem',
          justifyContent: 'flex-start',
          px: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  );
};
