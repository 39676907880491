import {
  AddIcon,
  ChartBarIcon,
  HomeIcon,
  ListIcon,
  ProjectIcon,
  StarIcon,
  TableIcon,
  TagIcon,
  TaskIcon,
  UserIcon,
  UsersIcon,
} from '../../../icons';
import { UserDto } from '../../../openapi';
import { AdminRole, MentorRole, NavigationRoutes } from '../../../shared';

const publicRoute = [AdminRole, MentorRole];
const adminRoute = [AdminRole];
const mentorRoute = [MentorRole];

export const items: SidebarNavItem[] = [
  {
    href: NavigationRoutes.home.route,
    icon: <HomeIcon fontSize='small' />,
    title: 'Головна',
    requiredRoles: publicRoute,
  },
  {
    href: NavigationRoutes.profile.route,
    icon: <UserIcon fontSize='small' />,
    title: 'Профіль',
    requiredRoles: publicRoute,
  },
  {
    href: NavigationRoutes.user.route,
    icon: <UsersIcon fontSize='small' />,
    title: 'Користувачі',
    requiredRoles: adminRoute,
    children: [
      {
        href: NavigationRoutes.overviewUsers.route,
        title: 'Перелік',
        icon: <ListIcon fontSize='small' />,
        requiredRoles: adminRoute,
      },
      {
        href: NavigationRoutes.addUser.route,
        title: 'Додати',
        icon: <AddIcon fontSize='small' />,
        requiredRoles: adminRoute,
      },
    ],
  },
  {
    href: NavigationRoutes.criteria.route,
    icon: <TaskIcon fontSize='small' />,
    title: 'Критерії',
    requiredRoles: publicRoute,
    children: [
      {
        href: NavigationRoutes.overviewCriteria.route,
        title: 'Перелік',
        icon: <ListIcon fontSize='small' />,
        requiredRoles: publicRoute,
      },
      {
        href: NavigationRoutes.addCriteria.route,
        title: 'Додати',
        icon: <AddIcon fontSize='small' />,
        requiredRoles: adminRoute,
      },
      {
        href: NavigationRoutes.criteriaStudentsProgress.route,
        title: 'Прогрес',
        icon: <ChartBarIcon fontSize='small' />,
        requiredRoles: mentorRoute,
      },
    ],
  },
  {
    href: NavigationRoutes.project.route,
    icon: <ProjectIcon fontSize='small' />,
    title: 'Проєкти',
    requiredRoles: adminRoute,
    children: [
      {
        href: NavigationRoutes.overviewProjects.route,
        icon: <ListIcon fontSize='small' />,
        title: 'Перелік',
        requiredRoles: adminRoute,
      },
      {
        href: NavigationRoutes.addProject.route,
        icon: <AddIcon fontSize='small' />,
        title: 'Додати',
        requiredRoles: adminRoute,
      },
      {
        href: NavigationRoutes.overviewTags.route,
        icon: <TagIcon fontSize='small' />,
        title: 'Теги',
        requiredRoles: adminRoute,
      },
      {
        href: NavigationRoutes.addTag.route,
        icon: <AddIcon fontSize='small' />,
        title: 'Додати тег',
        requiredRoles: adminRoute,
      },
    ],
  },
  {
    href: NavigationRoutes.studyClass.list.route,
    icon: <StarIcon fontSize='small' />,
    title: 'Потоки',
    requiredRoles: adminRoute,
  },
  {
    href: NavigationRoutes.classSpreadsheet.route,
    icon: <TableIcon fontSize='small' />,
    title: 'Журнал',
    requiredRoles: mentorRoute,
  },
];

export type SidebarNavItem = {
  href: string;
  icon?: JSX.Element;
  title: string;
  children?: SidebarNavItem[];
  requiredRoles: UserDto.role[];
};
