import { ReactElement } from 'react';
import { Box, Fade } from '@mui/material';
import { useAppSelector } from '../../hooks/useAppSelector';
import { LinearProgressWithLabel } from '../progress/progress-with-label';

import './preloader.scss';

export const Preloader = (): ReactElement | null => {
  const loadingProgress = useAppSelector((state) => state.app.initLoadingProgress);
  const status = useAppSelector((state) => state.app.requiredDataStatus);

  const progress = Number(Math.round(loadingProgress).toFixed());

  return (
    <Fade in={status === 'loading'} unmountOnExit>
      <div className='init-preloader'>
        <div className='init-preloader__content'>
          <Box sx={{ textAlign: 'center' }}>Завантаження даних</Box>

          <LinearProgressWithLabel value={progress} />
        </div>
      </div>
    </Fade>
  );
};
