import { Spinner } from '../spinner/Spinner';

type Props = {
  isLoading: boolean;
  children?: JSX.Element;
  spinner?: JSX.Element;
  centered?: boolean;
};

export const Loader = ({
  centered,
  spinner = <Spinner centered={centered ?? false} />,
  isLoading,
  children = <div></div>,
}: Props) => {
  return isLoading ? spinner : children;
};
