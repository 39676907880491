import { Box, Card, CardContent } from '@mui/material';

type Props = {
  children: JSX.Element;
};
export const PageToolbar = ({ children }: Props) => {
  return (
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};
