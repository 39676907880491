import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useLinkedUsers } from '../../hooks/redux-helper-hooks/useLinkedUsers';
import { UsePopoverProps } from '../../hooks/use-popover';
import { LinkedUserDto } from '../../openapi';

type Props = UsePopoverProps & {
  onAccountClick: (linkedUser: LinkedUserDto) => void;
};

export const LinkedAccountsPopover = ({ anchorEl, onClose, open, onAccountClick }: Props) => {
  const { linkedUsers } = useLinkedUsers();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant='overline'>Зв'язані аккаунти</Typography>
      </Box>
      <Divider />

      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1,
          },
        }}
      >
        {linkedUsers.map((linkedUser) => (
          <MenuItem key={linkedUser.user.id} onClick={() => onAccountClick(linkedUser)}>
            {linkedUser.user.fullName}
          </MenuItem>
        ))}
        <MenuItem onClick={onClose}>скасувати</MenuItem>
      </MenuList>
    </Popover>
  );
};
