import { Box } from '@mui/material';
import { DashboardFeature } from '../../features/dashboard/dashboard.feature';

export const HomePage = () => {
  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
      }}
    >
      <DashboardFeature />
    </Box>
  );
};
