import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilesUpload, FileUpload, Widget } from '@uploadcare/react-widget';
import { useCriteria } from '../../hooks/redux-helper-hooks/useCriteria';
import {
  CreateProjectDto,
  DesignAssetsEntity,
  ProjectDto,
  ProjectLessonDto,
  TagDto,
} from '../../openapi';
import {
  EmptyTranslations,
  envs,
  getFormMode,
  getLastItemFromArray,
  isEmptyString,
  Language,
  renderTranslation,
  RuLang,
  UkLang,
} from '../../shared';
import { FormFooter } from '../forms/form-footer';
import { Tabs } from '../tabs/tabs';
import { TabHeader } from '../tabs/tab-header';
import { TabContent } from '../tabs/tab-content';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useHistory } from '../../hooks/useHistory';
import { resetFormSubmit, selectProjectToEdit } from '../../features/project/redux/project-slice';
import { LanguageSelect } from '../form-fields/language-select';
import { useTags } from '../../hooks/redux-helper-hooks/useTags';
import { createProject, updateProject } from '../../features/project/redux/thunks';
import { TemplateTypeSelect } from './template-type-select';
import { TagsSelect } from './tags/tags-select';
import { ProjectLessonForm } from './project-lesson/project-lesson-form';
import { DesignPreviewFormField } from './design-preview-form-field';

import './project-form.scss';

export const ProjectForm = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { tags: availableTags } = useTags();
  // criteria prefetch
  useCriteria();
  const selectedProject = useAppSelector((state) => state.project.selectedProjectToEdit);
  const isCreateLoading = useAppSelector((state) => state.project.isFormLoading);
  const isFormSubmitted = useAppSelector((state) => state.project.isFormSubmitted);
  const [language, setLanguage] = useState<Language>(UkLang);
  const [activeTab, setActiveTab] = React.useState(0);
  const [title, setTitle] = useState(selectedProject?.title ?? '');
  const [color, setColor] = useState(selectedProject?.color ?? '');
  const [name, setName] = useState(selectedProject?.name ?? EmptyTranslations);
  const [designPreviewImagesUrls, setDesignPreviewImagesUrls] = useState<string[]>(
    selectedProject?.designPreviewImagesUrls ?? [],
  );
  const [designAssets, setDesignAssets] = useState<DesignAssetsEntity[]>(
    selectedProject?.designAssets ?? [],
  );
  const [accessType] = useState<ProjectDto.accessType>(
    selectedProject?.accessType ?? ProjectDto.accessType.FREE,
  );
  const [difficultyLevel, setDifficultyLevel] = useState(selectedProject?.difficultyLevel ?? 1);
  const [tags, setTags] = useState<TagDto[]>(selectedProject?.tags ?? []);
  const [description, setDescription] = useState(selectedProject?.description ?? EmptyTranslations);
  const [assetsProvided, setAssetsProvided] = useState(
    selectedProject?.assetsProvided ?? EmptyTranslations,
  );
  const [projectAssets, setProjectAssets] = useState(selectedProject?.projectAssets ?? '');
  const [instructions, setInstructions] = useState(
    selectedProject?.instructions ?? EmptyTranslations,
  );
  const [projectImageUrl, setProjectImageUrl] = useState(selectedProject?.projectImageUrl ?? '');
  const [lessons, setLessons] = useState<ProjectLessonDto[]>(selectedProject?.lessons ?? []);
  const [successMessage, setSuccessMessage] = useState('');
  const { isEditFormMode, isCreateFormMode } = getFormMode(selectedProject);

  useEffect(() => {
    if (!isFormSubmitted) return;

    if (isCreateFormMode) {
      setSuccessMessage('Проєкт був успішно створений');
      setTitle('');
      setProjectImageUrl('');
      setLessons([]);

      setTimeout(() => {
        hideSuccessMessage();
      }, 5000);
    }

    if (isEditFormMode) {
      setSuccessMessage('Проєкт був успішно оновлен, перенаправлення через пару секунд!');
      setTimeout(() => {
        history.goBack();
      }, 1300);

      dispatch(selectProjectToEdit(null));
    }

    dispatch(resetFormSubmit());
  }, [isFormSubmitted]);

  const onSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = validateLessonsEmptyValues(lessons);

    if (!isValid) {
      alert("Треба заповнити всі обов'язкові поля в формах уроків на обох мовах!");
      return;
    }

    const project: CreateProjectDto = {
      title,
      name,
      description,
      instructions,
      projectImageUrl,
      tags,
      accessType,
      designPreviewImagesUrls,
      projectAssets,
      difficultyLevel,
      designAssets,
      assetsProvided,
      lessons,
      color,
    };

    if (isCreateFormMode) {
      dispatch(createProject(project));
    }
    if (isEditFormMode && selectedProject) {
      dispatch(updateProject({ id: selectedProject.id, project }));
    }
  };

  const onLanguageChange = (value: string) => {
    setLanguage(value as Language);
  };

  const onNameChange = (value: string) => {
    setName(language === UkLang ? { ...name, uk: value } : { ...name, ru: value });
  };
  const onColorChange = (value: string) => {
    setColor(value);
  };

  const onDesignPreviewChange = useCallback((updatedState: string[]) => {
    setDesignPreviewImagesUrls(updatedState);
  }, []);

  const onDesignAssetAdd = () => {
    setDesignAssets([
      ...designAssets,
      { description: EmptyTranslations, link: '', type: DesignAssetsEntity.type.FIGMA },
    ]);
  };

  const onAssetTypeChange = (value: DesignAssetsEntity.type, asset: DesignAssetsEntity) => {
    setDesignAssets(
      designAssets.map((designAsset) =>
        designAsset === asset ? { ...designAsset, type: value } : designAsset,
      ),
    );
  };

  const onAssetLinkChange = (value: string, asset: DesignAssetsEntity) => {
    setDesignAssets(
      designAssets.map((designAsset) =>
        designAsset.link === asset.link ? { ...designAsset, link: value } : designAsset,
      ),
    );
  };

  const onAssetDescriptionChange = (value: string, asset: DesignAssetsEntity) => {
    const updatedAsset = {
      ...asset,
      description:
        language === UkLang
          ? { ...asset.description, uk: value }
          : { ...asset.description, ru: value },
    };
    setDesignAssets(
      designAssets.map((designAsset) => (designAsset === asset ? updatedAsset : designAsset)),
    );
  };

  const onDesignAssetRemove = (asset: DesignAssetsEntity) => {
    setDesignAssets(designAssets.filter((item) => item !== asset));
  };

  const onTagsChange = (value: string[]) => {
    const updatedTags = availableTags.filter((tag) => value.includes(tag.title));

    setTags(updatedTags);
  };

  const onDescriptionChange = (value: string) => {
    setDescription(
      language === UkLang ? { ...description, uk: value } : { ...description, ru: value },
    );
  };

  const onInstructionsChange = (value: string) => {
    setInstructions(
      language === UkLang ? { ...instructions, uk: value } : { ...instructions, ru: value },
    );
  };

  const onAssetsProvidedChange = (value: string) => {
    setAssetsProvided(
      language === UkLang ? { ...assetsProvided, uk: value } : { ...assetsProvided, ru: value },
    );
  };

  const onProjectImageFileChange = async (fileUpload: FileUpload | FilesUpload | null) => {
    if (!fileUpload) {
      setProjectImageUrl('');
      return;
    }
    const result = await fileUpload?.promise();

    setProjectImageUrl(result?.cdnUrl ?? '');
  };

  const onAddLessonBtnClick = () => {
    const updatedLessons = [...lessons];
    updatedLessons.push({
      number: (getLastItemFromArray(updatedLessons)?.number ?? 0) + 1,
      criteria: [],
      markupDescription: EmptyTranslations,
      taskDescription: EmptyTranslations,
    });

    setLessons(updatedLessons);
  };

  const onRemoveLessonBtnClick = () => {
    if (!lessons.length) {
      return;
    }

    setLessons(lessons.filter((lesson) => lesson.number !== getLastItemFromArray(lessons).number));
  };

  const onLessonUpdate = (updatedLesson: ProjectLessonDto) => {
    setLessons(
      lessons.map((lesson) => (lesson.number === updatedLesson.number ? updatedLesson : lesson)),
    );
  };

  const hideSuccessMessage = () => {
    setSuccessMessage('');
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Card>
        <CardHeader title={'Додати проєкт'} />
        <Divider />
        <CardContent>
          <LanguageSelect value={language} onChange={onLanguageChange} />
          <TextField
            id='project-title'
            label='Оригінальна назва проєкту англійською'
            margin='normal'
            type='text'
            variant='outlined'
            value={title}
            onChange={({ target }) => setTitle(target.value)}
            required
            fullWidth
          />

          <Box sx={{ mb: 3 }}>
            <TextField
              id='project-image-url'
              label='Посилання на зображення проєкту'
              margin='normal'
              type='text'
              variant='outlined'
              value={projectImageUrl}
              onChange={({ target }) => setProjectImageUrl(target.value)}
              required
              fullWidth
              disabled
            />
            <Widget
              publicKey={envs.uploadcarePublicKey}
              tabs='file'
              locale={'uk'}
              onFileSelect={(fileUpload) => onProjectImageFileChange(fileUpload)}
              value={projectImageUrl}
              clearable
              imagesOnly
              previewStep
            />
          </Box>

          <TextField
            id='project-title'
            label='Переклади назви проєкту'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, name)}
            onChange={({ target }) => onNameChange(target.value)}
            required
            fullWidth
          />

          <TextField
            id='project-color'
            label='Колір проєкту'
            margin='normal'
            type='text'
            variant='outlined'
            value={color}
            onChange={({ target }) => onColorChange(target.value)}
            required
            fullWidth
          />

          <TextField
            id='project-description'
            label='Опис проєкту'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, description)}
            onChange={({ target }) => onDescriptionChange(target.value)}
            required
            fullWidth
          />
          <TextField
            id='project-instructions'
            label='Інструкції до проєкту'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, instructions)}
            onChange={({ target }) => onInstructionsChange(target.value)}
            required
            fullWidth
          />

          <TextField
            id='project-assets-provided'
            label='Перелік доступних матеріалів (HTML розмітка)'
            margin='normal'
            type='text'
            variant='outlined'
            value={renderTranslation(language, assetsProvided)}
            onChange={({ target }) => onAssetsProvidedChange(target.value)}
            required
            fullWidth
            minRows={4}
            multiline
          />

          <DesignPreviewFormField
            designPreviewImagesUrls={designPreviewImagesUrls}
            onChange={onDesignPreviewChange}
          />

          <Box sx={{ my: 1 }}>
            <Typography sx={{ my: 1, fontSize: 20 }}>Посилання на макети</Typography>
            <Button onClick={onDesignAssetAdd} variant={'contained'} sx={{ mb: 1 }}>
              Додати
            </Button>
            {designAssets.map((asset, idx) => (
              <Box key={idx}>
                <TemplateTypeSelect
                  value={asset.type}
                  onChange={(newType) => onAssetTypeChange(newType, asset)}
                />
                <TextField
                  id='project-design-link'
                  label={`Посилання на дизайн макет ${idx + 1}`}
                  margin='normal'
                  type='text'
                  variant='outlined'
                  value={asset.link}
                  onChange={({ target }) => onAssetLinkChange(target.value, asset)}
                  required
                  fullWidth
                />
                <TextField
                  id='project-design-link'
                  label={'Опис макету'}
                  margin='normal'
                  type='text'
                  variant='outlined'
                  value={renderTranslation(language, asset.description)}
                  onChange={({ target }) => onAssetDescriptionChange(target.value, asset)}
                  required
                  fullWidth
                />
                <Button
                  variant={'contained'}
                  color={'error'}
                  onClick={() => onDesignAssetRemove(asset)}
                >
                  Видалити макет
                </Button>
              </Box>
            ))}
          </Box>

          <TagsSelect tagsState={tags.map((tag) => tag.title)} handleChange={onTagsChange} />

          <TextField
            id='project-difficulty'
            label='Складність проєкту від 1 до 5'
            margin='normal'
            type='number'
            inputProps={{ min: 1, max: 5 }}
            variant='outlined'
            value={difficultyLevel}
            onChange={({ target }) => setDifficultyLevel(Number(target.value))}
            required
            fullWidth
          />

          <TextField
            id='project-assets'
            label='Посилання на архів з матеріалами до проєкту'
            margin='normal'
            type='text'
            variant='outlined'
            value={projectAssets}
            onChange={({ target }) => setProjectAssets(target.value)}
            fullWidth
          />

          <Divider />

          <Box sx={{ my: 3 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: 'rgba(0, 0, 0, .03)' }}
              >
                <Typography sx={{ fontWeight: 600 }}>Уроки</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack sx={{ my: 3 }} direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                  <Button onClick={onAddLessonBtnClick} variant={'contained'}>
                    Додати урок
                  </Button>
                  {lessons.length ? (
                    <Button onClick={onRemoveLessonBtnClick} variant={'contained'} color={'error'}>
                      Видалити останній уррок
                    </Button>
                  ) : null}
                </Stack>

                <Tabs
                  activeTab={activeTab}
                  tabsHeader={lessons.map((lesson, index) => (
                    <TabHeader
                      label={`Урок №${lesson.number}`}
                      index={index}
                      key={new Date(lesson.number).getTime()}
                      onClick={(value) => setActiveTab(value)}
                    />
                  ))}
                  tabsContent={lessons.map((lesson, index) => (
                    <TabContent
                      value={activeTab}
                      index={index}
                      key={new Date(lesson.number).getTime()}
                    >
                      <ProjectLessonForm lesson={lesson} onLessonUpdate={onLessonUpdate} />
                    </TabContent>
                  ))}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </CardContent>
        <Divider />
        <FormFooter isLoading={isCreateLoading} successMessage={successMessage} />
      </Card>
    </form>
  );
};

function validateLessonsEmptyValues(lessons: ProjectLessonDto[]): boolean {
  let isValid = true;

  lessons.forEach((lesson) => {
    if (
      isEmptyString(
        renderTranslation(UkLang, lesson.taskDescription),
        renderTranslation(RuLang, lesson.taskDescription),
        renderTranslation(UkLang, lesson.markupDescription ?? EmptyTranslations),
        renderTranslation(RuLang, lesson.markupDescription ?? EmptyTranslations),
      )
    ) {
      isValid = false;
    }
  });

  return isValid;
}
