import {
  DashboardStatisticsDto,
  GetStudentsCountByStudyClassesDto,
  StudentCountByStudyClass,
} from '../../../openapi';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';

const baseUrl = 'dashboard/';

const getDashboardStatisticsEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'statistics'),
};

const getStudentsCountByStudyClassesEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'study-classes'),
};

export const dashboardApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardStatistics: builder.query<DashboardStatisticsDto, void>({
      query: () => ({
        url: getDashboardStatisticsEndpoint.path,
        method: getDashboardStatisticsEndpoint.method,
      }),
      providesTags: [ApiEntitiesTags.dashboardStatistics],
    }),
    getStudentsCountByStudyClasses: builder.query<StudentCountByStudyClass[], void>({
      query: () => ({
        url: getStudentsCountByStudyClassesEndpoint.path,
        method: getStudentsCountByStudyClassesEndpoint.method,
      }),
      providesTags: [ApiEntitiesTags.studentsCountByStudyClasses],
      transformResponse: (response: GetStudentsCountByStudyClassesDto) => response.data,
    }),
  }),
});

export const { useGetDashboardStatisticsQuery, useGetStudentsCountByStudyClassesQuery } =
  dashboardApi;
