import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import { LoadingButton } from '@mui/lab';
import { Avatar, IconButton, Paper, Stack, SvgIcon, Theme, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { useLoginToLinkedAccountMutation } from '../../features/auth/redux/auth-api';
import { setToken } from '../../features/user/redux/user-slice';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { useLinkedUsers } from '../../hooks/redux-helper-hooks/useLinkedUsers';
import { usePopover } from '../../hooks/use-popover';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { buildNotificationArgs, useFormNotifications } from '../../hooks/useFormNotifications';
import { LinkedUserDto } from '../../openapi';
import { setAuthData } from '../../shared';
import { AccountPopover } from './account-popover';
import { LinkedAccountsPopover } from './linked-accounts-popover';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

type Props = {
  onSidebarOpen: () => void;
};

export const DashboardTopNavbar = ({ onSidebarOpen }: Props) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover<HTMLDivElement>();
  const linkedAccountPopover = usePopover<HTMLButtonElement>();
  const { currentUser } = useCurrentUser();
  const { linkedUsers } = useLinkedUsers();
  const [loginToLinkedAccount, mutationResult] = useLoginToLinkedAccountMutation();
  useFormNotifications(
    buildNotificationArgs(mutationResult, { successMessage: 'Ви успішно увійшли!' }),
  );
  const dispatch = useAppDispatch();

  const loginAsLinkedUser = (linkedUser: LinkedUserDto) => {
    const {
      linkedAccountId,
      user: { id, email },
    } = linkedUser;

    loginToLinkedAccount({ linkedAccountId, userEmail: email, userId: id })
      .unwrap()
      .then(({ token }) => {
        dispatch(setToken(token));
        setAuthData({ token: token });
        window.location.reload();
      })
      .finally(() => {
        linkedAccountPopover.handleClose();
      });
  };

  return (
    <>
      <Paper
        component='header'
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
        elevation={8}
      >
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems='center' direction='row' spacing={2}>
            {!lgUp && (
              <IconButton onClick={onSidebarOpen}>
                <SvgIcon fontSize='small'>
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
            {/* Search is not yet implemented */}
            {/*<Tooltip title='Search'>*/}
            {/*  <IconButton>*/}
            {/*    <SvgIcon fontSize='small'>*/}
            {/*      <MagnifyingGlassIcon />*/}
            {/*    </SvgIcon>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </Stack>
          <Stack alignItems='center' direction='row' spacing={2}>
            {/* Notifications is not yet implemented */}
            {/*<Tooltip title='Notifications'>*/}
            {/*  <IconButton>*/}
            {/*    <Badge badgeContent={4} color='success' variant='dot'>*/}
            {/*      <SvgIcon fontSize='small'>*/}
            {/*        <BellIcon />*/}
            {/*      </SvgIcon>*/}
            {/*    </Badge>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
            {!!linkedUsers.length && (
              <LoadingButton
                color='primary'
                variant='outlined'
                type='button'
                onClick={linkedAccountPopover.handleOpen}
                ref={linkedAccountPopover.anchorRef}
                loading={mutationResult.isLoading}
              >
                Увійти як
              </LoadingButton>
            )}

            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: 'pointer',
                height: 40,
                width: 40,
              }}
              src={currentUser.avatarUrl}
            />
          </Stack>
        </Stack>
      </Paper>
      {!!linkedUsers.length && (
        <LinkedAccountsPopover
          anchorEl={linkedAccountPopover.anchorRef.current as Element}
          open={linkedAccountPopover.open}
          onClose={linkedAccountPopover.handleClose}
          onAccountClick={loginAsLinkedUser}
        />
      )}
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current as Element}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};
