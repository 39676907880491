import * as React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  EmptyTranslations,
  Language,
  renderTranslation,
  UkLang,
  validateCodeLanguage,
} from '../../../../shared';
import { CriterionExample } from '../../../../openapi';

type Props = {
  example: CriterionExample;
  removeExample: (id: string) => void;
  updateExample: (id: string, updatedSample: CriterionExample) => void;
  criterionLanguage: Language;
};

export const SampleField = ({
  example,
  removeExample,
  updateExample,
  criterionLanguage,
}: Props) => {
  function setSampleLanguage(language: string) {
    if (validateCodeLanguage(language)) {
      const updatedSample = { ...example, language };
      updateExample(example.id, updatedSample);
    }
  }

  function setSampleCode(code: string) {
    const updatedSample = {
      ...example,
      code:
        criterionLanguage === UkLang
          ? { ...example.code, uk: code }
          : { ...example.code, ru: code },
    };
    updateExample(example.id, updatedSample);
  }

  function setSampleDescription(description: string) {
    const updatedSample = {
      ...example,
      description:
        criterionLanguage === UkLang
          ? { ...(example.description ?? EmptyTranslations), uk: description }
          : { ...(example.description ?? EmptyTranslations), ru: description },
    };
    updateExample(example.id, updatedSample);
  }

  return (
    <Box
      sx={{
        p: 1,
        marginY: 2,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 1,
      }}
    >
      <FormControl margin='normal' fullWidth>
        <InputLabel id='criteriaSampleLang'>Мова прикладу</InputLabel>
        <Select
          labelId='criteriaSampleLang'
          id='criteriaSampleLang'
          label='Мова прикладу'
          value={example.language}
          onChange={(event) => setSampleLanguage(event.target.value)}
          fullWidth
          required
        >
          <MenuItem value={CriterionExample.language.HTMLBARS} defaultChecked>
            HTML
          </MenuItem>
          <MenuItem value={CriterionExample.language.CSS} defaultChecked>
            {CriterionExample.language.CSS}
          </MenuItem>
          <MenuItem value={CriterionExample.language.SCSS} defaultChecked>
            {CriterionExample.language.SCSS}
          </MenuItem>
          <MenuItem value={CriterionExample.language.JAVASCRIPT} defaultChecked>
            {CriterionExample.language.JAVASCRIPT}
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        id='criteria-sample-code'
        label='Приклади (код)'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(criterionLanguage, example.code)}
        onChange={({ target }) => setSampleCode(target.value)}
        minRows={4}
        required
        fullWidth
        multiline
      />
      <TextField
        id='criteria-sample-descr'
        label='Опис'
        margin='normal'
        type='text'
        variant='outlined'
        value={renderTranslation(criterionLanguage, example.description ?? EmptyTranslations)}
        onChange={({ target }) => setSampleDescription(target.value)}
        minRows={4}
        fullWidth
        multiline
      />

      <Button
        type='button'
        color={'error'}
        variant={'outlined'}
        onClick={() => removeExample(example.id)}
        startIcon={<DeleteIcon />}
      >
        Видалити приклад
      </Button>
    </Box>
  );
};
