import { useEffect } from 'react';
import { useAppSelector } from '../useAppSelector';
import { useAppDispatch } from '../useAppDispatch';
import { fetchProjectTags } from '../../features/project/redux/thunks';

export const useTags = () => {
  const dispatch = useAppDispatch();
  const shouldRefresh = useAppSelector((state) => state.project.shouldRefreshTags);
  const tags = useAppSelector((state) => state.project.projectTags);

  useEffect(() => {
    dispatch(fetchProjectTags());
  }, [shouldRefresh]);

  return { tags };
};
