import { Button, CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useRef } from 'react';
import { Preloader } from './components/preloader/preloader';
import { NavigationOutput } from './features/navigation/navigation-output.feature';
import { useRequiredDataLoader } from './hooks/redux-helper-hooks/useRequiredDataLoader';
import { useAuth } from './hooks/useAuth';
import { CloseIcon } from './icons';
import { OpenAPI } from './openapi';
import { envs, getAuthData } from './shared';
import { theme } from './theme';

const authData = getAuthData();

OpenAPI.BASE = envs.serverUrl || '';

const App = () => {
  useRequiredDataLoader();
  const { token } = useAuth();
  const snackbarRef = useRef<SnackbarProvider>(null);

  OpenAPI.HEADERS = {
    Authorization: `Bearer ${authData ? authData.token : token ?? ''}`,
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        ref={snackbarRef}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        action={(snackbarId) => (
          <Button
            onClick={() => snackbarRef.current?.closeSnackbar(snackbarId)}
            variant={'text'}
            sx={{ color: '#fff' }}
          >
            <CloseIcon />
          </Button>
        )}
        hideIconVariant
      >
        <CssBaseline />
        <div className='wrapper'>
          <NavigationOutput />

          <Preloader />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
