import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import React from 'react';
import { useCurrentUser } from '../../../hooks/redux-helper-hooks/useCurrentUser';
import { NavItem } from './nav-item';
import { SidebarNavItem } from './nav-ltems';

type Props = {
  item: Pick<SidebarNavItem, 'title' | 'children' | 'icon'>;
};

export const NavAccordion = ({ item }: Props) => {
  const { currentUser } = useCurrentUser();
  const { title, children, icon } = item;

  if (!children || children.length === 0) {
    return null;
  }

  return (
    <Accordion
      sx={{
        backgroundColor: 'transparent',
        mb: 0.5,
        py: 0,
        px: 2,
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
      }}
      disableGutters
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          borderRadius: 1,
          color: 'neutral.300',
          px: 3,
          fontSize: '1rem',
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}
      >
        <Box sx={{ ml: -0.5, mr: 1 }}>{icon}</Box>
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {children.map((nestedItem) =>
          currentUser?.role && nestedItem.requiredRoles.includes(currentUser.role) ? (
            <NavItem item={nestedItem} key={nestedItem.title} />
          ) : null,
        )}
      </AccordionDetails>
    </Accordion>
  );
};
