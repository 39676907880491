export const customScrollbar = (color = 'blue') => {
  let thumbBackground = '';
  if (color === 'blue') {
    thumbBackground = '#5048E5';
  }

  return {
    '&::-webkit-scrollbar': {
      width: 10,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: thumbBackground,
    },
  };
};
