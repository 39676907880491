import { MentorRole, normalizeArray } from '../../../shared';
import { useGetUsersQuery } from '../../user/redux/user-api';

export const useMentors = () => {
  const { data, isFetching } = useGetUsersQuery({
    page: 1,
    role: MentorRole,
  });

  const mentors = data?.users ?? [];
  const normalizedMentors = normalizeArray(mentors, 'id');

  return { mentors, normalizedMentors, isLoading: isFetching };
};
