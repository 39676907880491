type AuthData = {
  token: string;
};

const storageKeys = {
  token: 'token',
};

export function setAuthData(authData: AuthData) {
  localStorage.setItem(storageKeys.token, authData.token);
}

export function getAuthData(): AuthData | null {
  const token = localStorage.getItem(storageKeys.token);

  return token ? { token } : null;
}
