import * as React from 'react';
import { InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { SearchIcon } from '../../../../icons';
import { useDebounce } from '../../../../hooks/useDebounce';

type Props = {
  onSearch: (searchTerm: string) => void;
};

export const SearchFilter = ({ onSearch }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const { delayValue } = useDebounce({
    delayInMs: 400,
    callback: (value) => onSearch(value),
  });

  const onInputChange = (value: string) => {
    delayValue(value);
    setInputValue(value);
  };

  return (
    <>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SvgIcon color='action' fontSize='small'>
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        placeholder='Пошук користувача'
        variant='outlined'
        value={inputValue}
        onChange={({ target }) => onInputChange(target.value)}
        fullWidth
      />
      <Typography variant='body2' sx={{ marginTop: 1 }}>
        Пошук чутливий до регістру*
      </Typography>
    </>
  );
};
