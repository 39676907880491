import { Box } from '@mui/material';
import { styles } from './styles';

type Props = {
  color: string;
};

export const ColorSwatch = ({ color }: Props) => {
  return <Box sx={styles.swatch(color)} boxShadow={4} />;
};
