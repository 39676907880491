import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appSlice, setApiError, updateRequiredDataStatus } from '../../features/app/app-slice';
import { projectApi } from '../../features/project/redux/project-api';
import { userApi } from '../../features/user/redux/user-api';
import { NavigationRoutes, sharedStudyClassApi } from '../../shared';
import { store } from '../../store/store';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { useAuth } from '../useAuth';

let initStarted = 0;
type ApiError = { isError: boolean; error: { status: number } };

export const useRequiredDataLoader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const requiredDataStatus = useAppSelector((state) => state.app.requiredDataStatus);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn && requiredDataStatus === 'unset' && !initStarted) {
      ++initStarted;

      dispatch(updateRequiredDataStatus('loading'));

      const promises = [
        store.dispatch(userApi.endpoints.getCurrentUser.initiate()),
        store.dispatch(userApi.endpoints.getLinkedUsers.initiate()),
        store.dispatch(projectApi.endpoints.getProjects.initiate()),
        store.dispatch(sharedStudyClassApi.endpoints.getStudyClasses.initiate()),
        store.dispatch(sharedStudyClassApi.endpoints.getCurrentStudyClass.initiate()),
      ];

      const handleUpdateStatus = () => {
        dispatch(appSlice.actions.updateLoadingProgress({ actionsCount: promises.length }));
      };

      promises.forEach((promise) => {
        promise
          .then((result: { isError: boolean } | void) => {
            if ((result as ApiError)?.isError) {
              navigate(NavigationRoutes.serverError.route);
              store.dispatch(
                setApiError({
                  isError: true,
                  message: '',
                  status: (result as ApiError).error.status,
                }),
              );
            }
          })
          .finally(handleUpdateStatus);
      });

      Promise.allSettled(promises).then(() => {
        dispatch(updateRequiredDataStatus('ready'));
      });
    }
  }, [isLoggedIn]);
};
