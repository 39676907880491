import { Route, Routes } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import {
  createRelativePath,
  getCreateRouteUrl,
  getEditRouteUrl,
  getShowRouteUrl,
  NavigationRoutes,
} from '../../shared';
import { NotFoundPage } from '../not-found/not-found.page';
import { EditStudyClassFeature } from './features/edit-study-class.feature';
import { CreateStudyClassFeature } from './features/create-study-class.feature';
import { ShowStudyClassFeature } from './features/show-study-class.feature';
import { StudyClassListFeature } from './features/study-class-list.feature';

export const StudyClassPage = () => {
  useDocumentTitle();
  const studyClassRoutes = NavigationRoutes.studyClass;

  return (
    <Routes>
      <Route path={'/'} element={<StudyClassListFeature />} />
      <Route
        path={createRelativePath(studyClassRoutes.list.route, getShowRouteUrl(studyClassRoutes))}
        element={<ShowStudyClassFeature />}
      />
      <Route
        path={createRelativePath(studyClassRoutes.list.route, getCreateRouteUrl(studyClassRoutes))}
        element={<CreateStudyClassFeature />}
      />
      <Route
        path={createRelativePath(studyClassRoutes.list.route, getEditRouteUrl(studyClassRoutes))}
        element={<EditStudyClassFeature />}
      />

      <Route path={'/*'} element={<NotFoundPage />} />
    </Routes>
  );
};
