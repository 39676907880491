import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudyClassDto } from '../../../../openapi';
import { State } from './types';

const initialState: State = {
  selectedStudyClass: null,
};
const studyClassSlice = createSlice({
  name: 'study-class',
  initialState,
  reducers: {
    selectStudyClass: (state, action: PayloadAction<StudyClassDto | null>) => {
      state.selectedStudyClass = action.payload;
    },
  },
});

export const { selectStudyClass } = studyClassSlice.actions;

export default studyClassSlice.reducer;
