import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CriterionPreview } from '../../../features/project/redux/types';
import { getMenuStyles, isEmptyArray, MenuProps, renderValue } from '../../../shared';

type Props = {
  criteriaState: string[];
  handleChange: (value: string[]) => void;
  criteriaPreview: CriterionPreview[];
  label: string;
};

export const CriteriaSelect = ({ criteriaState, handleChange, criteriaPreview, label }: Props) => {
  const theme = useTheme();

  const onSelectChange = (value: string | string[]) => {
    handleChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='requirement-criteria'>{label}</InputLabel>
      <Select
        labelId='requirement-criteria'
        id='requirement-criteria'
        label={label}
        multiple
        value={criteriaState}
        onChange={({ target }) => onSelectChange(target.value)}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {criteriaPreview.map((criterion) => (
          <MenuItem
            key={criterion.id}
            value={String(criterion.number)}
            style={getMenuStyles(
              String(criterion.number),
              criteriaPreview.map((el) => String(el.number)),
              theme,
            )}
          >
            {criterion.number}
          </MenuItem>
        ))}
        {isEmptyArray(criteriaPreview) && <MenuItem disabled>Критерії не знайдено</MenuItem>}
      </Select>
    </FormControl>
  );
};
