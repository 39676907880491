import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useProjects } from '../../../../../../hooks/redux-helper-hooks/useProjects';
import { useNullDebounce } from '../../../../../../hooks/useNullDebounce';
import { CriteriaProgressItemDto, UserDto } from '../../../../../../openapi';
import { CriteriaProgressForm } from '../../criteria-progress-form/criteria-progress-form';
import { styles } from '../../styles';

type Props = {
  student: UserDto;
  projectId: string;
};

export const StudentProgressByProject = ({
  student: studentProp,
  projectId: projectProp,
}: Props) => {
  // useNullDebounce needed to 'unmount fade animation' to work properly as props are gone at the moment of animation
  const projectId = useNullDebounce(projectProp) as string;
  const student = useNullDebounce(studentProp, 400, true) as UserDto;
  const { normalizedProjects } = useProjects();
  const projectDto = normalizedProjects[projectId];
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const studentProject = student.projects.find((sp) => sp.projectId === projectId);

  // Finds unique criterion ids
  const projectCriteriaCount = projectDto.lessons.reduce((acum, lesson) => {
    return [...new Set([...acum, ...lesson.criteria.map((criterion) => criterion.id)]).values()];
  }, [] as string[]).length;
  const studentDoneCriteriaCount =
    studentProject?.criteriaProgress.filter(
      (progressItem) => progressItem.status === CriteriaProgressItemDto.status.DONE,
    ).length ?? 0;

  const doneCriteriaPercent = ((studentDoneCriteriaCount * 100) / projectCriteriaCount).toFixed(0);

  const onOpenBtnClick = () => {
    setShowModal(true);
  };

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <Box marginY={2}>
        <Typography>
          Прогрес виконання критеріїв проєкту -{' '}
          <Typography variant={'caption'} fontWeight={'bolder'} fontSize={'inherit'}>
            {doneCriteriaPercent}
          </Typography>
          %
        </Typography>
        <Button
          variant={'outlined'}
          sx={styles.openCriteriaProgressBtn}
          onClick={onOpenBtnClick}
          fullWidth={isMobile}
        >
          Відкрити прогрес по уроках
        </Button>
      </Box>
      <CriteriaProgressForm
        projectId={projectId}
        student={showModal ? student : null}
        onModalClose={onModalClose}
      />
    </>
  );
};
