import { Box, Button, Container, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from './page-title';

type Props = {
  children: JSX.Element;
  title: string;
  listUrl?: string;
};

export const FormPageLayout = ({ children, title, listUrl }: Props) => {
  const navigate = useNavigate();
  const redirectToList = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (listUrl) {
      navigate(listUrl);
    }
  };

  return (
    <Container maxWidth='lg'>
      <Stack justifyContent={'space-between'} alignItems={'center'} direction={{ sm: 'row' }}>
        <PageTitle text={title} />
        {listUrl && (
          <Button variant={'text'} onClick={redirectToList} component='a' href={listUrl}>
            До списку
          </Button>
        )}
      </Stack>
      <Box sx={{ pt: 3 }}>{children}</Box>
    </Container>
  );
};
