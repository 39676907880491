import { CriterionDto, CriterionExample, UserDto } from '../openapi';

export function validateCategory(str: string): str is CriterionDto.category {
  return Object.values(CriterionDto.category).includes(str as CriterionDto.category);
}

export function validateCodeLanguage(str: string): str is CriterionExample.language {
  return Object.values(CriterionExample.language).includes(str as CriterionExample.language);
}

export function validateUserRole(str: string): str is UserDto.role {
  return Object.values(UserDto.role).includes(str as UserDto.role);
}
