import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { customScrollbar } from '../../shared';

type Props = {
  isOpen: boolean;
  title: string;
  dialogActions?: JSX.Element;
  onClose: () => void;
  children: JSX.Element;
};

export const FormDialog = ({ title, isOpen, onClose, dialogActions, children }: Props) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={styles.dialogContent}>{children}</DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

const styles = {
  dialogContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
    ...customScrollbar(),
  },
};
