import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/redux-helper-hooks/useCurrentUser';
import { UserDto } from '../../../openapi';
import { NavigationRoutes } from '../../../shared';
import { RedirectToLoginPage } from '../redirects/redirect-to-login-page';

type Props = {
  roles: UserDto.role[];
  redirectPath?: string;
  children?: JSX.Element;
};

export const RequireRole = ({
  roles,
  redirectPath = NavigationRoutes.home.route,
  children,
}: Props) => {
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return <RedirectToLoginPage />;
  }

  const isAllowed = roles.includes(currentUser.role);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
