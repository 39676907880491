import { Tab } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  index: number;
  onClick: (value: number) => void;
};

export const TabHeader = ({ label, index, onClick }: Props) => {
  return <Tab label={label} onClick={() => onClick(index)} {...a11yProps(index)} />;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}
