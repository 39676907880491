import type { SelectChangeEvent } from '@mui/material';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useProjects } from '../../../hooks/redux-helper-hooks/useProjects';
import { MenuProps } from '../../../shared';

type Props = {
  onChangeHandler: (value: string | string[]) => void;
  // Project ids
  projectsIds: string[];
};

export const ProjectsSelect = ({ onChangeHandler, projectsIds }: Props) => {
  const { projects } = useProjects();

  const onChange = (event: SelectChangeEvent<string[]>) => {
    onChangeHandler(event.target.value);
  };

  const renderValue = (selected: string[]) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value: string) => (
        <Chip key={value} label={projects.find((p) => p.id === value)?.title ?? ''} />
      ))}
    </Box>
  );

  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='available-projects'>Доступні проєкти</InputLabel>
      <Select
        labelId='available-projects'
        id='available-projects'
        label='Доступні проєкти'
        multiple
        value={projectsIds}
        onChange={onChange}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {projects.map((project) => (
          <MenuItem key={project.id} value={project.id}>
            {project.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
