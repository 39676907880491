import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type Props = {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  items: string[];
};

export const FormSelect = ({ items, onChange, id, label, value }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ my: 3 }}>
      <InputLabel id={id + '-label'}>{label}</InputLabel>
      <Select labelId={id + '-label'} id={id} value={value} label={label} onChange={handleChange}>
        {items.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
