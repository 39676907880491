import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../features/user/redux/user-slice';
import { useCurrentUser } from '../../hooks/redux-helper-hooks/useCurrentUser';
import { UsePopoverProps } from '../../hooks/use-popover';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { NavigationRoutes, setAuthData } from '../../shared';

export const AccountPopover = ({ anchorEl, onClose, open }: UsePopoverProps) => {
  const { currentUser } = useCurrentUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(setToken(''));
    setAuthData({ token: '' });
    window.location.reload();
  };

  const navigateToProfile = () => {
    onClose();
    navigate(NavigationRoutes.profile.route);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant='overline'>Аккаунт</Typography>
        <Typography color='text.secondary' variant='body2'>
          {currentUser.fullName}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={navigateToProfile}>Профіль</MenuItem>
        <MenuItem onClick={handleSignOut}>Вийти</MenuItem>
      </MenuList>
    </Popover>
  );
};
