export const styles = {
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& .uploadcare--widget__button,& .uploadcare--widget__dragndrop-area,& .uploadcare--progress, & .uploadcare--widget__text':
      { display: 'none' },
  },
  avatar: {
    height: 80,
    mb: 2,
    width: 80,
  },
};
