import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useProjects } from '../../../../../hooks/redux-helper-hooks/useProjects';
import { UserDto } from '../../../../../openapi';
import { ProjectSettingsForm } from '../project-settings-form/project-settings-form';
import { styles } from '../styles';

type Props = {
  student: UserDto | null;
  selectedProject: string;
  onProjectChange: (projectId: string) => void;
};

export const StudentProjectsSelect = ({
  student,
  onProjectChange,
  selectedProject = '',
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = useState(false);
  const { normalizedProjects } = useProjects();
  const onChange = (event: SelectChangeEvent) => {
    const projectId = event.target.value;

    onProjectChange(projectId);
  };

  const onSettingsBtnClick = () => {
    setShowModal(true);
  };

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <FormControl fullWidth margin={'normal'}>
        <InputLabel id='student-project-select-label'>Проєкт</InputLabel>
        <Select
          labelId='student-project-select-label'
          id='student-project-select'
          value={selectedProject}
          label='Проєкт'
          onChange={onChange}
        >
          {!student && <MenuItem value={''} />}

          {(student?.projects ?? []).map(({ projectId }) => (
            <MenuItem value={projectId} key={projectId}>
              {normalizedProjects[projectId].name.uk}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant={'outlined'}
        sx={styles.projectSettingsBtn}
        onClick={onSettingsBtnClick}
        fullWidth={isMobile}
      >
        Налаштування проєктів
      </Button>

      <ProjectSettingsForm student={student} isModalOpen={showModal} onModalClose={onModalClose} />
    </>
  );
};
