import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { RedirectToLoginPage } from '../redirects/redirect-to-login-page';

type Props = {
  children?: JSX.Element;
};

export const RequireAuth = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <RedirectToLoginPage />;
  }

  return children ? children : <Outlet />;
};
