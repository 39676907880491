import React from 'react';
import { useTheme } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getMenuStyles, isEmptyArray, MenuProps, renderValue } from '../../../shared';
import { useTags } from '../../../hooks/redux-helper-hooks/useTags';

type Props = {
  tagsState: string[];
  handleChange: (value: string[]) => void;
};

export const TagsSelect = ({ tagsState, handleChange }: Props) => {
  const theme = useTheme();
  const { tags } = useTags();

  const onSelectChange = (value: string | string[]) => {
    handleChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='project-tags'>Теги</InputLabel>
      <Select
        labelId='project-tags'
        id='project-tags'
        label={'Теги'}
        multiple
        value={tagsState}
        onChange={({ target }) => onSelectChange(target.value)}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {tags.map((tag) => (
          <MenuItem
            key={tag.id}
            value={tag.title}
            style={getMenuStyles(
              String(tag.id),
              tags.map((el) => el.id),
              theme,
            )}
          >
            {tag.title}
          </MenuItem>
        ))}
        {isEmptyArray(tags) && <MenuItem disabled>Тегів не знайдено</MenuItem>}
      </Select>
    </FormControl>
  );
};
