import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from '../../../openapi';
import { State } from './types';

const initialState: State = {
  selectedStudentToReview: null,
  // TODO: instead of "all" string just set null value in case of 'all' groups
  studyClass: 0,
  currentStudyClassCheckboxSelected: true,
  selectedStudentToEditProjects: null,
};

export const classSpreadsheetSlice = createSlice({
  name: 'class-spreadsheet',
  initialState,
  reducers: {
    selectStudentToReview: (
      state,
      action: PayloadAction<{ student: UserDto; lessonNumber: number }>,
    ) => {
      state.selectedStudentToReview = action.payload;
    },
    cancelReview: (state) => {
      state.selectedStudentToReview = null;
    },
    setStudyClass: (state, action: PayloadAction<number | string>) => {
      state.studyClass = action.payload;
    },
    toggleCurrentStudyClassCheckboxSelected: (state) => {
      state.currentStudyClassCheckboxSelected = !state.currentStudyClassCheckboxSelected;
    },
    selectStudentToEditProjects: (state, action: PayloadAction<UserDto>) => {
      state.selectedStudentToEditProjects = action.payload;
    },
    cancelEditingProjects: (state) => {
      state.selectedStudentToEditProjects = null;
    },
  },
});

export const {
  selectStudentToReview,
  cancelReview,
  setStudyClass,
  toggleCurrentStudyClassCheckboxSelected,
  selectStudentToEditProjects,
  cancelEditingProjects,
} = classSpreadsheetSlice.actions;

export default classSpreadsheetSlice.reducer;
