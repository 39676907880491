import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CriterionDto } from '../../../openapi';
import { EmptyCategory, emptyCategory } from './criteria-form';

type Props = {
  currentValue: CriterionDto.category | EmptyCategory;
  onFocusHandler: () => void;
  onChangeHandler: (categoryValue: string) => void;
};

export const CriteriaCategorySelect = ({
  currentValue,
  onFocusHandler,
  onChangeHandler,
}: Props) => {
  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='category'>Категорія</InputLabel>
      <Select
        labelId='category'
        id='category'
        label='Категорія'
        value={currentValue}
        onFocus={onFocusHandler}
        onChange={({ target }) => onChangeHandler(target.value)}
        fullWidth
        required
      >
        <MenuItem value={emptyCategory} defaultChecked>
          {emptyCategory}
        </MenuItem>
        {Object.values(CriterionDto.category).map((category) => (
          <MenuItem value={category} key={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
