import { Box, Container, Typography } from '@mui/material';
import { CriteriaForm } from '../../components/criteria/criteria-form/criteria-form';
import { useAppSelector } from '../../hooks/useAppSelector';

export const EditCriteriaFeature = () => {
  const selectedCriterion = useAppSelector((state) => state.criteria.criterionToEdit);

  return (
    <Container maxWidth='lg'>
      <Typography sx={{ mb: 3 }} variant='h4'>
        Модерация
      </Typography>
      <Box sx={{ pt: 3 }}>
        <CriteriaForm selectedCriterion={selectedCriterion ?? undefined} />
      </Box>
    </Container>
  );
};
