import { useCallback } from 'react';
import debounce from 'lodash/debounce';

type UseDebounceArgs = {
  delayInMs: number;
  callback: (delayedValue: string) => void;
};

export const useDebounce = ({ delayInMs, callback }: UseDebounceArgs) => {
  const delayValue = useCallback(
    debounce((delayedValue: string) => {
      callback(delayedValue);
    }, delayInMs),
    [],
  );

  return { delayValue };
};
