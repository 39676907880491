export const styles = {
  accordion: {
    my: 2,
    border: '1px solid #E6E8F0',
  },
  criterion: {
    mt: 1,
    mb: 3,
    position: 'relative',
  },
  darkDivider: {
    borderColor: 'rgba(0,0,0,0.4)',
  },
  linearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  statusBar: (isMobile: boolean) => ({
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
};
