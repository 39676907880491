import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import AnimatedNumber from 'react-animated-numbers';
import { useAppSelector } from '../../../hooks/useAppSelector';

type Props = {
  title: string;
  count: number;
  icon: JSX.Element;
  color: string;
};

export const StatisticsItem = ({ title, count, icon, color }: Props) => {
  const wasAnimated = useAppSelector((state) => state.dashboard.wasAnimated);

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack alignItems='flex-start' direction='row' justifyContent='space-between' spacing={3}>
          <Stack spacing={1}>
            <Typography color='text.secondary' variant='overline'>
              {title}
            </Typography>
            <Typography variant='h4'>
              {wasAnimated ? count : <AnimatedNumber animateToNumber={count} />}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: color,
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>{icon}</SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};
