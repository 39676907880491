import React from 'react';
import { Button } from '@mui/material';
import { FormDialog } from '../../components/modals/form-dialog';
import { logout } from '../../features/user/redux/user-slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setAuthData } from '../../shared';

export const ServerErrorPage = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.app.apiErrorState);

  const isUnauthorizedError = status === 401;
  const handleClose = () => {
    if (isUnauthorizedError) {
      setAuthData({ token: '' });
      dispatch(logout());
    }

    location.reload();
  };

  const title = isUnauthorizedError
    ? 'Сталася помилка авторизації, спробуйте знову увійти в свій аккаунт'
    : "Сталася помилка сервера, спробуйте перезавантажити сторінку або зв'яжиться з адміністратором платформи";

  return (
    <FormDialog
      title={title}
      isOpen={true}
      onClose={handleClose}
      dialogActions={
        <Button onClick={handleClose} type={'button'}>
          {isUnauthorizedError ? 'Перейти до авторизації' : 'Перезавантажити сторінку'}
        </Button>
      }
    >
      <div></div>
    </FormDialog>
  );
};
