import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AllRoles, UserRolesFilterType } from '../../../../features/user/user-overview.feature';
import { UserDto } from '../../../../openapi';
import { validateUserRole } from '../../../../shared';

type Props = {
  role: UserRolesFilterType;
  onRoleChange: (role: UserRolesFilterType) => void;
};

export const RoleFilter = ({ role, onRoleChange }: Props) => {
  const onSelectChange = (value: string) => {
    if (value === AllRoles) {
      onRoleChange(AllRoles);
    }

    if (!validateUserRole(value)) {
      return;
    }

    onRoleChange(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id='user-role'>Роль</InputLabel>
      <Select
        labelId='user-role'
        id='user-role'
        label='Роль'
        value={role}
        onChange={({ target }) => onSelectChange(target.value)}
        fullWidth
      >
        <MenuItem value={AllRoles} defaultChecked>
          Всі
        </MenuItem>
        <MenuItem value={UserDto.role.STUDENT}>Учень</MenuItem>
        <MenuItem value={UserDto.role.MENTOR}>Ментор</MenuItem>
        <MenuItem value={UserDto.role.ADMIN}>Адмін</MenuItem>
      </Select>
    </FormControl>
  );
};
