import { LoginDto, LoginResponseDto, LoginToLinkedAccountDto } from '../../../openapi';
import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';
import { LoginArgs } from './types';

const loginEndpoint: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint('auth'),
};

const loginToLinkedAccountEndpoint: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint('auth/linked-account'),
};

export const authApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseDto, LoginArgs>({
      query: ({ email, password }) => ({
        method: loginEndpoint.method,
        url: loginEndpoint.path,
        body: { password, username: email } as LoginDto,
      }),
    }),
    loginToLinkedAccount: builder.mutation<LoginResponseDto, LoginToLinkedAccountDto>({
      query: (dto) => ({
        url: loginToLinkedAccountEndpoint.path,
        method: loginToLinkedAccountEndpoint.method,
        body: dto as LoginToLinkedAccountDto,
      }),
    }),
  }),
});

export const { useLoginMutation, useLoginToLinkedAccountMutation } = authApi;
