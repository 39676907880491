import { useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PageTitle } from '../../components/layout/page-title';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Loader } from '../../components/loader/loader';
import { EntityActions } from '../../widgets/entity-actions/entity-actions';
import { ProjectDto } from '../../openapi';
import { ConfirmDialog } from '../../components/modals/confirm-dialog/confirm-dialog';
import { NavigationRoutes } from '../../shared';
import { useHistory } from '../../hooks/useHistory';
import { selectProjectToDelete, selectProjectToEdit } from './redux/project-slice';
import { deleteProject, fetchProjects } from './redux/thunks';

export const ProjectsOverviewFeature = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.project.projects);
  const isLoading = useAppSelector((state) => state.project.isProjectsLoading);
  const isDeleteLoading = useAppSelector((state) => state.project.isDeleteLoading);
  const selectedProjectToDelete = useAppSelector((state) => state.project.selectedProjectToDelete);

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const onEditBtnClick = (project: ProjectDto) => {
    dispatch(selectProjectToEdit(project));
    history.push(NavigationRoutes.editProject.route);
  };

  const onDeleteBtnClick = (project: ProjectDto) => {
    dispatch(selectProjectToDelete(project));
  };

  const deleteProjectHandler = () => {
    if (!selectedProjectToDelete) {
      return;
    }

    dispatch(deleteProject(selectedProjectToDelete.id));
  };

  const onCancelDelete = () => {
    dispatch(selectProjectToDelete(null));
  };

  return (
    <Container maxWidth={false}>
      <PageTitle text={'Проєкти'} />

      <Box sx={{ mt: 3 }}>
        <Loader isLoading={isLoading} centered>
          {projects.length > 0 ? (
            <Paper sx={{ width: '100%', overflowX: 'auto' }}>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Назва проєкту</TableCell>
                      <TableCell>Дії</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((project) => (
                      <TableRow hover key={project.id}>
                        <TableCell>{project.title}</TableCell>
                        <TableCell>
                          <EntityActions
                            onEdit={() => onEditBtnClick(project)}
                            onDelete={() => onDeleteBtnClick(project)}
                            editUrl={NavigationRoutes.editProject.route}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          ) : (
            <Typography>Проєктів не знайдено</Typography>
          )}
        </Loader>
      </Box>

      <ConfirmDialog
        onConfirm={deleteProjectHandler}
        text={confirmationText}
        isOpen={Boolean(selectedProjectToDelete)}
        onClose={onCancelDelete}
        isLoading={isDeleteLoading}
      />
    </Container>
  );
};

const confirmationText = 'Ви впевнені, що хочете видалити цей проєкт?';
