import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../../store/app-api';
import { ProjectDto } from '../../../openapi';

const getProjectsEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint('project'),
};

export const projectApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectDto[], void>({
      query: () => ({
        url: getProjectsEndpoint.path,
        method: getProjectsEndpoint.method,
      }),
      providesTags: [ApiEntitiesTags.projects],
    }),
  }),
});

export const { useGetProjectsQuery } = projectApi;
