import * as React from 'react';
import { Avatar, SxProps } from '@mui/material';
import { stringToColor } from '../../shared';

type Props = {
  value: string;
  fontSize?: string;
  sx?: SxProps;
};

export const StringAvatar = ({
  value,
  fontSize = '1rem',
  sx = { width: '50px', height: '50px' },
}: Props) => {
  const { sx: styles, children } = stringAvatar(value, fontSize);

  return <Avatar sx={{ ...sx, ...styles }}>{children}</Avatar>;
};

function stringAvatar(name: string, fontSize: string) {
  const canSplit = name.split(' ').length > 1;

  return {
    sx: {
      backgroundColor: stringToColor(name),
      fontSize,
    },
    children: name.length
      ? `${canSplit ? name.split(' ')[0][0] : name[0]}${canSplit ? name.split(' ')[1][0] : ''}`
      : null,
  };
}
