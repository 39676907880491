import { appApi } from '../../store/app-api';
import { StudyClassDto } from '../../openapi';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from './shared.api';

const baseUrl = 'study-class/';

const getStudyClasses: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl),
};

const getCurrentStudyClass: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'current'),
};

export const sharedStudyClassApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getStudyClasses: builder.query<StudyClassDto[], void>({
      query: () => ({
        url: getStudyClasses.path,
        method: getStudyClasses.method,
      }),
      transformResponse: (studyClasses: StudyClassDto[]) =>
        [...studyClasses].sort((a, b) => a.classNumber - b.classNumber),
      providesTags: [ApiEntitiesTags.studyClasses],
    }),
    getCurrentStudyClass: builder.query<StudyClassDto, void>({
      query: () => ({
        url: getCurrentStudyClass.path,
        method: getCurrentStudyClass.method,
      }),
      providesTags: (studyClass) => [
        {
          type: ApiEntitiesTags.currentStudyClass,
          ...(studyClass && { id: studyClass.classNumber }),
        },
      ],
    }),
  }),
});

export const { useGetStudyClassesQuery, useGetCurrentStudyClassQuery } = sharedStudyClassApi;
