import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DesignAssetsEntity } from '../../openapi';

type Props = {
  value: DesignAssetsEntity.type;
  onChange: (value: DesignAssetsEntity.type) => void;
};

export const TemplateTypeSelect = ({ onChange, value }: Props) => {
  return (
    <FormControl margin='normal' fullWidth>
      <InputLabel id='language'>Тип макету</InputLabel>
      <Select
        labelId='template-type-select'
        id='template-type-select'
        label='Тип макету'
        value={value}
        onChange={({ target }) => onChange(target.value as DesignAssetsEntity.type)}
        fullWidth
      >
        <MenuItem value={DesignAssetsEntity.type.FIGMA}>Фігма</MenuItem>
        <MenuItem value={DesignAssetsEntity.type.PHOTOSHOP}>Фотошоп</MenuItem>
      </Select>
    </FormControl>
  );
};
