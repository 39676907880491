/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCriterionDto } from '../models/CreateCriterionDto';
import type { CriterionDto } from '../models/CriterionDto';
import type { UpdateCriterionDto } from '../models/UpdateCriterionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CriteriaApi {

    /**
     * @returns CriterionDto 
     * @throws ApiError
     */
    public static criterionControllerFindAll(): CancelablePromise<Array<CriterionDto>> {
        return __request({
            method: 'GET',
            path: `/api/criteria`,
        });
    }

    /**
     * @returns CriterionDto 
     * @throws ApiError
     */
    public static criterionControllerCreate({
requestBody,
}: {
requestBody: CreateCriterionDto,
}): CancelablePromise<CriterionDto> {
        return __request({
            method: 'POST',
            path: `/api/criteria`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Criterion with this category and id already exist`,
            },
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static criterionControllerFindAllByCategory({
category,
}: {
category: 'common' | 'html' | 'styles' | 'responsive',
}): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/criteria/${category}`,
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static criterionControllerUpdate({
id,
requestBody,
}: {
id: string,
requestBody: UpdateCriterionDto,
}): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/api/criteria/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static criterionControllerRemove({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/criteria/${id}`,
        });
    }

}