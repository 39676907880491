import React from 'react';

type Props = {
  onClick: () => void;
  url: string;
  children: JSX.Element;
};

export const Link = ({ children, onClick, url }: Props) => {
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <a className={'link-default-hover'} onClick={onLinkClick} href={url}>
      {children}
    </a>
  );
};
